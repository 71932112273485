// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1i1Wm{cursor:pointer;overflow:hidden;border-radius:8px;background:#fff;-webkit-box-shadow:0 0 10px rgba(0,0,0,.1);box-shadow:0 0 10px rgba(0,0,0,.1)}._3onmv{max-height:80%}._3onmv>.ant-modal-content{background:#000;border-radius:8px}._3onmv>.ant-modal-content>.ant-modal-body{padding:0}._3onmv>.ant-modal-content>.ant-modal-close{z-index:100;margin:-50px;color:#fff}._18wte{padding:10px 20px}._18wte:last-child{padding-bottom:20px}._2xl4q{border-radius:4px;overflow:hidden;text-align:center;background:#aaa;font-weight:700;color:#212121}._2xl4q>img{text-align:center;vertical-align:middle;width:100%;height:auto}._2lCrf{margin:-40px 0 0;width:100%;padding:0 20px;text-align:center;font-size:1.2em;font-weight:500;font-family:Poppins,sans-serif;font-style:normal;line-height:20px;text-shadow:0 0 5px #000}._2lCrf,._1OdXu{position:absolute;color:#fff}._1OdXu{font-size:4em;left:50%;top:50%;-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);transform:translate(-50%,-50%)}._2d-cQ{font-size:1.25em;font-weight:700;width:100%;display:block;color:#11174e}._2S1Wk{color:#fff;background:#11174e;padding:5px 0}._10cO-{color:#11174e;display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between}._345x_{width:100%;display:block}", ""]);
// Exports
exports.locals = {
	"appCard": "_1i1Wm",
	"appModal": "_3onmv",
	"cardBody": "_18wte",
	"cardImage": "_2xl4q",
	"cardLabel": "_2lCrf",
	"playIcon": "_1OdXu",
	"title": "_2d-cQ",
	"titleFlag": "_2S1Wk",
	"textTable": "_10cO-",
	"subtitle": "_345x_"
};
module.exports = exports;
