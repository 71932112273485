/* eslint-disable react/prop-types */
import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Row, Col, Button, Typography } from 'antd';
import style from './index.css';
import TermCrowdFunding from '../TermCrowdFunding';
import { UIShare } from '../Share';

const butttonStyle = {
  backgroundImage: 'linear-gradient(#5563E5, #232F9D)',
  color: '#fff',
  borderRadius: 5,
  width: '100%',
  height: 45,
};
const butttonStyles = {
  background: '#11174e',
  color: '#fff',
  borderRadius: 5,
  width: '100%',
  height: 45,
};

const statusStyle = {
  display: 'block',
  padding: '0px 7px',
  borderRadius: '10px',
  color: '#fff',
};

const statusColor = [
  { ...statusStyle, background: '#85b6ed' },
  { ...statusStyle, background: '#FBBC04' },
  { ...statusStyle, background: '#85B6ED' },
  { ...statusStyle, background: '#2EC57C' },
];

const CardCrowdFunding = props => {
  const {
    data = {},
    language,
    isButton,
    showTerm,
    buttonText,
    userData,
    userId,
    token,
    isStyle,
    hideShare,
    isMobile
  } = props;

  return (
    <>
      <div className={style.root}>
        {isButton ? (
          <Row gutter={[20, 20]}>
            <Col span={24}>
              <Button
                onClick={() => props.onClick()}
                className={style.appButton}
                style={isStyle ? butttonStyle : butttonStyles}
              >
                {buttonText ||
                  (language !== 'ind'
                    ? 'I want to participate'
                    : 'Saya ingin berpartisipasi')}
              </Button>
            </Col>
          </Row>
        ) : (
          <div className={style.appCard} style={{ height: '100%' }}>
            <Row>
              <Col span={24}>
                <div className={style.projectImage}>
                  <img
                    src={data.thumbnailPath ? data.thumbnailPath : ''}
                    alt="IMG"
                  />
                </div>
              </Col>
              <Col
                span={24}
                style={{
                  padding: '10px',
                  cursor: 'pointer',
                  textAlign: 'center',
                }}
                onClick={() => props.onClickDetail(data)}
              >
                <Typography.Paragraph
                  title={data.projectListName}
                  ellipsis={{ rows: 1 }}
                  className={style.title}
                >
                  {data.projectListName}
                </Typography.Paragraph>
                <Typography.Paragraph
                  title={data.developmentCategory}
                  ellipsis={{ rows: 1 }}
                  className={style.subtitle}
                >
                  {data.developmentCategory}
                </Typography.Paragraph>
              </Col>
              <Col
                span={24}
                className={style.titleFlag}
                style={{ textAlign: 'center' }}
              >
                {language !== 'ind' ? 'Project Details' : 'Detail Proyek'}
              </Col>
              <Col span={24} style={{ padding: '10px 20px 0px ' }}>
                <div className={style.textTable}>
                  <span>
                    {language !== 'ind' ? 'Start Date' : 'Tanggal Mulai'}
                  </span>{' '}
                  <span>{data.startDate || '-'}</span>
                </div>
                <div className={style.textTable}>
                  <span>{language !== 'ind' ? 'Duration' : 'Durasi'}</span>{' '}
                  <span>{data.duration || '-'}</span>
                </div>
                <div className={style.textTable}>
                  <span>{language !== 'ind' ? 'Project Status' : 'Status Proyek'}</span>{' '}
                  <span>
                    <span style={{ ...(statusColor[data.statusId] || {}) }}>
                      {data.status || '-'}
                    </span>
                  </span>
                </div>
                <div
                  className={style.textTable}
                  style={{
                    flexDirection: isMobile ? 'inherit' : 'column',
                    justifyContent: isMobile ? 'space-between' : 'flex-start',
                    marginTop: '5px',
                  }}
                >
                  <span>{language !== 'ind' ? 'Project Costs' : 'Nilai Proyek'}</span>{' '}
                  <span>{data.valueEstimation || '-'}</span>
                </div>
              </Col>
              <Col
                span={24}
                className={style.cardBody}
                style={{ textAlign: 'justify' }}
              >
                <div>
                  <Typography.Paragraph
                    ellipsis={{ rows: 6 }}
                    style={{ marginBottom: '0px'}}
                  >
                    {data.designationDesc || 'No description'}
                  </Typography.Paragraph>
                </div>
              </Col>
            </Row>
            <Row>
              {!hideShare && (
                <>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <Typography.Paragraph
                      ellipsis={{ rows: 1 }}
                      style={{ marginBottom: '0px' }}
                    >
                      {language !== 'ind' ? 'Info : ' : 'Info : '}
                      {data.contactPerson}
                    </Typography.Paragraph>
                  </Col>
                  <Col
                    span={24}
                    className={style.cardBody}
                    style={{ textAlign: 'center', padding: '10px 10px 20px' }}
                  >
                    <UIShare
                      label={language === 'eng' ? 'Share' : 'Bagikan'}
                      url={
                        window.location.href +
                        `/${data.projectListId}/${data.projectListName}`
                      }
                    />
                  </Col>
                </>
              )}
            </Row>
          </div>
        )}
      </div>
      <TermCrowdFunding
        visible={showTerm}
        buttonCancel={() => props.buttonCancel()}
        title="Saya Ingin Berpartisipasi Dalam Proyek Crowdfunding"
        handleFinish={e => props.handleFinish(e)}
        language={language}
        userData={userData}
        userId={userId}
        token={token}
      />
    </>
  );
};

export const UICardCrowdFunding = withStyles(style)(CardCrowdFunding);
