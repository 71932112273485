/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Row, Col, Typography } from 'antd';
import history from '@source/history';
import s from './index.css';
import { UIShare } from '../Share';
import ModalPenerimaBeasiswa from '../ModalPenerimaBeasiswa';
import Paragraph from 'antd/lib/typography/Paragraph';

const labels = {
  eng: {
    needs: 'Donation Needs',
    detailLabel: 'Scholarship Details',
    recipientTitle: 'Show recipient',
    recipient: 'Number of Recipients',
    donateButton: 'I want to donate',
    share: 'Share',
    moreInfo: 'Info : ',
    participateQuery: 'Do You want to participate in Voluntary Donation?',
    participateButton: 'Yes, I Want to Participate',
  },
  ind: {
    needs: 'Kebutuhan Donasi',
    detailLabel: 'Detail Beasiswa',
    recipientTitle: 'Lihat Penerima',
    recipient: 'Jumlah Penerima',
    donateButton: 'Saya Ingin Berdonasi',
    share: 'Bagikan',
    moreInfo: 'Info : ',
    participateQuery:
      'Apakah Saudara(i) ingin berpartisipasi dalam donasi sukarela?',
    participateButton: 'Ya, Saya Ingin Berpartisipasi',
  },
};

const CardBeasiswa = props => {
  const { data = {}, language, isMobile } = props;
  const label = labels[language];
  const [showRecipient, setShowRecipient] = useState(false);

  const handleDetail = () => {
    history.push(`/donorBeasiswa/${data.id}`);
  };

  const toggleRecipient = () => {
    setShowRecipient(!showRecipient);
  };

  return (
    <div style={{ height: '100%' }}>
      <div className={s.appCard}>
        <Row>
          <Col span={24}>
            <div className={s.projectImage}>
              <img src={data.imageUrl} alt="IMG" />
            </div>
          </Col>
          <Col span={24} style={{ padding: '10px' }}>
            <Paragraph
              className={s.title}
              ellipsis={{ rows: 1 }}
              style={{ cursor: 'pointer', marginBottom: '0px' }}
              onClick={handleDetail}
            >
              {data.category}
            </Paragraph>
          </Col>
          <Col span={24} className={s.titleFlag}>
            {label.detailLabel}
          </Col>
          <Col span={24} style={{ padding: '10px 20px 0px ' }}>
            <div className={s.textTable}>
              <span>{label.recipient}</span>
              <a
                className={s.appLink}
                title={label.recipientTitle}
                style={{ fontWeight: 'bold' }}
                onClick={toggleRecipient}
              >
                {data.recipient}
              </a>
            </div>
            <div
              className={s.textTable}
              style={{
                flexDirection: isMobile ? 'inherit' : 'column',
                justifyContent: isMobile ? 'space-between' : 'flex-start',
                marginTop: '5px',
                textAlign: 'left'
              }}
            >
              <span>{label.needs}</span>
              <span>{data.needs}</span>
            </div>
          </Col>
          <Col
            span={24}
            style={{ padding: '10px 20px 5px', textAlign: 'justify' }}
          >
            <div>
              <Typography.Paragraph
                ellipsis={{ rows: 6 }}
              >
                {data.description || 'No Description'}
              </Typography.Paragraph>
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            span={24}
            style={{ padding: '10px 20px 10px', textAlign: 'center' }}
          >
            <Typography.Paragraph
              ellipsis={{ rows: 1 }}
              style={{ marginBottom: '0px' }}
            >
              {label.moreInfo}
              {data.contactPerson || '-'}
            </Typography.Paragraph>
            {/* <hr style={{ margin: '10px 0px 0px' }} /> */}
          </Col>
          <Col
            span={24}
            style={{ padding: '0px 20px 20px', textAlign: 'center' }}
          >
            <UIShare
              label={label.share}
              url={window.location.href + `/donorBeasiswa/${data.id}`}
            />
          </Col>
        </Row>
      </div>
      <ModalPenerimaBeasiswa
        visible={showRecipient}
        data={data.id}
        onClose={toggleRecipient}
        language={language}
      />
    </div>
  );
};

export const UICardBeasiswa = withStyles(s)(CardBeasiswa);
