import React, { useEffect, useState } from 'react';
import {
  Button,
  Row,
  Col,
  Input,
  Form,
  notification,
  Typography,
} from 'antd';
import useStyles from 'isomorphic-style-loader/useStyles';
import { DanaAbadiService } from '../../api/services';
import style from './index.css';
import { UICaptchaBox } from '../Captchabox';
import { generateRandom } from '../../lib';
import { confirmDialog } from '../../lib/alert';
import MoneyInput from '../MoneyInput';

const { Text } = Typography;

function FormCrowdFunding(props) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [randomValue, setRandomValue] = useState(generateRandom(5));

  useStyles(style);
  useEffect(() => {
    if (props.userData) {
      const userDataTemp = JSON.parse(props.userData)
        ? JSON.parse(props.userData)
        : {};
      const formBody = {
        nama: userDataTemp.udName,
        email: userDataTemp.udEmail,
        noHp: userDataTemp.udWa,
        nilaiDonasi: 0,
        fileName: props.fileName,
      };
      form.setFieldsValue(formBody);
      setLoading(false);
    }
  }, []);

  const checkFinish = values => {
    if (values.captcha !== randomValue) {
      resetChapcha();
      notification.error({
        placement: 'bottomRight',
        message: 'Error',
        description: 'Captcha Salah!',
      });
    } else {
      if(values.nilaiDonasi > 0){
        confirmDialog({
          title: 'Checkout Pembayaran',
          text: 'Pastikan Informasi Pribadi & Nominal Donasi telah sesuai!',
          icon: 'info',
        }).then(_ => {
            handleSubmit(values);
        }).catch(_ => {});
      }else{
        setLoading(false);
        resetChapcha()
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: 'Nominal Donasi harus lebih dari Rp0,00',
        });
      }
    }
  };
  const resetChapcha = () => {
    let temp = {};
    temp = {
      captcha: undefined,
    };
    form.setFieldsValue(temp);
    handleRandom();
  };

  const handleRandom = () => {
    const data = generateRandom(5);
    setRandomValue(data);
  };

  const handleSubmit = val => {
    setLoading(true);
    const body = {
      usersId: props.userId,
      pcNominal: val.nilaiDonasi,
      fileName: props.fileName,
    };
    if (body.pcNominal > 0) {
      DanaAbadiService.crowdFoundingPayment(props.language, props.token, body)
        .then(res => {
          const response = res.data;
          if (response.rc == '00') {
            props.pressed(res);
            setLoading(false);
            form.setFieldsValue({
              nilaiDonasi: 0,
            });
          } else {
            setLoading(false);
            notification.error({
              placement: 'bottomRight',
              message: 'Error',
              description: response.message
                ? response.message
                : 'Terjadi Kesalahan Pada Server',
            });
          }
        })
        .catch(err => {
          setLoading(false);
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: err.response
              ? err.response.data.message
              : err.message
              ? err.message
              : 'Terjadi Kesalahan Pada Server',
          });
        });
    } else {
      setLoading(false);
      resetChapcha()
      notification.error({
        placement: 'bottomRight',
        message: 'Nominal Donasi',
        description: 'Nominal Donasi harus lebih dari Rp0,00',
      });
    }
  };

  return (
    <div className={style.modalBody}>
      <Form form={form} onFinish={checkFinish} onFinishFailed={resetChapcha}>
        <Row gutter={[10, 10]}>
          <div className={style.formTitle}>Detail Donasi</div>
          <Col span={24}>
            <Text>Nilai Donasi</Text>
            <Form.Item name="nilaiDonasi" style={{ marginBottom: '1em' }}
              rules={[
                {
                  required: true,
                  message: "Nilai Donasi harus diisi!",
                }]}
            >
              <MoneyInput max={99999999999}/>
            </Form.Item>
          </Col>
          <div className={style.formTitle}>Detail Donatur</div>
          <Col span={24}>
            <Text>Nama</Text>
            <Form.Item name="nama" style={{ marginBottom: '1em' }}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Text>No. Handphone (WA)</Text>
            <Form.Item name="noHp" style={{ marginBottom: '1em' }}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Text>E-mail</Text>
            <Form.Item name="email" style={{ marginBottom: '1em' }}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <UICaptchaBox
              randomValue={randomValue}
              handleRandom={handleRandom}
              language={props.language}
            />
          </Col>
          <Col span={24}>
            <Row>
              <Col span={8}>
                <Form.Item style={{ marginBottom: '12px' }}>
                  <Button
                    block
                    style={{ height: '46px' }}
                    onClick={() => props.onCancel()}
                    disabled={loading}
                    className={style.appButton}
                  >
                    Kembali
                  </Button>
                </Form.Item>
              </Col>
              <Col span={8} />
              <Col span={8}>
                <Form.Item style={{ marginBottom: '12px' }}>
                  <Button
                    block
                    style={{ height: '46px' }}
                    htmlType="submit"
                    loading={loading}
                    className={style.appButton}
                  >
                    Checkout
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default FormCrowdFunding;
