// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2VfVg{padding:0;border-radius:8px;overflow:hidden;border:0 solid #eee}._2VfVg>.ant-modal-content{border-radius:8px;overflow:hidden;background:#dfe0e1}._2VfVg>.ant-modal-content>.ant-modal-body{padding:0}._2VfVg>.ant-modal-content>.ant-modal-close{color:#fff}._1O5ut{padding:30px 50px;background:#11174e;color:#fff;font-size:2em;font-weight:500;text-align:center;font-family:Poppins,sans-serif}._1MQj5{padding:30px 50px 0;background:#dfe0e1}._1MQj5:last-child{padding:30px 50px;background:#dfe0e1}.ant-table-wrapper{background:#fff;padding:2em 2em 1em;border-radius:8px}@media only screen and (max-width:480px){._1O5ut{font-size:1em}._1MQj5:last-child{padding:30px}}@media only screen and (max-width:1080px){._1O5ut{font-size:1em}._1MQj5:last-child{padding:30px}}", ""]);
// Exports
exports.locals = {
	"appModal": "_2VfVg",
	"modalHeader": "_1O5ut",
	"modalBody": "_1MQj5"
};
module.exports = exports;
