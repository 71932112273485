// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1nC3V{width:100%;position:relative;display:inline-block;height:200px}._1nC3V img{width:100%;height:100%;-o-object-fit:cover;object-fit:cover}._1nC3V:hover{cursor:pointer}._3hqc_{position:absolute;font-size:45px;color:#fff;top:35%;left:40%;z-index:2}.T7q38{font-family:Mulish,sans-serif;font-style:normal;font-weight:400;margin-top:10px;text-align:left}._1Y9V4{font-weight:700;font-size:14px;line-height:22px}._3nj2-,._3o0XD{font-size:10px;line-height:12px}._3nj2-{font-weight:600}._2q7DH{border-radius:15px;min-width:250px;background:#fff}._3bNEN ._3g2Qb{padding:0}._2CX3O{text-align:left}._2VVx4{text-align:center}._3zzek{text-align:right}.M2GNA,.M2GNA:focus,.M2GNA:hover{margin-top:1.25rem;background:#11174e;border-radius:8px;color:#ececec}.M2GNA>span{font-size:14px;font-size:.875rem;color:#ececec}.ZGDm-{text-align:center;width:50%}._1t7n_{font-family:Mulish,sans-serif;font-style:normal;font-weight:700;font-size:12px;line-height:20px;text-align:center;color:#ef712b}._2l2sF>.ant-modal-content>.ant-modal-body{padding:0;text-align:center}._2rKXA{text-indent:50px;text-align:justify;letter-spacing:3px}", ""]);
// Exports
exports.locals = {
	"gradientCard": "_1nC3V",
	"playButton": "_3hqc_",
	"textContent": "T7q38",
	"textHead": "_1Y9V4",
	"textBottom": "_3nj2-",
	"textMid": "_3o0XD",
	"bodyCard": "_2q7DH",
	"custom": "_3bNEN",
	"ant-card-body": "_3g2Qb",
	"leftText": "_2CX3O",
	"centerText": "_2VVx4",
	"rightText": "_3zzek",
	"buttonStyle": "M2GNA",
	"buttonContainer": "ZGDm-",
	"nameUniv": "_1t7n_",
	"cardVideo": "_2l2sF",
	"docDescription": "_2rKXA"
};
module.exports = exports;
