/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Modal } from 'antd';
import { UIImage } from '@components';
import Title from 'antd/lib/typography/Title';
import styles from './index.css';

const CardDetailGaleri = props => {
  const [showPreview, setShowPreview] = useState(false);
  const { data = {}, docName } = props;
  const url = data.feGalleryImgPath !== null ? data.feGalleryImgPath : data.feGalleryVideoImage;

  const handleClick = async () => {
    setShowPreview(true);
  };

  return (
    <div className={styles.root}>
      <div className={styles.appCard} onClick={handleClick}>
        <div className={styles.cardImage}>
          <UIImage
            src={url}
            style={{ height: '100%', width: '100%', objectFit: 'cover' }}
            iconStyle={{ fontSize: '4em', color: '#eee' }}
          />
        </div>
        <div className={styles.cardLabel}>{`${docName}`} </div>
      </div>
      <Modal
        visible={showPreview}
        centered
        className={styles.appModal}
        footer={null}
        width="75%"
        onCancel={() => setShowPreview(false)}
      >
        {data.feGalleryImgPath !== null && (
          <div
            style={{
              height: props.isMobile ? 'auto' : '500px',
              margin: 'auto',
              lineHeight: props.isMobile ? 'auto' : '500px',
              width: '100%',
              textAlign: 'center',
            }}
          >
            <UIImage
              src={url}
              width="100%"
              style={{
                height: '100%',
                width: '100%',
                objectFit: 'contain',
                textAlign: 'center',
                display: 'block',
              }}
              iconStyle={{ fontSize: '4em', color: '#eee' }}
            />
          </div>
        )}
        {data.feGalleryVideoUrl !== null && (
          <iframe
            title="preview-document"
            style={{ width: '100%', height: props.isMobile ? '300px' : '500px' }}
            id="preview-document"
            src={props.data.feGalleryVideoUrl}
            frameBorder="0"
            allow="accelerometer;
          autoplay;
          encrypted-media;
          gyroscope;
          picture-in-picture"
            allowFullScreen="allowfullscreen"
          />
        )}
      </Modal>
    </div>
  );
};

// eslint-disable-next-line import/prefer-default-export
export const UICardDetailGaleri = withStyles(styles)(CardDetailGaleri);
