/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-undef */
import { FileTextOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import withStyles from 'isomorphic-style-loader/withStyles';
import React from 'react';
import s from './index.css';

export default function NoData(props) {
  const {
    showReload,
    onReload,
    label = {
      noData: 'Data Not Available',
      reload: 'Reload',
    },
  } = props;

  return (
    <div className={s.root}>
      <div className={s.noData}>
        <div className={s.noDataIcon}>
          <div className="shadow" />
          <FileTextOutlined className="icon" style={{ color: '#222' }} />
        </div>
        <span style={{ color: '#222' }}>{label.noData}</span>
      </div>
      {showReload && (
        <Button onClick={onReload} className={s.reloadBtn}>
          <ReloadOutlined style={{ marginRight: '5px' }} /> {label.reload}
        </Button>
      )}
    </div>
  );
}

export const UINoData = withStyles(s)(NoData);
