export default function translator(lang) {
  return lang === 'eng'
    ? {
        quota: 'Quota',
        loyola: 'Loyola',
        general: 'Public',
        share: 'Share',
        cancelRegister: 'Cancel Register',
        register: 'Register',
        textRegister: `Would you like to register for this event?`,
        textCancel: `Would you like to unregister from this event?`,
      }
    : {
        quota: 'Kuota',
        loyola: 'Loyola',
        general: 'Umum',
        share: 'Bagikan',
        cancelRegister: 'Batalkan Pendaftaran',
        register: 'Saya Mendaftar',
        textRegister: `Apakah Anda yakin ingin mendaftar pada Jadwal acara ini?`,
        textCancel: `Apakah Anda yakin ingin membatalkan pendaftaran pada Jadwal acara ini?`,
      };
}
