import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  Row,
  Col,
  Input,
  Form,
  Radio,
  DatePicker,
  notification,
} from 'antd';
import useStyles from 'isomorphic-style-loader/useStyles';
import { YayasanLoyolaService } from '@api/services';
import moment from 'moment';
import s from './index.css';
import registrasiLabel from './language.js';
import logoUrl from '@global/assets/logoYoloya.png';
import { UICaptchaBox } from '../Captchabox';
import { generateRandom } from '../../lib';

function ModalRegister(props) {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [label, setLabel] = useState(registrasiLabel(props.language));
  const [randomValue, setRandomValue] = useState(generateRandom(5));
  const isEng = props.language === 'eng';

  useStyles(s);
  useEffect(() => {}, []);

  const checkFinish = values => {
    let temp = {};
    if (values.captcha !== randomValue) {
      temp = {
        captcha: undefined,
      };
      form.setFieldsValue(temp);
      handleRandom();
    } else {
      handleSubmit(values);
    }
  };

  const handleRandom = () => {
    let data = generateRandom(5);
    setRandomValue(data);
  };

  const handleSubmit = val => {
    setLoading(true);
    let body = {
      usersUsername: val.username,
      usersPassword: val.password,
      usersEmail: val.email,
      udName: val.name,
      udGender: val.gender,
      udDateOfBirth: moment(val.dateOfBirth).format('YYYY-MM-DD'),
      udAddress: val.address,
      udProfession: val.profession,
      udInstitution: val.nameOfWork,
      udLastEducation: val.education,
      udLastPlaceOfEdu: val.nameOfEducation,
      udPhone: val.noTlpn,
      udWa: val.wa,
      isLoyola: val.isLoyola,
    };
    if (val.password == val.confirmPassword) {
      YayasanLoyolaService.registrasi(body, props.language)
        .then(res => {
          let response = res.data;
          setLoading(false);
          if (response.responseType == 'Success') {
            setVisible(false);
            notification.success({
              placement: 'bottomRight',
              message: response.responseType,
              description: response.message,
            });
            form.resetFields();
          } else {
            notification.warning({
              placement: 'bottomRight',
              message: response.responseType,
              description: response.message,
            });
            form.setFieldsValue({ captcha: undefined });
            handleRandom();
          }
        })
        .catch(err => {
          if (err) {
            setLoading(false);
            notification.error({
              placement: 'bottomRight',
              message: label.notifikasi.error.message,
              description: err.data
                ? err.data.message
                : label.notifikasi.error.description,
            });
          }
        });
    } else {
      setLoading(false);
      notification.error({
        placement: 'bottomRight',
        message: label.notifikasi.error.message,
        description: label.notifikasi.error.confirmPasswordMessage,
      });
    }
  };

  return (
    label && (
      <>
        <Col span="24" style={{ paddingBottom: 0 }}>
          <Button
            block
            hidden={props.hiddenButton}
            className={s.buttonDaftar}
            style={{ border: '1 solid #fff', borderRadius: 8 }}
            onClick={() => setVisible(!visible)}
            disabled={props.disabled}
          >
            {label.daftar}
          </Button>
        </Col>
        <Modal
          title="Login"
          visible={visible}
          width={900}
          centered
          className={s.modalStyle}
          onCancel={() => setVisible(!visible)}
        >
          <Row>
            <Col
              span={props.isMobile ? 24 : 10}
              style={{ background: '#11174E' }}
            >
              <div
                className={s.containLeft}
                style={{ height: props.isMobile ? 'auto' : '100%' }}
              >
                <Row gutter={[0, 8]}>
                  <Col span={24}>
                    <img width={100} height={100} src={logoUrl} alt="logoLyl" />
                  </Col>
                  <Col span={24} style={{ flex: 1, height: '100%' }}>
                    <span className={s.textLeft}>{label.bergabungBersama}</span>
                  </Col>
                  <Col span={24}>
                    <hr style={{ border: '10 solid #fff' }} />
                    <Button type="text" onClick={() => setVisible(!visible)}>
                      <div style={{ color: '#fff', width: '100%' }}>
                        {label.kembaliKeLogin}
                      </div>
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col
              span={props.isMobile ? 24 : 14}
              style={{ background: '#DFE0E1', height: 700, overflow: 'auto' }}
            >
              <div className={s.cotainerContent}>
                <Form
                  form={form}
                  name="normal_login"
                  className="login-form"
                  initialValues={{ remember: true }}
                  onFinish={checkFinish}
                >
                  <Form.Item
                    style={{ textAlign: 'left', marginBottom: '50px' }}
                  >
                    <span className={s.textLogin}>{label.daftar}</span>
                  </Form.Item>
                  <Col>
                    <Col span={24}>
                      <div className={s.labelText}>
                        {label.nama}
                        <span style={{ color: 'red' }}> *</span>
                      </div>
                      <Form.Item
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: label.namaRequired,
                          },
                        ]}
                      >
                        <Input
                          className={s.inputStyleLogin}
                          placeholder={label.nama}
                          maxLength="255"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Row>
                        <Col span={12}>
                          <div>
                            {label.jenisKelamin}
                            <span style={{ color: 'red' }}> *</span>
                          </div>
                          <Form.Item
                            style={{ marginBottom: '40px' }}
                            name="gender"
                            rules={[
                              {
                                required: true,
                                message: label.jenisKelaminRequired,
                              },
                            ]}
                          >
                            <Radio.Group>
                              {label.jenisKelaminLov.map(res => {
                                return (
                                  <Radio value={res.key}>{res.value}</Radio>
                                );
                              })}
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <div>
                            {label.tglLahir}
                            <span style={{ color: 'red' }}> *</span>
                          </div>
                          <Form.Item
                            style={{ marginBottom: '40px' }}
                            name="dateOfBirth"
                            rules={[
                              {
                                required: true,
                                message: label.tglLahirRequired,
                              },
                            ]}
                          >
                            <DatePicker
                              className={s.inputStyleLogin}
                              placeholder={label.tglLahir}
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <div className={s.labelText}>{label.alamat}</div>
                      <Form.Item name="address">
                        <Input.TextArea
                          className={s.inputStyleLogin}
                          placeholder={label.alamat}
                          rows={4}
                          maxLength="500"
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <div className={s.labelText}>{label.pekerjaan}</div>
                      <Form.Item name="profession">
                        <Input
                          className={s.inputStyleLogin}
                          placeholder={label.pekerjaan}
                          maxLength="255"
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <div className={s.labelText}>
                        {label.namaInstitusiBekerja}
                      </div>
                      <Form.Item name="nameOfWork">
                        <Input
                          className={s.inputStyleLogin}
                          placeholder={label.namaInstitusiBekerja}
                          maxLength="500"
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Row>
                        <Col span={24}>
                          <div className={s.labelText}>{label.pendidikan}</div>
                        </Col>
                        <Col flex="auto">
                          <Form.Item
                            style={{ marginBottom: '40px' }}
                            name="education"
                          >
                            <Radio.Group>
                              {label.pendidikanLov.map(res => {
                                return (
                                  <Radio value={res.key}>{res.value}</Radio>
                                );
                              })}
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <div className={s.labelText}>
                        {label.namaInstitusiPendidikan}
                      </div>
                      <Form.Item name="nameOfEducation">
                        <Input
                          className={s.inputStyleLogin}
                          placeholder={label.namaInstitusiPendidikan}
                          maxLength="500"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Row gutter={[20, 0]}>
                        <Col span={12}>
                          <div className={s.labelText}>
                            {label.noTlpn}
                            <span style={{ color: 'red' }}> *</span>
                          </div>
                          <Form.Item
                            name="noTlpn"
                            rules={[
                              {
                                required: true,
                                message: label.noTlpnRequired,
                              },
                              {
                                pattern: new RegExp('^[0-9]+$'),
                                message: label.noOnlyRequired,
                              },
                            ]}
                          >
                            <Input
                              className={s.inputStyleLogin}
                              placeholder={label.noTlpn}
                              maxLength="20"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <div className={s.labelText}>
                            {label.wa}
                            <span style={{ color: 'red' }}> *</span>
                          </div>
                          <Form.Item
                            name="wa"
                            rules={[
                              {
                                required: true,
                                message: label.noTlpnRequired,
                              },
                              {
                                pattern: new RegExp('^[0-9]+$'),
                                message: label.noOnlyRequired,
                              },
                            ]}
                          >
                            <Input
                              className={s.inputStyleLogin}
                              placeholder={label.wa}
                              maxLength="20"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row gutter={[20, 0]}>
                        <Col span={12}>
                          <div className={s.labelText}>
                            {label.email}
                            <span style={{ color: 'red' }}> *</span>
                          </div>
                          <Form.Item
                            name="email"
                            rules={[
                              {
                                required: true,
                                message: label.emailRequired,
                              },
                            ]}
                          >
                            <Input
                              className={s.inputStyleLogin}
                              placeholder={label.email}
                              type="email"
                              maxLength="50"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <div className={s.labelText}>
                            {label.username}
                            <span style={{ color: 'red' }}> *</span>
                          </div>
                          <Form.Item
                            name="username"
                            rules={[
                              {
                                required: true,
                                message: label.usernameRequired,
                              },
                            ]}
                          >
                            <Input
                              className={s.inputStyleLogin}
                              placeholder={label.username}
                              maxLength="15"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row gutter={[20, 0]}>
                        <Col span={12}>
                          <div className={s.labelText}>
                            {label.password}
                            <span style={{ color: 'red' }}> *</span>
                          </div>
                          <Form.Item
                            name="password"
                            rules={[
                              {
                                required: true,
                                message: label.passwordRequired,
                              },
                            ]}
                          >
                            <Input.Password
                              className={s.inputStyleLogin}
                              placeholder={label.password}
                              type="password"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <div className={s.labelText}>
                            {label.confirmPassword}
                            <span style={{ color: 'red' }}> *</span>
                          </div>
                          <Form.Item
                            name="confirmPassword"
                            rules={[
                              {
                                required: true,
                                message: label.confirmPasswordRequired,
                              },
                            ]}
                          >
                            <Input.Password
                              className={s.inputStyleLogin}
                              placeholder={label.confirmPassword}
                              type="password"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row gutter={[20, 0]}>
                        <Col span={24}>
                          <div className={s.labelText}>
                            {label.registeredLyl}{' '}
                            <span style={{ color: 'red' }}> *</span>
                          </div>
                        </Col>
                        <Col flex="auto">
                          <Form.Item
                            style={{ marginBottom: '40px' }}
                            name="isLoyola"
                            rules={[
                              {
                                required: true,
                                message: label.confirmRole,
                              },
                            ]}
                          >
                            <Radio.Group>
                              <Radio value={true}>{isEng ? 'Yes' : 'Ya'}</Radio>
                              <Radio value={false}>{isEng ? 'No' : 'Tidak'}</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row gutter={[20, 0]}>
                        <Col span={12} style={{ marginBottom: 20 }}>
                          <UICaptchaBox
                            randomValue={randomValue}
                            handleRandom={handleRandom}
                            language={props.language}
                          />
                        </Col>
                      </Row>
                    </Col>

                    <Form.Item style={{ marginBottom: '12px' }}>
                      <Button
                        block
                        style={{ height: '46px' }}
                        className={s.buttonLogin}
                        htmlType="submit"
                        loading={loading}
                      >
                        {label.daftar}
                      </Button>
                    </Form.Item>
                  </Col>
                </Form>
              </div>
            </Col>
          </Row>
        </Modal>
      </>
    )
  );
}

export default ModalRegister;
