/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Row, Col } from 'antd';
import style from './index.css';
import translator from './language.js';

const BannerDirection = props => {
  const label = translator(props.language);

  return (
    <div className={style.root}>
      <div>
        <Row>
          <Col span={props.isMobile ? 24 : 12} className={style.imgBanner}>
            <div className={style.gradientImgLeft}>
              <div className={style.leftText}>
                <div className={style.chevronLeft} onClick={props.nextAction} />
                <span
                  className={style.textStyleLeft}
                  onClick={props.nextAction}
                >
                  {label.latestEvent}
                </span>
              </div>
              <img
                src={props.dataImage ? props.dataImage.acaraTerbaru : null}
                style={{ width: '100%' }}
              />
            </div>
          </Col>
          <Col span={props.isMobile ? 24 : 12} className={style.imgBanner}>
            <div className={style.gradientImgRight}>
              <div className={style.rightText}>
                {/* {!props.active && */}
                {/* <> */}
                <div
                  className={style.chevronRight}
                  onClick={props.prevAction}
                />
                <span className={style.textStyle} onClick={props.prevAction}>
                  {label.previousEvent}
                </span>
                {/* </> */}
                {/* } */}
              </div>
              <img
                src={props.dataImage ? props.dataImage.acaraSebelumnya : null}
                style={{ width: '100%' }}
              />
            </div>
            <div />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export const UIBannerDirection = withStyles(style)(BannerDirection);
