/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Col, Row, Button, Typography, Modal, Popconfirm } from 'antd';
import { UIImage } from '@components';
import { UIShare } from '../Share';
import translator from './language.js';
import style from './index.css';

const { Text, Paragraph } = Typography;
const textTitle = {
  fontWeight: 'bold',
  fontSize: 18,
  textAlign: 'justify',
  color: '#11174e',
};
const textDesc = { fontSize: 'smaller', textAlign: 'justify' };
const placeHolder = 'https://bitsofco.de/content/images/2018/12/broken-1.png';

const CardJadwal = props => {
  const [expired, setExpired] = useState(false);
  const { data = {}, token } = props;
  const label = translator(props.language);
  return (
    <>
      <div className={style.appCard}>
        <Row justify="center">
          <Col span={24} className={style.cardHeader}>
            <UIImage
              src={(data && data.feventThumbnailPath) || placeHolder}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                color: '#474747',
                fontSize: '2em',
                fontWeight: 'bold',
                borderRadius: '15px 15px 0 0',
                cursor: 'pointer',
              }}
              iconStyle={{ fontSize: '2em' }}
              alt="example"
              onClick={() => props.onClick(data.feventId)}
            />
          </Col>
          <Col span={24} style={{ padding: '5px 10px 0px' }}>
            <Row justify="center" align="middle">
              <Col span={8} style={{ textAlign: 'center', padding: '5px' }}>
                <Text style={textDesc}>
                  {data && data.feventDay ? data.feventDay : '-'}
                </Text>
                <br />
                <Text style={textDesc}>
                  {data && data.feventDate ? data.feventDate : '-'}
                </Text>
              </Col>
              <Col
                span={8}
                style={{
                  textAlign: 'center',
                  padding: '5px',
                  borderRight: 1,
                  borderLeft: 1,
                  borderLeftStyle: 'solid',
                  borderRightStyle: 'solid',
                  height: '100%',
                }}
              >
                <Text style={textDesc}>
                  {data && data.feventTimeStart ? data.feventTimeStart : ''} -{' '}
                  {data && data.feventTimeFinish ? data.feventTimeFinish : ''}
                </Text>
              </Col>
              <Col span={8} style={{ textAlign: 'center', padding: '5px' }}>
                <Text style={textDesc}>
                  {data && data.isOnline ? 'Online' : 'Offline'}
                </Text>
              </Col>
            </Row>
          </Col>
          <Col
            span={24}
            style={{
              textAlign: 'left',
              cursor: 'pointer',
              padding: '10px 20px 0px',
            }}
            onClick={() => props.onClick(data.feventId)}
          >
            <Text style={textTitle}>
              {data && data.feventName ? data.feventName : '-'}
            </Text>
          </Col>
          <Col span={24} style={{ textAlign: 'left', padding: '0px 20px 0px' }}>
            <Paragraph ellipsis={{ rows: 6 }} style={textDesc}>
              {data && data.feventDesc ? data.feventDesc : '-'}
            </Paragraph>
          </Col>
        </Row>
        <Row style={{ padding: '0px 20px 20px' }}>
          <Col span={24}>
            <Row>
              <Col span={24} style={{ textAlign: 'left' }}>
                <Text style={textTitle}>{label.quota}</Text>
              </Col>
              <Col span={24}>
                <Row>
                  <Col offset={2} span={8} style={{ textAlign: 'left' }}>
                    <Text style={textDesc}>{label.loyola}</Text>
                  </Col>
                  <Col offset={3}>
                    <Text style={textDesc}>
                      {data && data.actualParticipantLoyola} /{' '}
                      {data && data.feventLoyolaQuota
                        ? data.feventLoyolaQuota
                        : '0'}
                    </Text>
                  </Col>
                </Row>
                <Row>
                  <Col offset={2} span={8} style={{ textAlign: 'left' }}>
                    <Text style={textDesc}>{label.general}</Text>
                  </Col>
                  <Col offset={3}>
                    <Text style={textDesc}>
                      {data && data.actualParticipantNonLoyola} /{' '}
                      {data && data.feventNonLoyolaQuota
                        ? data.feventNonLoyolaQuota
                        : '0'}
                    </Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24} style={{ padding: '15px 0px 10px' }}>
            <Row justify="center" align="middle">
              <Col span={24} style={{ textAlign: 'center' }}>
                {data.isExpired || !token ? (
                  <Button
                    className={
                      data.isRegistered
                        ? style.redButtonStyle
                        : style.buttonStyle
                    }
                    onClick={() => props.onRegister(data.isRegistered)}
                    loading={props.loading}
                    disabled={
                      data.isExpired ||
                      data.actualParticipantLoyola >= data.feventLoyolaQuota
                    }
                  >
                    {data.isRegistered ? label.cancelRegister : label.register}
                  </Button>
                ) : (
                  <Popconfirm
                    placement="bottom"
                    title={
                      data.isRegistered ? label.textCancel : label.textRegister
                    }
                    onConfirm={() => props.onRegister(data.isRegistered)}
                    okText={props.language === 'ind' ? 'Ya' : 'Yes'}
                    cancelText={props.language === 'ind' ? 'Tidak' : 'No'}
                  >
                    <Button
                      className={
                        data.isRegistered
                          ? style.redButtonStyle
                          : style.buttonStyle
                      }
                      loading={props.loading}
                      disabled={data.isExpired}
                    >
                      {data.isRegistered ? label.cancelRegister : label.register}
                    </Button>
                  </Popconfirm>
                )}
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <UIShare
              label={label.share}
              url={window.location.href + `/${data.feventId}`}
              redirect={data.isExpired}
              onExpired={isExpired => setExpired(isExpired)}
            />
          </Col>
        </Row>
      </div>
      <Modal
        visible={expired}
        onCancel={() => setExpired(false)}
        className={style.modalEventExpired}
        centered
      >
        <span className={style.textEventExpired}>
          <b>{props.language === 'ind' ? 'Mohon Maaf, ' : 'Sorry, '}</b>
          {props.language === 'ind'
            ? `Acara ${data.feventName} Telah Selesai.`
            : `${data.feventName} Event Has Ended.`}
        </span>
      </Modal>
    </>
  );
};

export const UICardJadwal = withStyles(style)(CardJadwal);
