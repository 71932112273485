// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".hwUd_{width:100%;position:relative;display:inline-block}._3tcaB{position:absolute;font-size:45px;color:#fff;top:35%;left:40%;z-index:2}.Me6dc{font-family:Mulish,sans-serif;font-style:normal;font-weight:400;margin-top:20px;text-align:left}._3Jlia{font-weight:700;font-size:14px;line-height:22px}._2qNsl,._1mqZt{font-size:10px;line-height:12px}._2qNsl{font-weight:600}._1l64A{border-radius:15px;min-width:250px;background:#fff}._2zUpO ._3sZXl{padding:0}.yRZzP{text-align:left}._3Ei2D{text-align:center}._1wb5W{text-align:right}._1D7bk,._1D7bk:focus,._1D7bk:hover{margin-top:1.25rem;background:#11174e;border-radius:8px;color:#ececec}._1D7bk>span{font-size:14px;font-size:.875rem;color:#ececec}.f5enY{text-align:center;width:50%}._2wF9f{font-family:Mulish,sans-serif;font-style:normal;font-weight:700;font-size:12px;line-height:20px;text-align:center;color:#ef712b}._2YS1B>.ant-modal-content>.ant-modal-body{padding:0}", ""]);
// Exports
exports.locals = {
	"gradientCard": "hwUd_",
	"playButton": "_3tcaB",
	"textContent": "Me6dc",
	"textHead": "_3Jlia",
	"textBottom": "_2qNsl",
	"textMid": "_1mqZt",
	"bodyCard": "_1l64A",
	"custom": "_2zUpO",
	"ant-card-body": "_3sZXl",
	"leftText": "yRZzP",
	"centerText": "_3Ei2D",
	"rightText": "_1wb5W",
	"buttonStyle": "_1D7bk",
	"buttonContainer": "f5enY",
	"nameUniv": "_2wF9f",
	"cardVideo": "_2YS1B"
};
module.exports = exports;
