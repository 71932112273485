import { get, post, put } from 'axios';
import { endpoints } from '@source/config';

const isEngToBool = lang => {
  if (lang !== 'ind') {
    return true;
  }
  return false;
};
const isEngToInt = lang => {
  if (lang !== 'ind') {
    return 1;
  }
  return 0;
};

const PortalJadwalService = {
  getTentangKami(lang) {
    return get(
      `${endpoints.api}noAuth/be/api/yayasanLoyola/aboutUs?isEng=${isEngToInt(
        lang,
      )}`,
      {
        ...endpoints.setting,
      },
    ).then(res => {
      return res;
    });
  },
  getProfile(size, lang) {
    return get(
      `${
        endpoints.api
      }noAuth/be/api/yayasanLoyola/profileManagement?isEng=${isEngToInt(
        lang,
      )}&size=${size}&page=0`,
      {
        ...endpoints.setting,
      },
    );
  },
  getProfileDetail(id, lang) {
    return get(
      `${
        endpoints.api
      }noAuth/be/api/yayasanLoyola/profileManagement?isEng=${isEngToInt(
        lang,
      )}&managementId=${id}`,
      {
        ...endpoints.setting,
      },
    );
  },
  getListJadwal(lang, event = '', userId) {
    return get(
      `${
        endpoints.api
      }noAuth/be/api/yayasanLoyola/jadwal/daftarJadwal?isEng=${isEngToBool(
        lang,
      )}&eventBefore=${event}&usersId=${userId}`,
      {
        ...endpoints.setting,
      },
    ).then(res => {
      return res;
    });
  },
  getDetailJadwal(lang, id, userId) {
    return get(
      `${
        endpoints.api
      }noAuth/be/api/yayasanLoyola/jadwal/detailJadwal?fEventId=${id}&isEng=${isEngToBool(
        lang,
      )}&usersId=${userId}`,
      {
        ...endpoints.setting,
      },
    ).then(res => {
      return res;
    });
  },
  registerEvent(lang, data, token) {
    return post(
      `${
        endpoints.api
      }be/api/yayasanLoyola/jadwal/sayaMendaftar?isEng=${isEngToBool(lang)}`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        ...endpoints.setting,
      },
    ).then(res => {
      return res;
    });
  },
  cancelRegisterEvent(lang, data, token) {
    return put(
      `${
        endpoints.api
      }be/api/yayasanLoyola/jadwal/batalMendaftar?isEng=${isEngToBool(lang)}`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        ...endpoints.setting,
      },
    ).then(res => {
      return res;
    });
  },
};

const PortalGaleriService = {
  getSearchGaleri(lang, search, page) {
    const isEng = lang ? isEngToBool(lang) : false;
    const url = `${endpoints.api}noAuth/be/api/yayasanLoyola/galeri/cariAcara?page=${page}&size=4&sort=&eventName=${search}&isEng=${isEng}`;
    // let url = `https://demo5436246.mockable.io/listApapun?$search=${search}&page=${page}&size=4`
    return get(url, {
      ...endpoints.setting,
    }).then(res => {
      return res;
    });
  },
  getDetailGaleri(lang, id) {
    const isEng = lang ? isEngToBool(lang) : false;
    const url = `${endpoints.api}noAuth/be/api/yayasanLoyola/galeri/detailGaleri?eventId=${id}&isEng=${isEng}`;
    // let url = `https://demo5436246.mockable.io/listApapun?$search=${id}&page=${isEng}&size=4`
    return get(url, {
      ...endpoints.setting,
    }).then(res => {
      return res;
    });
  },
};

const DanaAbadiService = {
  danaAbadi(lang, page, size, id) {
    const isEng = isEngToInt(lang) || 0;
    const isId = id ? `&id=${id}` : ''; // for detail
    const isPage = page ? `&page=${page}` : '&page=0';
    const isSize = size ? `&size=${size}` : '';
    const url = `${endpoints.api}noAuth/be/api/danaPengembangan/danaAbadi?isEng=${isEng}${isId}${isPage}${isSize}`;
    // let url = `https://demo5436246.mockable.io/listApapun?isEng=${isEng}${isId}${isPage}${isSize}`
    return get(url, {
      ...endpoints,
    }).then(res => {
      return res;
    });
  },
  danaAbadiIsLogin(lang, page, size, token, userId, id) {
    const isEng = isEngToInt(lang) || 0;
    const isId = id ? `&id=${id}` : ''; // for detail
    const isUserId = id ? `&userId=${userId}` : '';
    const isPage = page ? `&page=${page}` : '&page=0';
    const isSize = size ? `&size=${size}` : '';
    return get(
      `${endpoints.api}be/api/danaPengembangan/danaAbadi?isEng=${isEng}${isId}${isUserId}${isPage}${isSize}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        ...endpoints.setting,
      },
    ).then(res => {
      return res;
    });
  },
  danaAbadiDetail(lang, id) {
    const isEng = isEngToInt(lang) || 0;
    const isId = id ? `&projectId=${id}` : ''; // for detail
    const url = `${endpoints.api}noAuth/be/api/danaPengembangan/danaAbadiDetail?isEng=${isEng}${isId}`;
    // let url = `https://demo5436246.mockable.io/listApapun?isEng=${isEng}${isId}${isPage}${isSize}`
    return get(url, {
      ...endpoints,
    }).then(res => {
      return res;
    });
  },

  getListLangkahKedepan(lang, projectid) {
    const isEng = isEngToBool(lang) || false;
    const isProjectId = projectid ? `&projectId=${projectid}` : '';
    // let uri = `https://demo5436246.mockable.io/listApapun?isEng=${isEng}${isUserId}${isPage}${isSIze}${isProjectId}`
    const uri = `${endpoints.api}noAuth/be/api/yayasanLoyola/prospectiveProject?isEng=${isEng}${isProjectId}`;
    return get(uri, {
      ...endpoints.setting,
    }).then(res => {
      return res;
    });
  },

  crowdFounding(lang, page, size, id) {
    const isEng = isEngToBool(lang) || false;
    const isId = id ? `&projectListId=${id}` : ''; // for detail
    const isPage = page ? `&page=${page}` : '&page=0';
    const isSize = size ? `&size=${size}` : '';
    let path = 'crowdFunding';
    if (id) {
      path = 'detailCrowdFunding';
    }
    const url = `${endpoints.api}noAuth/be/api/danaPengembangan/${path}?isEng=${isEng}${isId}${isPage}${isSize}&sort=date,desc`;
    // let url = `https://demo5436246.mockable.io/listApapun?isEng=${isEng}${isId}${isPage}${isSize}`
    return get(url, {
      ...endpoints,
    }).then(res => {
      return res;
    });
  },
  crowdFoundingIsLogin(lang, page, size, token, userId, id) {
    const isEng = isEngToBool(lang) || false;
    const isId = id ? `&projectListId=${id}` : ''; // for detail
    const isUserId = userId ? `&userId=${userId}` : '';
    const isPage = page ? `&page=${page}` : '&page=0';
    const isSize = size ? `&size=${size}` : '';
    let path = 'crowdFunding';
    if (id) {
      path = 'detailCrowdFunding';
    }
    const url = `${endpoints.api}noAuth/be/api/danaPengembangan/${path}?isEng=${isEng}${isId}${isUserId}${isPage}${isSize}&sort=date,desc`;
    // let url = `https://demo5436246.mockable.io/listApapun?isEng=${isEng}${isId}${isPage}${isSize}`
    return get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      ...endpoints.setting,
    }).then(res => {
      return res;
    });
  },
  crowdFoundingTerm(lang) {
    const isEng = isEngToBool(lang) || false;
    const url = `${endpoints.api}noAuth/be/api/danaPengembangan/termCrowdFunding?isEng=${isEng}`;
    // let url = `https://demo5436246.mockable.io/listApapun?isEng=${isEng}${isId}${isPage}${isSize}`
    return get(url, {
      ...endpoints,
    }).then(res => {
      return res;
    });
  },
  crowdFoundingPayment(lang, token, data) {
    const isEng = isEngToBool(lang) || false;
    const url = `${endpoints.api}be/api/danaPengembangan/participateCrowdFunding?isEng=${isEng}`;
    return post(url, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      ...endpoints.setting,
    }).then(res => {
      return res;
    });
  },
  getLovPaymentQR(lang) {
    const isEng = isEngToBool(lang) || false;
    const url = `${endpoints.api}noAuth/be/api/danaPengembangan/qrCode?isEng=${isEng}`;
    // const url = `https://demo5436246.mockable.io/listApapun?isEng=${isEng}`;
    return get(url, {
      ...endpoints,
    }).then(res => {
      return res;
    });
  },
};

const YayasanLoyolaService = {
  // sdw
  homepageInit(lang) {
    const isEng = isEngToBool(lang) || false;
    // let uri = `https://demo8362966.mockable.io/portal-yayasan-homepage`
    const uri = `${endpoints.noAuth}noAuth/be/api/yayasanLoyola/homepage?isEng=${isEng}`;
    return get(uri, {
      ...endpoints.setting,
    }).then(res => {
      return res;
    });
  },
  registrasi(val, lang) {
    const isEng = isEngToBool(lang) || false;
    return post(`${endpoints.api}noAuth/be/api/users/create?isEng=${isEng}`, val, {
      ...endpoints,
    });
  },
  getListLangkahKedepan(lang, page, size, userId, projectid) {
    const isEng = isEngToBool(lang) || false;
    const isUserId = userId ? `&usersId=${userId}` : '';
    const isPage = page ? `&page=${page}` : '&page=0';
    const isSIze = size ? `&size=${size}` : '';
    const isProjectId = projectid ? `&projectId=${projectid}` : '';
    // let uri = `https://demo5436246.mockable.io/listApapun?isEng=${isEng}${isUserId}${isPage}${isSIze}${isProjectId}`
    const uri = `${endpoints.api}noAuth/be/api/yayasanLoyola/prospectiveProject?isEng=${isEng}${isUserId}${isPage}${isSIze}${isProjectId}`;
    return get(uri, {
      ...endpoints.setting,
    }).then(res => {
      return res;
    });
  },
  postLangkahKedepan(lang, val, token) {
    const isEng = isEngToBool(lang) || false;
    return post(
      `${endpoints.api}be/api/yayasanLoyola/vendorRegistrasi?isEng=${isEng}&usersId=${val.userId}&projectId=${val.id}`,
      val,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        ...endpoints.setting,
      },
    );
  },
  getListCeritaKekl(lang, page, size, id) {
    const isEng = isEngToBool(lang) || false;
    const isId = id ? `&keklId=${id}` : ''; // for detail
    const isPage = page ? `&page=${page}` : '&page=0';
    const isSIze = size ? `&size=${size}` : '';
    const uri = `${endpoints.api}noAuth/be/api/yayasanLoyola/ceritaKekl?isEng=${isEng}${isId}${isPage}${isSIze}`;
    return get(uri, {
      ...endpoints.setting,
    }).then(res => {
      return res;
    });
  },
  getDetailKekl(lang, id) {
    const uri = `${endpoints.api}noAuth/be/api/admin/ceritaKEKL/detail?keklId=${id}`;
    return get(uri, {
      ...endpoints.setting,
    });
  },
  searchGlobal(lang, search) {
    const isEng = isEngToBool(lang) || false;
    const isSearch = search ? `&keyword=${search}` : ''; // for detail
    const uri = `${endpoints.api}noAuth/be/api/yayasanLoyola/search?isEng=${isEng}${isSearch}`;
    return get(uri, {
      ...endpoints.setting,
    }).then(res => {
      return res;
    });
  },

  // sdw

  portalYayasanheaderFooter(lang) {
    const isEng = lang ? isEngToBool(lang) : false;
    const url = `${endpoints.noAuth}noAuth/be/api/yayasanLoyola/headerFooter?isEng=${isEng}`;
    // let url = `https://demo5436246.mockable.io/listApapun`
    return get(url, {
      ...endpoints,
    }).then(res => {
      return res;
    });
  },

  detailSiswa(id, lang) {
    return get(`${endpoints.noAuth}api/bStudentVisionDetail/${id}`, {
      ...endpoints.setting,
    }).then(res => {
      return res;
    });
  },

  ceritaKekl(lang) {
    // let url = `${endpoints.noAuth}api/bidikti?lang=${lang}&page=0&size=10&sort=last_modified_by,desc`
    const url = `http://demo5436246.mockable.io/ceritaKEKL`;
    return get(url, {
      ...endpoints.setting,
    }).then(res => {
      return res;
    });
  },

  // Bidikti Week
  bidiktiLanding(lang) {
    return get(`${endpoints.noAuth}api/bidiktiWeek?lang=${lang}`, {
      ...endpoints.setting,
    }).then(res => {
      return res;
    });
  },
  subscribe(data) {
    return post(`${endpoints.noAuth}api/subscribe`, data, {
      ...endpoints.setting,
    }).then(res => {
      return res;
    });
  },
  regisBidiktiWeek(data, token, userId) {
    return post(
      `${endpoints.api}be/api/registerBidiktiWeek?userId=${userId}`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        ...endpoints.setting,
      },
    ).then(res => {
      return res;
    });
  },
  regisBidiktiWeekLate(data, token, userId) {
    return post(
      `${endpoints.api}be/api/bwLateRegister?userId=${userId}`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        ...endpoints.setting,
      },
    ).then(res => {
      return res;
    });
  },
  LOVSchedule() {
    return get(`${endpoints.noAuth}api/listSchedule`, {
      ...endpoints.setting,
    }).then(res => {
      return res;
    });
  },
  LOVSession(id) {
    return get(`${endpoints.noAuth}api/listSession?bwScheduleId=${id}`, {
      ...endpoints.setting,
    }).then(res => {
      return res;
    });
  },
  uploadLogo(data) {
    return post(`${endpoints.noAuth}api/upload/logo`, data, {
      ...endpoints.setting,
    }).then(res => {
      return res;
    });
  },

  // persentasi
  jadwalPresentasi() {
    return get(`${endpoints.noAuth}api/bwEventOngoing`, {
      ...endpoints,
    }).then(res => {
      return res;
    });
  },
  jadwalPresentasiLogin(token, userId) {
    return get(`${endpoints.api}be/api/bwEventOngoingLogin/${userId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      ...endpoints.setting,
    }).then(res => {
      return res;
    });
  },
  bannerJadwal() {
    return get(`${endpoints.noAuth}api/bannerImage`, {
      ...endpoints.setting,
    }).then(res => {
      return res;
    });
  },
  nextPagePresentasi() {
    return get(`${endpoints.noAuth}api/bwEventUpcoming?sort=bw_univ_id,desc`, {
      ...endpoints.setting,
    }).then(res => {
      return res;
    });
  },
  nextPagePresentasiLogin(token, userId) {
    return get(`${endpoints.api}be/api/bwEventOngoingLogin/${userId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      ...endpoints.setting,
    }).then(res => {
      return res;
    });
  },
  prevPagePresentasi() {
    return get(`${endpoints.noAuth}api/bwEventPast?sort=c.bw_schedule_date`, {
      ...endpoints.setting,
    }).then(res => {
      return res;
    });
  },
  prevPagePresentasiLogin(token, userId) {
    return get(`${endpoints.api}be/api/bwEventUpcomingLogin/${userId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      ...endpoints.setting,
    }).then(res => {
      return res;
    });
  },
  regisEvent(data, token) {
    return post(`${endpoints.api}be/api/bwParticipantRegister`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      ...endpoints.setting,
    }).then(res => {
      return res;
    });
  },
  cancelRegis(id, token, userId) {
    return post(
      `${endpoints.api}be/api/bwParticipantCancel?bwUnivId=${id}&userId=${userId}`,
      '',
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        ...endpoints.setting,
      },
    ).then(res => {
      return res;
    });
  },
  jadwalPresentasiDetail(id, lang) {
    return get(
      `${endpoints.noAuth}api/bwDetailEvent?bwUnivId=${id}&lang=${lang}`,
      {
        ...endpoints.setting,
      },
    ).then(res => {
      return res;
    });
  },
  jadwalPresentasiDetailLogin(id, token, userId, lang) {
    return get(
      `${endpoints.api}be/api/bwDetailEventLogin?bwUnivId=${id}&userId=${userId}&lang=${lang}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        ...endpoints.setting,
      },
    ).then(res => {
      return res;
    });
  },

  // galery

  galeryList(size) {
    return get(`${endpoints.noAuth}api/bwGallery?&sort=d.bw_schedule_date`, {
      ...endpoints.setting,
    }).then(res => {
      return res;
    });
  },
  nextGaleryList(size) {
    return get(
      `${endpoints.noAuth}api/bwGallery?&sort=bw_gallery_id
    `,
      {
        ...endpoints.setting,
      },
    ).then(res => {
      return res;
    });
  },
  prevGaleryList(size) {
    return get(`${endpoints.noAuth}api/bwGalleryPast?sort=d.bw_schedule_date`, {
      ...endpoints.setting,
    }).then(res => {
      return res;
    });
  },
  galeryDetail(id, lang) {
    return get(
      `${endpoints.noAuth}api/bwDetailGallery?bwGalleryId=${id}&lang=${lang}`,
      {
        ...endpoints.setting,
      },
    ).then(res => {
      return res;
    });
  },
  bidiktiHeaderFooter() {
    return get(`${endpoints.noAuth}api/initialLoad`, {
      ...endpoints,
    }).then(res => {
      return res;
    });
  },
  bidiktiRegisUniv(val) {
    return post(`${endpoints.noAuth}api/users/createUniversity`, val, {
      ...endpoints,
    })
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  bidiktiRegisGenaral(val) {
    const tempBody = {
      ...val,
    };
    return post(`${endpoints.noAuth}api/registerGeneral`, tempBody, {
      ...endpoints,
    })
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  bidiktiRegisStudent(val) {
    const tempBody = {
      ...val,
    };
    return post(`${endpoints.noAuth}api/users/createStudent`, tempBody, {
      ...endpoints,
    })
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  bidiktiRegisTeacher(val) {
    const tempBody = {
      ...val,
    };
    return post(`${endpoints.noAuth}api/users/createTeacher`, tempBody, {
      ...endpoints,
    })
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  yayasanFaq(lang) {
    const url = `${
      endpoints.api
    }noAuth/be/api/yayasanLoyola/faq?isEng=${isEngToInt(lang)}`;
    // let url = `https://demo5436246.mockable.io/listApapun?isEng=`+isEngToInt(lang)
    return get(url, {
      ...endpoints,
    }).then(res => {
      return res;
    });
  },
  bidiktiRegisterStaff(val) {
    const tempBody = {
      ...val,
    };
    return post(`${endpoints.noAuth}api/users/createStaff`, tempBody, {
      ...endpoints,
    })
      .then(res => {
        return res;
      })
      .catch(err => {
        return {
          err,
        };
      });
  },
  bidiktiRegisterStaffRelative(val) {
    const tempBody = {
      ...val,
    };
    return post(`${endpoints.noAuth}api/users/createEmpChild`, tempBody, {
      ...endpoints,
    })
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  bidiktiRegisterKEKL(val) {
    const tempBody = {
      ...val,
    };
    return post(`${endpoints.noAuth}api/users/createKekl`, tempBody, {
      ...endpoints,
    }).then(res => {
      return res;
    });
  },
  bidiktiBidangMinat() {
    return get(`${endpoints.noAuth}api/interest/list`, {
      ...endpoints,
    }).then(res => {
      return res;
    });
  },

  // Beasiswa Menu

  beasiswaInit(lang) {
    return get(
      `${endpoints.noAuth}api/beasiswa?lang=${lang}&page=0&size=4&sort=last_modified_on `,
      {
        ...endpoints,
      },
    ).then(res => {
      return res;
    });
  },
  beasiswaUmum(lang, page) {
    return get(
      `${endpoints.noAuth}api/scholarshipGeneral?lang=${lang}&page=${page -
        1}&size=8&sort=last_modified_on
    `,
      {
        ...endpoints,
      },
    ).then(res => {
      return res;
    });
  },
  beasiswaKomunitas(lang, page) {
    return get(
      `${endpoints.noAuth}api/scholarshipLoyola?lang=${lang}&page=${page -
        1}&size=8&sort=last_modified_on
    `,
      {
        ...endpoints,
      },
    ).then(res => {
      return res;
    });
  },
  beasiswaDetail(id, lang) {
    return get(
      `${endpoints.noAuth}api/detailBeasiswa?lang=${lang}&scholarshipId=${id}`,
      {
        ...endpoints,
      },
    ).then(res => {
      return res;
    });
  },
  lovCountry() {
    return get(`${endpoints.noAuth}api/country`, {
      ...endpoints,
    }).then(res => {
      return res;
    });
  },
  lovProvince(id) {
    return get(`${endpoints.noAuth}api/province?countryId=${id}`, {
      ...endpoints,
    }).then(res => {
      return res;
    });
  },
  lovCity(id) {
    return get(`${endpoints.noAuth}api/city?provinceId=${id}`, {
      ...endpoints,
    }).then(res => {
      return res;
    });
  },
  lovCurrency() {
    return get(`${endpoints.noAuth}api/currency`, {
      ...endpoints,
    }).then(res => {
      return res;
    });
  },
  donorBeasiswa(body, token) {
    return post(`${endpoints.api}be/api/regScholarship`, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      ...endpoints.setting,
    }).then(res => {
      return res;
    });
  },

  // service Halaman Universitas
  bidiktiUniversityLanding() {
    return get(`${endpoints.noAuth}api/profileUniversity`, {
      ...endpoints,
    }).then(res => {
      return res;
    });
  },
  bidiktiListPublicUniv() {
    return get(`${endpoints.noAuth}api/publicUnivList`, {
      ...endpoints,
    }).then(res => {
      return res;
    });
  },
  bidiktiListPrivateUniv() {
    return get(`${endpoints.noAuth}api/privateUnivList`, {
      ...endpoints,
    }).then(res => {
      return res;
    });
  },
  bidiktiListGovUniv() {
    return get(`${endpoints.noAuth}api/governmentUnivList`, {
      ...endpoints,
    }).then(res => {
      return res;
    });
  },
  bidiktiListEmbassyUniv() {
    return get(`${endpoints.noAuth}api/embassyUnivList`, {
      ...endpoints,
    }).then(res => {
      return res;
    });
  },
  universitasDetail(id) {
    return get(`${endpoints.noAuth}api/univDetailProfile?univId=${id}`, {
      ...endpoints,
    }).then(res => {
      return res;
    });
  },
  universitasDownloadForm(id) {
    return get(`${endpoints.noAuth}api/downloadForm?univEntId=${id}`, {
      ...endpoints,
    }).then(res => {
      return res;
    });
  },
  universitasGaleryList(search, page) {
    return get(
      `${endpoints.noAuth}api/univGallery?search=${search}&page=${page -
        1}&size=4&sort=b.univ_event_date,desc`,
      {
        ...endpoints,
      },
    ).then(res => {
      return res;
    });
  },
  universitasGaleryDetail(id) {
    return get(`${endpoints.noAuth}api/univGalleryDetail?univEventId=${id}`, {
      ...endpoints,
    }).then(res => {
      return res;
    });
  },
  regisUnivLanding(data, token, userId) {
    return post(`${endpoints.api}be/api/univRegister?userId=${userId}`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      ...endpoints.setting,
    }).then(res => {
      return res;
    });
  },

  // univ jadwal

  univJadwal() {
    return get(`${endpoints.noAuth}api/univEvent`, {
      ...endpoints,
    }).then(res => {
      return res;
    });
  },
  univJadwalLogin(token, userId) {
    return get(`${endpoints.api}be/api/univEventLogin?usersId=${userId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      ...endpoints.setting,
    }).then(res => {
      return res;
    });
  },
  nextPageJadwalUniv() {
    return get(`${endpoints.noAuth}api/univEventUpcoming`, {
      ...endpoints.setting,
    }).then(res => {
      return res;
    });
  },
  nextPageJadwalUnivLogin(token, userId) {
    return get(
      `${endpoints.api}be/api/univEventUpcomingLogin?usersId=${userId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        ...endpoints.setting,
      },
    ).then(res => {
      return res;
    });
  },
  prevPageJadwalUniv() {
    return get(`${endpoints.noAuth}api/univEventPast`, {
      ...endpoints.setting,
    }).then(res => {
      return res;
    });
  },
  regisEventUniv(id, token, userId) {
    return post(
      `${endpoints.api}be/api/univParticipantRegister?usersId=${userId}&univEventId=${id}`,
      '',
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        ...endpoints.setting,
      },
    ).then(res => {
      return res;
    });
  },
  cancelRegisEventUniv(id, token, userId) {
    return put(
      `${endpoints.api}be/api/univParticipantCancel?usersId=${userId}&univEventId=${id}`,
      '',
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        ...endpoints.setting,
      },
    ).then(res => {
      return res;
    });
  },
  jadwalUnivDetail(id) {
    return get(`${endpoints.noAuth}api/univEventDetail?univEventId=${id}`, {
      ...endpoints.setting,
    }).then(res => {
      return res;
    });
  },
  jadwalUnivDetailLogin(id, token, userId) {
    return get(
      `${endpoints.api}be/api/univEventDetailLogin?univEventId=${id}&usersId=${userId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        ...endpoints.setting,
      },
    ).then(res => {
      return res;
    });
  },
  univEntranceUploadForm(data, token) {
    return post(`${endpoints.api}be/api/uploadUnivRegisEntForm`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
      ...endpoints.setting,
    }).then(res => {
      return res;
    });
  },
  lovCountryU() {
    return get(`${endpoints.noAuth}api/country`, {
      ...endpoints,
    }).then(res => {
      return res;
    });
  },
  lovProvinceU(id) {
    return get(`${endpoints.noAuth}api/province?countryId=${id}`, {
      ...endpoints,
    }).then(res => {
      return res;
    });
  },
  lovCityU(id) {
    return get(`${endpoints.noAuth}api/city?provinceId=${id}`, {
      ...endpoints,
    }).then(res => {
      return res;
    });
  },
  univRegisToBidikti(userId, token, data) {
    return post(
      `${endpoints.api}be/api/registerBidiktiWeek?userId=${userId}`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        ...endpoints.setting,
      },
    ).then(res => {
      return res;
    });
  },
  univRegisUploadLogo(data, token) {
    return post(`${endpoints.api}be/api/uploadUnivRegisLogo`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
      ...endpoints.setting,
    }).then(res => {
      return res;
    });
  },
  univRegisUploadBanner(data, token) {
    return post(`${endpoints.api}be/api/uploadUnivRegisBanner`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
      ...endpoints.setting,
    }).then(res => {
      return res;
    });
  },
  univRegisUploadEntForm(data, token) {
    return post(`${endpoints.api}be/api/uploadUnivRegisEntForm`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
      ...endpoints.setting,
    }).then(res => {
      return res;
    });
  },
};

export {
  YayasanLoyolaService,
  PortalJadwalService,
  PortalGaleriService,
  DanaAbadiService,
};
export * from './informasi';
export * from './dana';
