/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import {
  Modal,
  Button,
  Typography,
  Row,
  Col,
  notification,
  Checkbox,
  Spin,
} from 'antd';
import useStyles from 'isomorphic-style-loader/useStyles';
import SignatureCanvas from 'react-signature-canvas';
import axios from 'axios';
import { endpoints } from '@source/config';
import AppCache from '../../lib/chache.js';
import { blobtoFile, dataURItoBlob } from '../../lib/files';
import style from './index.css';
import { DanaAbadiService } from '../../api/services';
import FormCrowdFunding from './formCrowdFunding.js';

const { Text } = Typography;

function saveFile(url, file, isAuth) {
  return new Promise((resolve, reject) => {
    const body = new FormData();
    body.append('file', file);
    body.append('path', 'signature');
    axios
      .post(url, body)
      .then(res => {
        resolve(res);
      })
      .catch(err => reject(err));
  });
}

async function saveTtd(ttd, language) {
  return new Promise((resolve, reject) => {
    const blob = dataURItoBlob(ttd);
    const file = blobtoFile(blob, 'file.jpg');
    saveFile(
      `${
        endpoints.api
      }noAuth/be/api/yayasanLoyola/upload/image?isEng=${language != 'ind'}`,
      file,
    )
      .then(res => {
        resolve(res);
        // sessionStorage.setItem('signature', JSON.stringify(ttd));
      })
      .catch(err => {
        reject(err);
      });
  });
}

export default function TermCrowdFunding(props) {
  const { visible, title, language, userData, userId, token } = props;
  useStyles(style);

  const sigPad = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [description, setDescription] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [fileName, setFileName] = useState('');
  const [isAgree, setIsAgree] = useState(false);

  React.useEffect(() => {
    if (visible) {
      setFileName('');
      setIsAgree(false)
      DanaAbadiService.crowdFoundingTerm(language)
        .then(res => {
          if (res.data) {
            if (res.data.rc == '00') {
              setDescription(res.data.data && res.data.data.fundTermCondition);
              setIsLoading(false);
            } else {
              notification.error({
                placement: 'bottomRight',
                message: 'Error',
                description: res.data
                  ? res.data.message
                  : 'Terjadi Kesalahan Pada Server',
              });
            }
          }
        })
        .catch(err => {
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: err.data
              ? err.data.message
              : 'Terjadi Kesalahan Pada Server',
          });
        });
    }
  }, [!!visible]);

  const handleAgree = async () => {
    setIsLoading(true);
    if (!sigPad.current.isEmpty()) {
      await saveTtd(
        sigPad.current.getTrimmedCanvas().toDataURL('image/jpeg'),
        language,
      )
        .then(res => {
          AppCache.set('signatureCrowdFunding', {
            fileName: res.data.fileName,
          });
          setFileName(res.data.fileName);
          setShowForm(true);
        })
        .catch(err => {
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: err.response
              ? err.response.data.message
              : err.message
              ? err.message
              : 'Gagal menyimpan tanda tangan',
          });
        });
    } else {
      notification.error({
        placement: 'bottomRight',
        message: 'Error',
        description: 'Anda belum tanda tangan',
      });
    }
    setIsLoading(false);
  };

  const handleFinish = e => {
    props.handleFinish(e);
    setShowForm(false);
  };

  const backToTerm = () => {
    setIsAgree(false)
    setShowForm(false);
  }

  const buttonCancel = () => {
    setIsAgree(false)
    setShowForm(false);
    props.buttonCancel()
  }

  return (
    <Modal
      visible={!!visible}
      className={style.appModal}
      width="600px"
      onCancel={buttonCancel}
      maskClosable={false}
    >
      <Spin spinning={isLoading}>
        <Row>
          <Col span={24} className={style.modalHeader}>
            {title}
          </Col>
        </Row>
        {!showForm ? (
          <Row>
            <Col span={24} className={style.modalBody}>
              <Typography.Title level={4}>
                Syarat & Ketentuan Donasi
              </Typography.Title>
              <div
                className={style.appCard}
                style={{ padding: '20px 20px 20px 0px', marginBottom: '20px' }}
              >
                <ol>{description || ''}</ol>
              </div>
              <Checkbox
                style={{ fontSize: '1.2em' }}
                checked={isAgree}
                value={isAgree}
                onChange={$val => setIsAgree($val.target.checked)}
              >
                Saya setuju dengan syarat & ketentuan di atas.
              </Checkbox>
            </Col>
            <Col span={24} className={style.modalBody}>
              <Typography.Title level={4}>Tanda Tangan</Typography.Title>
              <div
                className={style.appCard}
                style={{ padding: '0px', boxShadow: 'none', background: '#eee'  }}
              >
                <SignatureCanvas
                  canvasProps={{ width: 500, height: 200 }}
                  ref={sigPad}
                />
              </div>
            </Col>
            <Col
              span={24}
              className={style.modalBody}
              style={{ textAlign: 'center' }}
            >
              <Button
                className={style.appButton}
                onClick={handleAgree}
                disabled={!isAgree}
              >
                Submit
              </Button>
            </Col>
          </Row>
        ) : (
          <FormCrowdFunding
            userData={userData}
            fileName={fileName}
            userId={userId}
            pressed={handleFinish}
            onCancel={backToTerm}
            language={language}
            token={token}
          />
        )}
      </Spin>
    </Modal>
  );
}
