import React, { useEffect, useState } from 'react';
import { Modal, notification, Table } from 'antd';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './index.css';
import { DanaService } from '../../api/services/dana';

export default function ModalPenerimaBeasiswa(props) {
  useStyles(s);
  const { visible, onClose, language = 'ind', data } = props;
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [isLoading, setIsLoading] = useState(false);
  const [sort, setSort] = useState('sg_nisn,asc');
  const [dataList, setDataList] = useState([{}, {}]);
  const isEng = language === 'eng';

  useEffect(() => {
    if (props.visible) {
      loadData(data, pagination, sort);
    }
  }, [props]);

  const loadData = async (id, paging, sorting) => {
    setIsLoading(true);
    await DanaService.getPenerimaDonorBeasiswa(
      id,
      language,
      paging.current - 1,
      paging.pageSize,
      sorting,
    )
      .then(res => {
        const resData = res.data.data;
        setDataList(resData ? resData.recipientList : []);
      })
      .catch(err => {
        notification.error({
          message: 'Error',
          description: err.message,
        });
      });
    setIsLoading(false);
  };

  const handleTableChange = (paging, filters, sorter) => {
    sorter.order = sorter.order === 'ascend' ? 'asc' : 'desc';
    if (sorter.columnKey === 'sg_reason') {
      sorter.columnKey =
        language === 'eng' ? 'sg_reason_eng_ver' : 'sg_reason_ind_ver';
    }
    const sorting = sorter.order
      ? `${sorter.columnKey},${sorter.order}`
      : 'sg_nisn,asc';
    setSort(sorting);
    setPagination(paging);
    loadData(data, paging, sorting);
  };

  const columns = [
    {
      title: isEng ? 'NISN' : 'NISN',
      dataIndex: 'nisn',
      key: 'sg_nisn',
      sorter: true,
    },
    {
      title: isEng ? 'Full Name' : 'Nama Lengkap',
      dataIndex: 'name',
      key: 'sg_name',
      sorter: true,
    },
    {
      title: isEng ? 'Amount' : 'Nominal',
      dataIndex: 'nominal',
      key: 'sg_nominal',
      sorter: true,
    },
    {
      title: isEng ? 'Information' : 'Keterangan',
      dataIndex: 'reason',
      key: 'sg_reason',
      sorter: true,
    },
  ];

  return (
    <Modal
      visible={visible}
      className={s.appModal}
      width="800px"
      centered
      onCancel={onClose}
    >
      <div className={s.modalHeader}>
        {language === 'ind'
          ? 'Daftar Penerima Donor Beasiswa'
          : 'List of Scholarship Recipients'}
      </div>
      <div className={s.modalBody}>
        <Table
          columns={columns}
          size="middle"
          bordered={false}
          pagination={pagination}
          loading={isLoading}
          onChange={handleTableChange}
          dataSource={dataList}
          scroll={props.isMobile && { x: 600 }}
        />
      </div>
    </Modal>
  );
}
