import React, { useState } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Typography,
  Button,
  Select,
  Modal,
  Card,
  Tooltip,
} from 'antd';
import withStyles from 'isomorphic-style-loader/withStyles';
import logoUrl from '@global/assets/BIDikTi_Week.png';
import { ReloadOutlined } from '@ant-design/icons';
import { YayasanLoyolaService } from '@api/services';
import style from './index.css';

const FormModal = props => {
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [fileNameRandom, setFileNameRandom] = useState('');
  const [filePath, setFilePath] = useState(null);
  const [form] = Form.useForm();
  if (props.visibleSubs === false || props.visibleRegis === false) {
    form.resetFields();
  }

  const handleResetForm = () => {
    form.resetFields();
  };

  const generateRandomFileName = () => {
    return Math.random()
      .toString(36)
      .substr(2, 9);
  };

  const fileInput = React.createRef();

  const openFileBrowser = () => {
    fileInput.current.click();
  };

  const uploadAssignmentLetter = (data, fileNameParam) => {
    setLoadingUpload(true);
    YayasanLoyolaService.uploadLogo(data).then(res => {
      if (res && res.status ? res.status === 200 : false) {
        message.success('Upload success');
        setFilePath(res.data ? res.data.filePath : '');
      } else {
        message.error('Upload failed');
      }
      setLoadingUpload(false);
    });
  };

  const fileHandler = event => {
    if (event.target.files.length) {
      const file = event.target.files[0];
      const fileNameValue = file.name;
      const fileExtension = fileNameValue.slice(
        fileNameValue.lastIndexOf('.') + 1,
      );

      setFileName(fileNameValue);

      const randomNameFile = `Assignment letter ${generateRandomFileName()}.${fileExtension}`;

      const newFile = new File([file], randomNameFile, { type: file.type });

      setFileNameRandom(newFile.name);

      const formData = new FormData();
      formData.append('file', newFile);
      uploadAssignmentLetter(formData, newFile.name);
    }
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const checkFinish = (values, name, file) => {
    let temp = {};
    if (values.captcha !== props.randomValue) {
      temp = {
        captcha: undefined,
      };
      form.setFieldsValue(temp);
      props.handleRandom();
    } else if (name === 'regis') {
      props.onFinish(values, filePath);
    } else {
      props.onFinish(values);
    }
  };

  const handleScheduleChange = e => {
    let temp = {};
    temp = {
      session: undefined,
    };
    form.setFieldsValue(temp);
    props.onChangeSchedule(e);
  };

  return props.typeModal === 'subcribe' ? (
    <Modal
      visible={props.visibleSubs}
      footer={null}
      className={style.modalSubscribe}
      centered
      width={1179}
      onCancel={props.close}
    >
      <Row gutter={[24, 0]}>
        <Col span={12}>
          <img className={style.images} src={props.dataImage} />
        </Col>
        <Col span={12}>
          <Typography.Title className={style.title} level={4}>
            {props.title}
          </Typography.Title>
          <div className={style.divider} />
          <Col span={24} style={{ paddingLeft: 0, paddingRight: 30 }}>
            <Form
              form={form}
              onFinish={values => checkFinish(values, 'subs')}
              onFinishFailed={onFinishFailed}
              initialValues={
                props.userData
                  ? {
                      username: props.userData[0].userFullname,
                      email: props.userData[0].userEmail,
                    }
                  : {}
              }
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="username"
                    rules={[
                      { required: true, message: 'Please input username!' },
                    ]}
                  >
                    <Input
                      maxLength={256}
                      size="large"
                      className={style.inputStyle}
                      placeholder="Nama Lengkap"
                    />
                  </Form.Item>
                </Col>
                <Col span={24} style={{ marginBottom: '20px' }}>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Please input username!',
                      },
                      {
                        type: 'email',
                        message: 'Please input a valid email',
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      maxLength={256}
                      className={style.inputStyle}
                      placeholder="Email"
                    />
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Card className={style.captchaBox} bordered={false}>
                    <Row>
                      <Col span={20} style={{ marginBottom: '10px' }}>
                        <span className={style.textRandom}>
                          {props.randomValue}
                        </span>
                      </Col>
                      <Col span={4} style={{ textAlign: 'right' }}>
                        <Tooltip title="Reload">
                          <ReloadOutlined
                            onClick={() => props.handleRandom()}
                            size={18}
                            style={{ color: '#ef712b', cursor: 'pointer' }}
                          />
                        </Tooltip>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="captcha"
                          style={{ marginBottom: '0' }}
                          rules={[
                            {
                              required: true,
                              message: 'Wrong Captcha input captcha!',
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            className={style.inputStyle}
                            placeholder="Input Captcha"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col span={8}>
                  <Form.Item
                    style={{
                      textAlign: 'right',
                    }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      className={style.buttonSubmit}
                      size="small"
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Col>
      </Row>
    </Modal>
  ) : props.typeModal === 'past' ? (
    <Modal
      visible={props.visiblePast}
      footer={null}
      className={style.modalSubscribe}
      centered
      width={1179}
      onCancel={props.close}
    >
      <Row gutter={[24, 0]}>
        <Col span={12}>
          <img className={style.imagesPast} src={props.dataImage} />
        </Col>
        <Col span={12}>
          <Typography.Title className={style.title} level={4}>
            {props.title}
          </Typography.Title>
          <div className={style.divider} />
          <span className={style.pastTest}>
            <b>Mohon Maaf,</b> pendaftaran BIDikTi week telah ditutup. Silahkan
            isi formulir dibawah ini agar admin kami bisa melakukan contact
            lebih lanjut.
          </span>
          <Col span={24} style={{ paddingLeft: 0, paddingRight: 30 }}>
            <Form
              form={form}
              onFinish={values => checkFinish(values, 'past', filePath)}
              onFinishFailed={onFinishFailed}
              initialValues={
                props.userData
                  ? {
                      univName: props.userData[0].userFullname,
                    }
                  : {}
              }
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="univName"
                    rules={[
                      {
                        required: true,
                        message: 'Please input university name!',
                      },
                    ]}
                  >
                    <Input
                      maxLength={256}
                      size="large"
                      className={style.inputStyle}
                      placeholder="Nama Universitas"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="univPICName"
                    rules={[
                      {
                        required: true,
                        message: 'Please input university pic name!',
                      },
                    ]}
                  >
                    <Input
                      maxLength={256}
                      size="large"
                      className={style.inputStyle}
                      placeholder="Nama PIC Universitas"
                    />
                  </Form.Item>
                </Col>
                <Col span={24} style={{ marginBottom: '20px' }}>
                  <Form.Item
                    name="emailPIC"
                    rules={[
                      {
                        required: true,
                        message: 'Please input username!',
                      },
                      {
                        type: 'email',
                        message: 'Please input a valid email',
                      },
                    ]}
                  >
                    <Input
                      maxLength={125}
                      size="large"
                      className={style.inputStyle}
                      placeholder="Email PIC Aktif"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="noWa"
                    rules={[
                      {
                        required: true,
                        message: 'Please input number WA pic!',
                      },
                      {
                        pattern: /^[+\d](?:.*\d)?$/g,
                        message: 'Please input a valid WA number!',
                      },
                      {
                        min: 10,
                        message: 'Minimal WA Number 10 digits!',
                      },
                    ]}
                  >
                    <Input
                      maxLength={15}
                      size="large"
                      className={style.inputStyle}
                      placeholder="No WA PIC Aktif"
                    />
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Card className={style.captchaBox} bordered={false}>
                    <Row>
                      <Col span={20} style={{ marginBottom: '10px' }}>
                        <span className={style.textRandom}>
                          {props.randomValue}
                        </span>
                      </Col>
                      <Col span={4} style={{ textAlign: 'right' }}>
                        <Tooltip title="Reload">
                          <ReloadOutlined
                            onClick={() => props.handleRandom()}
                            size={18}
                            style={{ color: '#ef712b', cursor: 'pointer' }}
                          />
                        </Tooltip>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="captcha"
                          style={{ marginBottom: '0' }}
                          rules={[
                            {
                              required: true,
                              message: 'Please input captcha!',
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            className={style.inputStyle}
                            placeholder="Input Captcha"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col span={8}>
                  <Form.Item
                    style={{
                      textAlign: 'right',
                    }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      className={style.buttonSubmit}
                      loading={props.loading}
                      size="small"
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Col>
      </Row>
    </Modal>
  ) : (
    <Modal
      visible={props.visibleRegis}
      footer={null}
      title="none"
      className={style.modalRegis}
      width={633}
      centered
      onCancel={props.close}
    >
      <Row>
        <Col span={24}>
          <div className={style.headerForm}>
            <img src={logoUrl} style={{ marginRight: '24px' }} />
            <Typography.Title className={style.title} level={4}>
              {props.title}
            </Typography.Title>
          </div>
          <div className={style.dividerRegis} />
        </Col>
        <Col span={24}>
          <Form
            form={form}
            onFinish={values => checkFinish(values, 'regis', filePath)}
            onFinishFailed={onFinishFailed}
            initialValues={
              props.userData
                ? {
                    institusi: props.userData[0].userFullname,
                    noHp: props.userData[1].univRegPhone,
                    alamat: props.userData[1].univRegAddress,
                  }
                : {}
            }
          >
            <Row gutter={[24, 0]}>
              <Col span={24}>
                <Form.Item name="institusi">
                  <Input
                    maxLength={256}
                    size="large"
                    className={style.inputStyle}
                    placeholder="Nama Institusi"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item>
                  <Row>
                    <Col span={5} style={{ margin: 'auto' }}>
                      <span className={style.logoInstitusi}>
                        Logo Institusi
                      </span>
                    </Col>
                    <Col span={19} style={{ textAlign: 'right' }}>
                      <span
                        style={{
                          marginRight: 15,
                        }}
                      >
                        {fileName
                          ? fileName.length > 30
                            ? `${fileName.substr(0, 30)}...`
                            : fileName
                          : props.userData && props.userData[1].univRegLogoPath
                          ? props.userData[1].univRegLogoPath.length > 30
                            ? `${props.userData[1].univRegLogoPath.substr(
                                0,
                                30,
                              )}...`
                            : props.userData[1].univRegLogoPath
                          : ''}
                      </span>
                      <input
                        type="file"
                        hidden
                        onChange={fileHandler}
                        ref={fileInput}
                      />
                      <Button
                        onClick={openFileBrowser}
                        type="primary"
                        className={style.buttonUpload}
                        loading={loadingUpload}
                      >
                        Upload
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="noHp">
                  <Input
                    size="large"
                    className={style.inputStyle}
                    placeholder="Nomor Telepon Institusi"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="alamat">
                  <Input.TextArea
                    maxLength={256}
                    size="large"
                    className={style.inputStyle}
                    placeholder="Alamat Institusi"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="picName"
                  rules={[
                    {
                      required: true,
                      message: 'Please input PIC name!',
                    },
                  ]}
                >
                  <Input
                    maxLength={256}
                    size="large"
                    className={style.inputStyle}
                    placeholder="Nama Lengkap PIC Institusi"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="picNo"
                  rules={[
                    {
                      required: true,
                      message: 'Please input number WA pic!',
                    },
                    {
                      pattern: /^[+\d](?:.*\d)?$/g,
                      message: 'Please input a valid WA number!',
                    },
                    {
                      min: 10,
                      message: 'Minimal WA Number 10 digits!',
                    },
                  ]}
                >
                  <Input
                    maxLength={15}
                    size="large"
                    className={style.inputStyle}
                    placeholder="Nomor WhatsApp PIC Yang Dapat Dihubungi"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="sumberName"
                  rules={[
                    {
                      required: true,
                      message: 'Please input speaker name!',
                    },
                  ]}
                >
                  <Input
                    maxLength={256}
                    size="large"
                    className={style.inputStyle}
                    placeholder="Nama Narasumber dengan Gelar"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="descSumber"
                  rules={[
                    {
                      required: true,
                      message: 'Please input speaker name!',
                    },
                  ]}
                >
                  <Input.TextArea
                    size="large"
                    className={style.inputStyle}
                    placeholder="Deskripsi Narasumber"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="dayPresentasi"
                  rules={[
                    {
                      required: true,
                      message: 'Please input day presentation!',
                    },
                  ]}
                >
                  <Select
                    placeholder="Hari / Tanggal Presentasi"
                    onChange={e => handleScheduleChange(e)}
                  >
                    {props.listSchedule ? (
                      props.listSchedule.map((item, index) => (
                        <Select.Option
                          key={`${item.bwScheduleId}customer${index}`}
                          value={item.bwScheduleId}
                        >
                          {item.bwSchedule.replace('00:00:00.0', '')}
                        </Select.Option>
                      ))
                    ) : (
                      <></>
                    )}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="session"
                  rules={[
                    {
                      required: true,
                      message: 'Please input session!',
                    },
                  ]}
                >
                  <Select placeholder="Sesi">
                    {props.listSession ? (
                      props.listSession.map((item, index) => (
                        <Select.Option
                          key={`${item.bwSessionId}customer${index}`}
                          value={item.bwSessionId}
                        >
                          {item.bwSession}
                        </Select.Option>
                      ))
                    ) : (
                      <></>
                    )}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={16}>
                <Card className={style.captchaBox} bordered={false}>
                  <Row>
                    <Col span={20} style={{ marginBottom: '10px' }}>
                      <span className={style.textRandom}>
                        {props.randomValue}
                      </span>
                    </Col>
                    <Col span={4} style={{ textAlign: 'right' }}>
                      <Tooltip title="Reload">
                        <ReloadOutlined
                          onClick={() => props.handleRandom()}
                          size={18}
                          style={{ color: '#ef712b', cursor: 'pointer' }}
                        />
                      </Tooltip>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="captcha"
                        style={{ marginBottom: '0' }}
                        rules={[
                          {
                            required: true,
                            message: 'Please input captcha!',
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          className={style.inputStyle}
                          placeholder="Input Captcha"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={24} className={style.bottomButton}>
                <Form.Item
                  style={{
                    textAlign: 'right',
                    marginBottom: '0px',
                  }}
                >
                  <Button
                    type="link"
                    className={style.buttonReset}
                    size="small"
                    onClick={() => handleResetForm()}
                  >
                    Reset
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className={style.buttonRegis}
                    loading={props.loading}
                    size="small"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export const UIFormModal = withStyles(style)(FormModal);
