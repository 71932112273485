/* eslint-disable import/prefer-default-export */
/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Card, Row, Col, Input, Tooltip } from 'antd';
import { CheckCircleFilled, CloseCircleFilled, ReloadOutlined } from '@ant-design/icons';
import s from './index.css';
import { generateRandom } from '../../lib';

const Captcha = props => {
  const { onChange, value } = props;
  const [randomValue, setRandomValue] = useState('');
  const [isValid, setIsValid] = useState(null);

  useEffect(() => {
    if (value === null) {
      // getRandom();
    }
  }, [value]);

  useEffect(() => {
    getRandom();
  }, []);

  const getRandom = () => {
    if (randomValue && value !== null) {
      onChange(false);
      setIsValid(false);
    }
    const newValue = generateRandom(5);
    setRandomValue(newValue);
  };

  const handleChange = event => {
    const newValue = event.target.value;
    const newIsValid = newValue === randomValue ? true : 'false';
    onChange(!!newValue ? newIsValid : null);
    setIsValid(newValue === randomValue);
  };

  return (
    <React.Fragment>
      <Card className={s.captchaBox} bordered={false}>
        <Row gutter={20} style={{ marginBottom: '5px' }}>
          <Col style={{ flex: '1' }}>
            <span className={s.textRandom}>{randomValue}</span>
          </Col>
          <Col
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Tooltip title="Reload">
              <ReloadOutlined
                onClick={getRandom}
                style={{ fontSize: '1.2em' }}
              />
            </Tooltip>
          </Col>
        </Row>
        <Row>
          <Col style={{ flex: 1 }}>
            <div
              style={{
                position: 'absolute',
                right: '11px',
                fontSize: '1.3em',
                lineHeight: '40px',
                verticalAlign: 'middle',
                zIndex: 1,
              }}
            >
              {isValid !== null && (isValid ? (
                <CheckCircleFilled style={{ color: '#52c41a', verticalAlign: 'middle' }} />
              ) : (
                <CloseCircleFilled style={{ color: '#ff4d4f', verticalAlign: 'middle' }} />
              ))}
            </div>
            <Input
              onKeyUp={handleChange}
              size="large"
              className={s.inputStyle}
              placeholder="Captcha"
              maxLength={5}
            />
          </Col>
        </Row>
      </Card>
    </React.Fragment>
  );
};

export const FormCaptcha = withStyles(s)(Captcha);
