// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2J_zD{float:right}._1Bl9w{vertical-align:middle;font-size:14px;font-weight:700}._2sLfu .ant-select-arrow{color:#fff}._2sLfu .ant-select-dropdown{background:#11174e}.TeLPk{cursor:pointer;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;font-family:Mulish,sans-serif;margin-right:3px;margin-left:3px;font-size:16px;font-weight:700;color:#fff}.TeLPk:after{content:\"\";cursor:pointer;width:12px;height:12px;margin-left:5px;display:inherit;background:url(\"data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNOS44NzUgMWwtMy44OCAzLjg4TDIuMTE1IDFhLjk5Ni45OTYgMCAxMC0xLjQxIDEuNDFMNS4yOTUgN2MuMzkuMzkgMS4wMi4zOSAxLjQxIDBsNC41OS00LjU5YS45OTYuOTk2IDAgMDAwLTEuNDFjLS4zOS0uMzgtMS4wMy0uMzktMS40MiAweiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==\")!important;background-repeat:no-repeat!important;background-position:50%!important}._3_sTB{font-family:Mulish,sans-serif;font-weight:600;background:#11174e}._3_sTB .ant-dropdown-menu-item:hover{color:#11174e}._3_sTB .ant-dropdown-menu-item{color:#fff}@media only screen and (max-width:480px){._2J_zD{float:none}.TeLPk{place-content:center}}@media only screen and (orientation:landscape) and (max-width:1080px){._2J_zD{float:none}.TeLPk{place-content:center}}", ""]);
// Exports
exports.locals = {
	"root": "_2J_zD",
	"title": "_1Bl9w",
	"selectLang": "_2sLfu",
	"titleHeader": "TeLPk",
	"navMenu": "_3_sTB"
};
module.exports = exports;
