/* eslint-disable react/prop-types */
import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Row, Col } from 'antd';
import s from './index.css';

const Container = ({ style, ...props }) => {
  const center = 'center' in props;
  const breakpoint = {
    xxxl: { span: 18 },
    xxl: { span: 20 },
    xl: { span: 21 },
    md: { span: 22 },
    sm: { span: 23 },
  };
  return (
    <Row
      style={{
        padding: '0px 20px',
        justifyContent: 'center',
        ...props.styleRow,
      }}
    >
      <Col
        {...breakpoint}
        span={24}
        style={{ textAlign: center ? 'center' : '', ...style }}
      >
        {props.children}
      </Col>
    </Row>
  );
};

export const UIContainer = withStyles(s)(Container);
