/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import styles from './index.css';
import { Row, Col, Modal, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { YayasanLoyolaService } from '@api/services';
import history from '@source/history-refresh';
import debounce from 'lodash.debounce';

const Search = props => {
  const [visibleModal, setVisible] = useState(false);
  const [dataSearch, setDataSearch] = useState([]);
  let debouncedFn;

  useEffect(() => {
    if (!visibleModal) {
      setDataSearch([]);
    }
  }, [visibleModal]);

  const openModal = isOpen => {
    setVisible(isOpen);
  };

  const handleSearch = async info => {
    if (!debouncedFn) {
      debouncedFn = debounce(async (lang, keyword) => {
        if (keyword.length < 2) {
          return;
        }
        await YayasanLoyolaService.searchGlobal(lang, keyword).then(res => {
          if (res.data) {
            setDataSearch(res.data.data);
          }
        });
      }, 1000);
    }
    await debouncedFn(props.data.language, info.target.value);
  };

  const handleRedirect = data => {
    let url = '';
    const { menu, subMenu } = data;
    if (['Yayasan Loyola', 'Loyola Foundation'].includes(menu)) {
      if (['Story Of Loyola Alumni', 'Cerita KEKL'].includes(subMenu)) {
        url = data.keklId ? `/CeritaKeklDetail/${data.keklId}` : '';
      } else if (['Schedule', 'Jadwal'].includes(subMenu)) {
        url = `/Jadwal/DetailJadwal/${data.eventId}`;
      } else if (['Future Project', 'Langkah Kedepan'].includes(subMenu)) {
        url = `/langkah-ke-depan/${data.projectId}/${data.title}`;
      } else if (['Gallery', 'Galeri'].includes(subMenu)) {
        url = `/Galeri/Detail/${data.galleryId}`;
      }
    } else if (data.menu === 'Information' || data.menu === 'Informasi') {
      if (['News / Announcement', 'Pengumuman'].includes(subMenu)) {
        url = `/pengumuman/${data.announceId}`;
      } else if (['Future Project', 'Langkah Kedepan'].includes(subMenu)) {
        url = `/langkah-ke-depan/${data.projectId}/${data.title}`;
      } else {
        url = `/Informasi/Detail?type=dokumen&ownerId=${data.docOwnerId || ''}&periodId=${data.docPeriodId}`;
      }
    } else if (['Dana Pengembangan', 'Development Fund'].includes(menu)) {
      if (['Crowdfunding', 'Crowdfunding'].includes(subMenu)) {
        url = `/crowdfunding/${data.crowdfundingId}/${data.title}`;
        openModal(false);
      } else if (['Scholarship Donor', 'Donor Beasiswa'].includes(subMenu)) {
        url = `/donorBeasiswa/${data.scholarshipDonationId}`;
      }
    }
    history.push(url);
    openModal(false);
  };

  return (
    <div style={{ cursor: 'pointer' }}>
      <div onClick={() => openModal(true)}>
        <SearchOutlined
          style={{ color: '#fff', fontSize: '18px !important' }}
        />
      </div>
      {visibleModal && (
        <Modal
          visible={visibleModal}
          style={{ top: '0px', padding: '0px' }}
          width={'100%'}
          className={styles.modalContainer}
          onCancel={() => openModal(false)}
        >
          <div>
            <Row>
              <Col
                span={24}
                style={{ marginBottom: props.isMobile ? '15px' : '36px' }}
              >
                <Input
                  onChange={handleSearch}
                  bordered={false}
                  autoFocus
                  suffix={
                    <SearchOutlined
                      style={{ color: '#fff', fontSize: '14px !important' }}
                    />
                  }
                  placeholder="Search..."
                  className={styles.inputSearch}
                />
              </Col>
              <Col span={24} className={styles.colContain}>
                {dataSearch &&
                  dataSearch.map(res => {
                    const isAnnouncement = ['News / Announcement', 'Pengumuman'].includes(res.subMenu);
                    return (
                      <div
                        style={{
                          marginTop: '10px',
                          padding: '0px 24px',
                          cursor: 'pointer',
                        }}
                      >
                        <Row
                          className={styles.cointainerSearchItem}
                          onClick={() => handleRedirect(res)}
                        >
                          <Col span={24} style={{ alignSelf: 'center' }}>
                            <div className={styles.containerText}>
                              <span className={styles.textTitle}>{res.title}</span>
                              <ul className={styles.listStyle}>
                                <li className={styles.listItemStyle}>
                                  {!props.isMobile && (
                                    <span className={styles.textSearchMenu}>
                                      {res.menu} - {res.subMenu}
                                    </span>
                                  )}
                                </li>
                              </ul>
                            </div>
                          </Col>
                          {!isAnnouncement && (
                            <Col span={24} style={{ marginTop: '10px' }}>
                              <span className={styles.resultText}>
                                {res.result && res.result.length > 90
                                  ? `${res.result.substr(0, 90)}...`
                                  : res.result}
                              </span>
                            </Col>
                          )}
                          <Col span={24}>
                            <div className={styles.divider} />
                          </Col>
                        </Row>
                      </div>
                    )
                  })}
              </Col>
            </Row>
          </div>
        </Modal>

      )}
    </div>
  );
};

export const UISearch = withStyles(styles)(Search);
