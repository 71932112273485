// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1P18j{padding:0;border-radius:8px;overflow:hidden;border:0 solid #eee}._1P18j>.ant-modal-content{border-radius:8px;overflow:hidden}._1P18j>.ant-modal-content>.ant-modal-body{padding:0}._1P18j>.ant-modal-content>.ant-modal-close{color:#fff}._3xXLM{padding:30px 50px;background:#11174e;color:#fff;font-size:2em;font-weight:500;text-align:center;font-family:Poppins,sans-serif}._2s3Iy{padding:30px 50px 0;background:#fff}._2s3Iy:last-child{padding:30px 50px;background:#fff}._2aIfS{overflow:hidden;border-radius:8px;background:#fff;-webkit-box-shadow:0 0 10px rgba(0,0,0,.1);box-shadow:0 0 10px rgba(0,0,0,.1)}._24v8E,._24v8E:focus,._24v8E:hover{border-radius:4px;background:#11174e;color:#fff;border:0 solid #fff;padding:.5em 2em;height:auto;font-size:1.2em}._2aNTV{background:#dfe0e1;padding:50px}.LmCIm{text-align:\"left\";margin-bottom:10}._18eGm{min-height:50px;border-radius:2px;border:none!important;outline:0}._18eGm>.input.ant-input{color:rgba(0,0,0,.5)}._3qPop{font-weight:700;font-size:1.2em;margin-bottom:.5em}._3APpq{font-size:1.1em}", ""]);
// Exports
exports.locals = {
	"appModal": "_1P18j",
	"modalHeader": "_3xXLM",
	"modalBody": "_2s3Iy",
	"appCard": "_2aIfS",
	"appButton": "_24v8E",
	"cotainerContent": "_2aNTV",
	"labelText": "LmCIm",
	"inputStyleLogin": "_18eGm",
	"formTitle": "_3qPop",
	"formLabel": "_3APpq"
};
module.exports = exports;
