// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._9eRgb{font-family:times new roman;font-style:normal;font-weight:700;font-size:1.6em;letter-spacing:4px;font-style:italic;line-height:36px;color:#222;text-align:center}._5p_X2>.ant-card-body{overflow:hidden;padding:10px 15px 15px;border:1px solid #d9d9d9;border-radius:4px}._3axDa,._9eRgb{-webkit-user-select:none;-webkit-touch-callout:none;-moz-user-select:none;-ms-user-select:none;user-select:none}", ""]);
// Exports
exports.locals = {
	"textRandom": "_9eRgb",
	"captchaBox": "_5p_X2",
	"inputStyle": "_3axDa"
};
module.exports = exports;
