// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".wDs3V{-ms-flex-direction:column;flex-direction:column;padding:6em 0}._1mNlK,.wDs3V{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center}._1mNlK{width:100%;color:#ccc;margin-bottom:2em}._1mNlK>span{font-weight:700;font-size:2em;text-transform:capitalize}.bY_Gv>.icon{font-size:4em;margin-right:.5em;-webkit-transform:rotate(15deg);-ms-transform:rotate(15deg);transform:rotate(15deg)}.bY_Gv>.shadow{height:60px;width:60px;background:#eee;border-radius:100%;position:absolute;margin:-10px 0 0}._1V-GN{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;font-size:1.2em}", ""]);
// Exports
exports.locals = {
	"root": "wDs3V",
	"noData": "_1mNlK",
	"noDataIcon": "bY_Gv",
	"reloadBtn": "_1V-GN"
};
module.exports = exports;
