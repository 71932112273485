// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2-l4a{width:100%;position:relative;display:inline-block;height:160px;background:#ccc;text-align:center;vertical-align:center;line-height:160px;overflow:hidden}._2-l4a:hover{cursor:pointer}._18N75{position:absolute;font-size:45px;color:#fff;top:35%;left:40%;z-index:2}._3GLWQ{font-family:Mulish,sans-serif;font-style:normal;font-weight:400;padding:10px;text-align:left}._3d_ae{font-weight:700;font-size:14px;line-height:22px}.eOwbK,._1Vj6P{font-size:10px;line-height:12px}.eOwbK{font-weight:600}._3Aord{border-radius:15px;min-width:250px;background:#fff}._1eGBk ._3hAVv{padding:0}._3c5Yw{text-align:left}._3S5Fl{text-align:center}._3e3Ka{text-align:right}._2ZnbC,._2ZnbC:focus,._2ZnbC:hover{margin-top:1.25rem;background:#11174e;border-radius:8px;color:#ececec}._2ZnbC>span{font-size:14px;font-size:.875rem;color:#ececec}._2mpHC{text-align:center;width:50%}.DWZp1{font-family:Mulish,sans-serif;font-style:normal;font-weight:700;font-size:12px;line-height:20px;text-align:center;color:#ef712b}._1rYN_>.ant-modal-content>.ant-modal-body{padding:0;text-align:center}._3Vc0E{text-indent:50px;text-align:justify;letter-spacing:3px}", ""]);
// Exports
exports.locals = {
	"gradientCard": "_2-l4a",
	"playButton": "_18N75",
	"textContent": "_3GLWQ",
	"textHead": "_3d_ae",
	"textBottom": "eOwbK",
	"textMid": "_1Vj6P",
	"bodyCard": "_3Aord",
	"custom": "_1eGBk",
	"ant-card-body": "_3hAVv",
	"leftText": "_3c5Yw",
	"centerText": "_3S5Fl",
	"rightText": "_3e3Ka",
	"buttonStyle": "_2ZnbC",
	"buttonContainer": "_2mpHC",
	"nameUniv": "DWZp1",
	"cardVideo": "_1rYN_",
	"docDescription": "_3Vc0E"
};
module.exports = exports;
