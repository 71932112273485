import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Row, Col, Button, Modal, Typography, Form, Input, Radio, Checkbox } from 'antd';
import history from '@source/history';
import style from './index.css';
import ModalLogin from '../ModalLogin';
import cardProyekLabel from './language.js'

const CardProject = props => {
  const [modal, setModal] = React.useState(false);
  const [modalLogin, setModalLogin] = React.useState(false);
  const [isform, setIsForm] = React.useState(false);
  const [isChecked, setIsChecked] = React.useState(false);
  const [form] = Form.useForm();
  const [alumni, setAlumni] = React.useState(true);
  const [label, setLabel] = React.useState(cardProyekLabel(props.language));

  React.useEffect(() => {
    form.setFieldsValue({ isKekl: true })
  }, [form])

  const showModal = () => {
    if(props.token){
      setModal(true)
    }else{
      setModalLogin(true)
    }
  }
  const onChange = e => {
    setIsChecked(e.target.checked)
  };

  const onReset = e => {
    form.resetFields()
    setIsChecked(false)
    setAlumni(true)
  };

  const handleFinish=(event)=>{
    let body = {
      ...event,
      projectId:props.data.id
    }
    props.handleFinish(body)
  }

  return (
    <React.Fragment>
    {
      !props.isHiddenCard ?
        <div
          style={{
            borderRadius: '15px',
            background: 'white',
            boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
          }}
        >
          <div className={style.gradientCard}>
            <img
              alt="example"
              src={props.data.thumbnail}
              width="100%"
              height="150px"
              style={{ borderRadius: '15px 15px 0 0' }}
            />
          </div>
          <div className={style.textContent}>
            <Row>
              <Col
                span={24}
                style={{
                  textAlign: 'center',
                  color: '#12184f',
                  fontSize: 18,
                  padding: '0 0 10px 0',
                }}
              >
                <Button type='text' onClick={()=>history.push('/Informasi/Proyek/detail/'+props.data.id)}>
                  <span className={style.textHead}>{props.data.title}</span>
                </Button>
              </Col>
            </Row>
            <Row>
              <Col
                span={24}
                style={{ background: '#12184f', padding: '3px 0 3px 0' }}
              >
                <Row>
                  <Col offset={2} span={22}>
                    <Typography style={{ color: 'white' }}>
                      {label.detailProject}
                    </Typography>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Row style={{ padding: '5px 0 0 0' }}>
                  <Col offset={2} span={10}>
                    <span className={style.textMid}>{label.startYear}</span>
                  </Col>
                  <Col span={10} style={{ textAlign: 'right' }}>
                    <span className={style.textMid}>{props.data.year}</span>
                  </Col>
                </Row>
                <Row style={{ padding: '5px 0 0 0' }}>
                  <Col offset={2} span={10}>
                    <span className={style.textMid}>{label.duration}</span>
                  </Col>
                  <Col span={10} style={{ textAlign: 'right' }}>
                    <span className={style.textMid}>{props.data.duration ? props.data.duration : '-'} {label.tahun}</span>
                  </Col>
                </Row>
                <Row style={{ padding: '5px 0 0 0' }}>
                  <Col offset={2} span={10}>
                    <span className={style.textMid}>{label.value}</span>
                  </Col>
                  <Col span={10} style={{ textAlign: 'right' }}>
                    <span className={style.textMid}>{props.data.value}</span>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row style={{ paddingTop: 10 }}>
              <Col offset={2} span={20} className={style.docDescription}>
                {props.data.desc && props.data.desc.length > 90 ? props.data.desc.substr(0,90)+'...' : props.data.desc}
              </Col>
            </Row>
          </div>
          
          <div style={{ marginTop: 30 }}>
            <Row>
              <Col span={24}>
                <Button
                  style={{
                    border: '1px solid #12184f',
                    borderRadius: '0 0 15px 15px',
                    width: '100%',
                    padding: '3px 0 3px',
                    background: '#12184f',
                    color: 'white',
                  }}
                  onClick={showModal}
                  disabled={props.data.isRegistered || props.data.isExpired}
                >
                  { props.data.isRegistered ? label.cancel : label.daftar }
                </Button>
              </Col>
            </Row>
          </div>
        </div> : 
          <div style={{ marginTop: 30 }}>
            <Button
              style={{
                border: '1px solid #12184f',
                width: '100%',
                padding: '3px 0 3px',
                background: '#12184f',
                color: 'white',
              }}
              onClick={showModal}
              disabled={props.data.isRegistered || props.data.isExpired}
            >
              { props.data.isRegistered ? label.cancel : label.daftar }
            </Button>
          </div>
      }
      <ModalLogin
        visible={modalLogin}
        handleLogin={()=>setModalLogin(false)}
        handleCancelLogin={()=>setModalLogin(false)}
        hiddenButton={true}
        tokenUser={props.token}
        language={props.language}
        isMobile={props.isMobile}
      />
      <Modal
        visible={modal}
        centered
        className={style.modalParticipate}
        footer={null}
        width="40%"
        height="50%"
        onCancel={()=>{
          setModal(false)
          setIsForm(false)
        }}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className={style.modalHeader}>
          <Col span={24} className="gutter-row">
            <h3 style={{ color: 'white' }}>
              {label.participationForm}
            </h3>
          </Col>
        </Row>
        {!isform ?
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-around" className={style.modalContent}>
            <Col span={8}>
              <Button
                className={style.modalButton}
                onClick={() => setIsForm(true)}
              >
                {label.vendor}
              </Button>
            </Col>
            <Col span={8}>
              <Button
                className={style.modalButton}
                onClick={()=>history.push('/danaAbadi')}
              >
                {label.donatur}
              </Button>
            </Col>
          </Row>
          :
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className={style.modalContent} style={{ overflow: 'hidden' }}>
            <Col span={24} style={{justifyContent: 'center', textAlign: 'center', marginBottom: 20}}>
              <h2><span>{label.title}</span></h2>
            </Col>
            <Col span={24} className="gutter-row">
              <Form
                form={form}
                onFinish={event => handleFinish(event)}
                onFinishFailed={errorInfo => console.log('Failed:', errorInfo)}
                initialValues={{
                  ['isKekl']: true,
                  ['noTelp']: props.userData.udPhone,
                  ['noWA']: props.userData.udWa,
                  ['email']: props.userData.udEmail,
                }}
              >
                <Row gutter={16}>
                  <Col offset={2} span={20}>
                    <Form.Item
                      name="isKekl"
                      label={label.alumni}
                      size="large"
                      rules={[
                        {
                          required: true,
                          message: label.alumniRequired,
                        }
                      ]}
                    >
                      <Radio.Group onChange={e=>setAlumni(e.target.value)}>
                        { label.alumniLov && label.alumniLov.map(res => {
                          return <Radio value={res.key}>{res.value}</Radio>
                        })}
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  {alumni &&
                    <Col offset={2} span={20}>
                      <Form.Item>
                        <span>{label.angkatan}</span>
                      </Form.Item>
                      <Form.Item
                        name="tahunLulus"
                        rules={[
                          {
                            required: true,
                            message: label.angkatanRequired,
                          },
                          {
                            pattern: new RegExp('^[0-9]+$'), 
                            message: label.noOnlyRequired
                          }
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder={label.angkatan}
                          maxLength="4"
                        />
                      </Form.Item>
                    </Col>
                  }
                  <Col offset={2} span={20}>
                    <Form.Item>
                      <span>{label.bidang}</span>
                    </Form.Item>
                    <Form.Item
                      name="bidangUsaha"
                      rules={[
                        {
                          required: true,
                          message: label.bidangRequired,
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={label.bidang}
                        maxLength="256"
                      />
                    </Form.Item>
                  </Col>
                  <Col offset={2} span={20}>
                    <Form.Item>
                      <span>{label.namaUsaha}</span>
                    </Form.Item>
                    <Form.Item
                      name="namaUsaha"
                      rules={[
                        {
                          required: true,
                          message: label.namaUsahaRequired,
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder= {label.namaUsaha}
                        maxLength="256"
                      />
                    </Form.Item>
                  </Col>
                  <Col offset={2} span={20}>
                    <Form.Item>
                      <span>{label.alamat}</span>
                    </Form.Item>
                    <Form.Item
                      name="alamatUsaha"
                      rules={[
                        {
                          required: true,
                          message: label.alamatRequired,
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={label.alamat}
                        maxLength="256"
                      />
                    </Form.Item>
                  </Col>
                  <Col offset={2} span={20}>
                    <Form.Item>
                      <span>{label.lamaUsaha} ({label.tahun})</span>
                    </Form.Item>
                    <Form.Item
                      name="lamaUsaha"
                      rules={[
                        {
                          required: true,
                          message: label.lamaUsahaRequired,
                        },
                        {
                          pattern: new RegExp('^[0-9]+$'), 
                          message: label.noOnlyRequired
                        }
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={label.lamaUsaha}
                        maxLength="4"
                      />
                    </Form.Item>
                  </Col>
                  <Col offset={2} span={20}>
                    <Form.Item>
                      <span>{label.noTlp}</span>
                    </Form.Item>
                    <Form.Item
                      name="noTelp"
                      rules={[
                        {
                          required: true,
                          message: label.noTlpRequired,
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={label.noTlp}
                        maxLength="256"
                        type='number'
                        disabled={true}
                      />
                    </Form.Item>
                  </Col>
                  <Col offset={2} span={20}>
                    <Form.Item>
                      <span>{label.noWa}</span>
                    </Form.Item>
                    <Form.Item
                      name="noWA"
                      rules={[
                        {
                          required: true,
                          message: label.noWaRequired,
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={label.noWa}
                        maxLength="256"
                        type='number'
                        disabled={true}
                      />
                    </Form.Item>
                  </Col>
                  <Col offset={2} span={20}>
                    <Form.Item>
                      <span>{label.email}</span>
                    </Form.Item>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: label.emailRequired,
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={ label.email }
                        maxLength="256"
                        type='email'
                        disabled={true}
                      />
                    </Form.Item>
                  </Col>
                  <Col offset={2} span={20}>
                    <Form.Item
                      name="terms"
                      valuePropName="checked" 
                    >
                      <Checkbox onChange={onChange}>
                        {label.syarat}
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify="space-around">
                  <Col span={8}>
                    <Button
                      type="link"
                      className={style.modalButton}
                      onClick={onReset}
                    >
                      Reset
                      </Button>
                  </Col>
                  <Col span={8}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className={style.modalButton}
                      disabled={!isChecked}
                      loading={props.loading}
                    >
                      {label.daftarPopUp}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        }
      </Modal>
    </React.Fragment >
  );
};

export const UICardProject = withStyles(style)(CardProject);
