// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2T7hU>.ant-modal-content{border-radius:0;height:100vh;background-color:rgba(0,0,0,.9)}._2T7hU>.ant-modal-content>.ant-modal-body{padding:100px}._2T7hU>.ant-modal-content>.ant-modal-close>.ant-modal-close-x{color:#ececec}.arggc{border:none!important;border-bottom:2px solid #ececec!important;border-radius:0!important;min-height:0!important;font-size:14px}.arggc,.arggc>.ant-input{font-family:Mulish,sans-serif;font-style:normal;font-weight:700;line-height:22px;color:#ececec}.arggc>.ant-input{font-size:12px}._3i-J-{list-style:none;-webkit-padding-start:16px;padding-inline-start:16px;margin-bottom:0}._3i-J- ._3auPC:before{content:\"\\2022\";color:#ececec;font-weight:900;font-size:16px;display:inline-block;width:1.2em;margin-left:-1em;margin-right:4px}._4bY0t{font-family:Mulish,sans-serif;font-style:normal;font-weight:400;font-size:16px;line-height:1.5;color:#797979}.Bwnpa{font-family:Poppins,sans-serif;font-style:normal;font-weight:700;font-size:24px;line-height:36px;color:#ececec;margin-right:12px}._2GKgO{margin-top:16px;border-bottom:1px solid #ececec}._2UXzy{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}._3EOlN{overflow:auto;max-height:700px}.dBSIJ{font-family:Mulish,sans-serif;font-style:normal;font-weight:400;font-size:18px;line-height:23px;color:#ececec}._3EOlN::-webkit-scrollbar{width:6px}._3EOlN::-webkit-scrollbar-track{background:#464646;border-radius:5px}._3EOlN::-webkit-scrollbar-thumb{background:#777;border-radius:5px}._3EOlN::-webkit-scrollbar-thumb:hover{background:#636363}._3w8fm:hover{background-color:rgba(39,39,39,.45)}@media only screen and (max-width:480px){._2T7hU>.ant-modal-content>.ant-modal-body{padding:50px 30px}.Bwnpa{font-size:21px;line-height:1.5}.dBSIJ{font-size:14px;line-height:1.5}.arggc>.ant-input{font-size:16px;line-height:1.5}._2T7hU{max-width:none!important;margin:0!important}._3EOlN{max-height:590px}}", ""]);
// Exports
exports.locals = {
	"modalContainer": "_2T7hU",
	"inputSearch": "arggc",
	"listStyle": "_3i-J-",
	"listItemStyle": "_3auPC",
	"textSearchMenu": "_4bY0t",
	"textTitle": "Bwnpa",
	"divider": "_2GKgO",
	"containerText": "_2UXzy",
	"colContain": "_3EOlN",
	"resultText": "dBSIJ",
	"cointainerSearchItem": "_3w8fm"
};
module.exports = exports;
