/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FileTextOutlined } from '@ant-design/icons';
// import withStyles from 'isomorphic-style-loader/withStyles';
// import {styles} from

class Image extends Component {
  constructor(props) {
    super(props);

    this.state = {
      src: props.src,
      errored: false,
    };
  }

  onError = () => {
    if (!this.state.errored) {
      this.setState({
        src: this.props.fallbackSrc,
        errored: true,
      });
    }
  };

  render() {
    const { src, fallbackSrc, errored } = this.state;
    const {
      src: _1,
      fallbackSrc: _2,
      alt = '',
      iconStyle,
      ...props
    } = this.props;

    return (errored && !fallbackSrc && !alt) || (!src && !alt) ? (
      <FileTextOutlined
        style={{ fontSize: '2em', margin: 'auto', ...iconStyle }}
      />
    ) : (
      <>
        {errored ? (
          <span {...props}>{alt}</span>
        ) : (
          <img
            src={src}
            style={{ textAlign: 'center', verticalAlign: 'center' }}
            onError={this.onError}
            {...props}
            alt={alt}
          />
        )}
      </>
    );
  }
}

Image.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
  fallbackSrc: PropTypes.string,
};

export const UIImage = Image;
