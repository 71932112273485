import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Row, Col } from 'antd';
import style from './index.css';

const Information = props => {
  return (
    <div className={style.root}>
      <Row>
        <Col span={24}>
          <div className={style.gradientCard}>
            <img
              src={props.data.imageUrl}
              width="370px"
              style={{ textAlign: 'left' }}
            />
            <div
              style={{
                width: 370,
                height: 60,
                background: 'rgba(17, 23, 78, 0.6)',
                position: 'absolute',
                bottom: 0,
                left: 0,
                color: 'white',
                textAlign: 'left',
                padding: '16px 0 0 16px',
                fontSize: 'large',
              }}
            >
              {props.data.title}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export const UIInformation = withStyles(style)(Information);
