// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1_MpU{overflow:hidden;border-radius:8px;background:#fff;-webkit-box-shadow:0 0 10px rgba(0,0,0,.1);box-shadow:0 0 10px rgba(0,0,0,.1)}.rMIZL{padding:10px 20px}.rMIZL:last-child{padding-bottom:20px}._2xu5G{border-radius:8px;overflow:hidden;min-height:220px;line-height:120px;background:#eee;font-weight:700;color:#212121}._2xu5G>img{text-align:center;vertical-align:middle;width:100%}._34Ar9{margin:auto;width:50%;-ms-flex-pack:justify;justify-content:space-between;text-align:center}._3Yj6Y{font-size:1.4em}._1duSj{font-size:1.25em;font-weight:700;width:100%;display:block;color:#11174e}._38uyV{color:#fff;background:#11174e;padding:5px 0}._2fATK{color:#11174e;display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between}._2HEtJ{width:100%;display:block}", ""]);
// Exports
exports.locals = {
	"appCard": "_1_MpU",
	"cardBody": "rMIZL",
	"projectImage": "_2xu5G",
	"shareIcons": "_34Ar9",
	"shareIcon": "_3Yj6Y",
	"title": "_1duSj",
	"titleFlag": "_38uyV",
	"textTable": "_2fATK",
	"subtitle": "_2HEtJ"
};
module.exports = exports;
