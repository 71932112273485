// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root{--font-family-base:\"Mulish\",sans-serif!important;--max-content-width:1000px;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px}._1UUMy{color:#fff;background:-webkit-gradient(linear,left top,left bottom,from(#12184f),to(#060927));background:-o-linear-gradient(top,#12184f 0,#060927 100%);background:linear-gradient(180deg,#12184f,#060927);font-family:Mulish,sans-serif}._3dfAC{margin:0 auto;padding:40px 40px 0}._37a4W{font-family:Mulish,sans-serif;font-size:18px;font-weight:700;color:#fff}._2J6Xt,._1EO_k{font-family:Mulish,sans-serif;font-weight:400;font-size:16px;line-height:30px;color:#fff}._1EO_k{margin-left:1em}.kJylR{border-bottom:1px solid #ffe600;width:45px;margin:5px 0 10px}.K_KEN{width:80px;height:90px}._3Qlk3{display:block;margin-left:auto;margin-right:auto;width:80px;height:80px}@media only screen and (max-width:480px){.K_KEN{text-align:left;width:auto;height:70px}._2EI7i{padding-left:24%!important}.tprqj{text-align:center}._2hEMe{-ms-flex:0 0 25.666667%;flex:0 0 25.666667%;max-width:25.666667%}}@media only screen and (orientation:landscape) and (max-width:1080px){._2EI7i{padding-left:35%!important}.RsKas{-ms-flex:0 0 35%;flex:0 0 35%;max-width:35%}._2hEMe{-ms-flex:0 0 34.333333%;flex:0 0 34.333333%;max-width:34.333333%}._1fzxo{-ms-flex:0 0 63%;flex:0 0 63%;max-width:63%}.K_KEN{width:auto;height:auto}.K_KEN,.tprqj{text-align:center}}", ""]);
// Exports
exports.locals = {
	"root": "_1UUMy",
	"container": "_3dfAC",
	"footerLoyola": "_37a4W",
	"footerAddress": "_2J6Xt",
	"sosial": "_1EO_k",
	"divider": "kJylR",
	"logoLoyola": "K_KEN",
	"logoBidikti": "_3Qlk3",
	"footerMobile": "_2EI7i",
	"mapsMobile": "tprqj",
	"mobileSpan10": "_2hEMe",
	"mobileSpan6": "RsKas",
	"mobileSpan18": "_1fzxo"
};
module.exports = exports;
