export default function labels(lang) {

  let alumniLov = [
    {
      key: true,
      value: lang == 'eng' ? "Yes, I'm KEKL" : 'Ya, Saya KEKL'
    },
    {
      key: false,
      value: lang == 'eng' ? "No" : 'Bukan'
    },
  ]

  let notifikasi = {
    success: {
      message: 'Success',
      description: lang == 'eng' ? 'Data saved successfully' : 'Data Berhasil disimpan'
    },
    error: {
      message: 'Error',
      description: lang == 'eng' ? 'Server Error Occurred' : 'Terjadi Kesalahan Pada Server',
      descriptionLogin: lang == 'eng' ? 'Username or Password wrong!' : 'Username atau Password salah!'
    }
  }

  if(lang == 'eng'){
    return {
      //label
      detailProject: 'Project Details',
      startYear: 'Year Start',
      duration: 'Duration',
      value: 'Value',
      participation: 'I want to participate',


      participationForm:  'I Want to Participate in a Development Project that will be Implemented',
      alumni: 'Are you a Loyola Alumni (KEKL) ?',
      angkatan: 'Generation',
      bidang: 'Business Fields',
      namaUsaha: 'Business Name',
      alamat: 'Business Address',
      lamaUsaha: 'Business Length',
      noTlp: 'No. Telp',
      noWa: 'No. Whatsapp',
      email: 'E-mail',
      tahun: 'Year',
      syarat: 'I declare that I have filled in the data correctly and are fully responsible for the accuracy of the data.',

      daftar: 'I Want to Participate',
      daftarPopUp: 'Sign Me Up',
      cancel: 'Signed',
      donatur: 'As Donor',
      vendor: 'As Vendor',
      title: 'Data Vendor',

      alumniRequired: 'Please input your Alumni!',
      angkatanRequired: 'Please input your Generation!',
      bidangRequired: 'Please input your Business Fields!',
      namaUsahaRequired: 'Please input your Business Name!',
      alamatRequired: 'Please input your Business Address!',
      lamaUsahaRequired: 'Please input your Old Business!',
      noTlpRequired: 'Please input your No. Telp',
      noWaRequired: 'Please input your No. Whatsapp',
      emailRequired: 'Please input your E-mail!',
      syaratRequired: 'Please input your Terms!',
      noOnlyRequired: 'Please input number only!',

      //required
      usernameRequired: 'Please input your username!',
      passwordRequired: 'Please input your password!',
      emailRequired: 'Please input your E-mail!',

      notifikasi: notifikasi,
      alumniLov: alumniLov
    }
  }else{
    return {
      //label
      detailProject: 'Detail Proyek',
      startYear: 'Tahun Dimulai',
      duration: 'Durasi',
      value: 'Nilai proyek',
      participation: 'Saya Ingin Berpartisipasi',

      participationForm: 'Saya Ingin Berpartisipasi Dalam Proyek Pengembangan Yang Akan Dilaksanakan',
      alumni: 'Apakah Saudara(i) Alumni(KEKL) ?',
      angkatan: 'Angkatan',
      bidang: 'Bidang Usaha',
      namaUsaha: 'Nama Usaha',
      alamat: 'Alamat Usaha',
      lamaUsaha: 'Lama Usaha',
      noTlp: 'No. Telp',
      noWa: 'No. Whatsapp',
      email: 'E-mail',
      tahun: 'Tahun',
      syarat: 'Saya menyatakan telah mengisi data secara benar dan bertanggung jawab penuh atas kebenaran data tersebut.',

      daftar: 'Saya Ingin Berpartisipasi',
      daftarPopUp: 'Daftarkan Saya',
      cancel: 'Terdaftar',
      donatur: 'Sebagai Donatur',
      vendor: 'Sebagai Vendor',
      title: 'Data Vendor',

      alumniRequired: 'Apakah saudara(i) Alumni(KEKL) harus diisi!',
      angkatanRequired: 'Angkatan harus diisi!',
      bidangRequired: 'Bidang Usaha harus diisi!',
      namaUsahaRequired: 'Nama Usaha harus diisi!',
      alamatRequired: 'Alamat Usaha harus diisi!',
      lamaUsahaRequired: 'Lama Usaha harus diisi!',
      noTlpRequired: 'No. Telp harus diisi!',
      noWaRequired: 'No. Whatsapp harus diisi!',
      emailRequired: 'E-mail harus diisi!',
      syaratRequired: 'Terms harus diisi!',
      noOnlyRequired: 'Hanya diisi nomor!',

      //required

      notifikasi: notifikasi,
      alumniLov: alumniLov
    }
  }
}
