// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._31UKc{font-family:Poppins,sans-serif;font-style:normal;font-weight:700;font-size:24px;font-style:italic;line-height:36px;color:#222}._3K9Zf>.ant-card-body{padding:16px;border:1px solid #797979;border-radius:10px}", ""]);
// Exports
exports.locals = {
	"textRandom": "_31UKc",
	"captchaBox": "_3K9Zf"
};
module.exports = exports;
