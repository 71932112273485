// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2BwxZ{width:\"100%\";min-height:250px;position:relative;display:inline-block}._1YwNR{position:absolute;font-size:45px;color:#fff;top:35%;left:40%;z-index:2}._2KugH{font-family:Mulish,sans-serif;font-style:normal;font-weight:400;margin-top:20px;text-align:left}._2yeL3{font-weight:700;font-size:14px;line-height:22px}._3lMkN,._2zlLq{font-size:10px;line-height:12px}._3lMkN{font-weight:600}._1BJCX{border-radius:15px;min-width:250px;background:#fff}._33nOP ._3ECKN{padding:0}._3058g{text-align:left}._3tcRa{text-align:center}._3Nels{text-align:right}._3uWBY,._3uWBY:focus,._3uWBY:hover{margin-top:1.25rem;background:#11174e;border-radius:8px;color:#ececec}._3uWBY>span{font-size:14px;font-size:.875rem;color:#ececec}._2fpC3{text-align:center;width:50%}._3yk9T{font-family:Mulish,sans-serif;font-style:normal;font-weight:700;font-size:12px;line-height:20px;text-align:center;color:#ef712b}._34496>.ant-modal-content>.ant-modal-body{padding:0}", ""]);
// Exports
exports.locals = {
	"gradientCard": "_2BwxZ",
	"playButton": "_1YwNR",
	"textContent": "_2KugH",
	"textHead": "_2yeL3",
	"textBottom": "_3lMkN",
	"textMid": "_2zlLq",
	"bodyCard": "_1BJCX",
	"custom": "_33nOP",
	"ant-card-body": "_3ECKN",
	"leftText": "_3058g",
	"centerText": "_3tcRa",
	"rightText": "_3Nels",
	"buttonStyle": "_3uWBY",
	"buttonContainer": "_2fpC3",
	"nameUniv": "_3yk9T",
	"cardVideo": "_34496"
};
module.exports = exports;
