// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1AVlz,._2IY66{height:100%}._1AVlz{cursor:pointer;border-radius:8px;overflow:hidden;background:#fff;-webkit-box-shadow:0 0 10px rgba(0,0,0,.1);box-shadow:0 0 10px rgba(0,0,0,.1)}._39lx-{height:100%}._2oy2T{padding:10px 20px}._2oy2T:last-child{padding-bottom:20px}._2K2Cc{cursor:pointer;border-radius:4px;text-align:center;font-weight:700;color:#212121;padding:0}._2K2Cc .ant-modal-body{padding:0;background:#000}._2K2Cc .ant-modal-close-x{margin:-56px -56px 0 0;color:#fff}._2K2Cc>img{text-align:center;vertical-align:middle;width:100%;height:100%;-o-object-fit:contain;object-fit:contain}.I9inI{margin:-40px 20px 0;text-align:center;position:absolute;font-size:1.2em;font-weight:500;font-family:Poppins,sans-serif;font-style:normal;line-height:20px;color:#fff;width:calc(100% - 80px)}._1vpU8{font-size:1.25em;font-weight:700;width:100%;display:block;color:#11174e}._1VxHi{color:#fff;background:#11174e;padding:5px 0}._3n9lQ{color:#11174e;display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between}._1ALdw{width:100%;display:block}", ""]);
// Exports
exports.locals = {
	"appCard": "_1AVlz",
	"root": "_2IY66",
	"cardImage": "_39lx-",
	"cardBody": "_2oy2T",
	"appModal": "_2K2Cc",
	"cardLabel": "I9inI",
	"title": "_1vpU8",
	"titleFlag": "_1VxHi",
	"textTable": "_3n9lQ",
	"subtitle": "_1ALdw"
};
module.exports = exports;
