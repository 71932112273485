/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Modal, notification } from 'antd';
import { UIImage } from '@components';
import Downloader from 'js-file-downloader';
import { CaretRightFilled } from '@ant-design/icons';
import styles from './index.css';

const ext = {
  1: '',
  2: 'VIDEO',
  3: 'PDF',
  4: 'PPT',
  5: 'HTTP',
  6: 'HTTP',
};

const truncateTitle = (input, length) => {
  const title = input.replace(/(^\w+:|^)\/\//, '');
  if (input.length > length) {
    return `${title.substring(0, length)}...`;
  }
  return input;
};

const CardDocumentItem = props => {
  const [showPreview, setShowPreview] = useState(false);
  const { data = {}, cardHeight = 200, labelLength = 12, isMobile } = props;
  const docName = truncateTitle(props.docName, labelLength);
  const url =
    data.docFormatId === 2
      ? data.urlVideoImage
      : data.docFormatId === 1 || data.docFormatId === 5 || data.docFormatId === 6
      ? data.docUrl
      : '';
  const alt = ext[data.docFormatId];

  const handleClick = async () => {
    if ([1, 2].includes(data.docFormatId)) {
      setShowPreview(true);
    } else if ([5, 6].includes(data.docFormatId)) {
      window.open(url, '_blank');
    } else {
      notification.info({
        placement: 'bottomRight',
        message: 'Downloading File ...',
      });
      await new Downloader({ url: data.docUrl })
        .then(file => {})
        .catch(err => {});
    }
  };

  return (
    <div className={styles.root} style={{ position: 'relative' }}>
      <div className={styles.appCard} onClick={handleClick}>
        <div
          className={styles.cardImage}
          title={truncateTitle(props.docName, 100)}
          style={{
            background: [1, 2].includes(data.docFormatId) ? '#000000' : '#aaaaaa',
            height: `${cardHeight}px`,
            lineHeight: `${cardHeight - 10}px`,
          }}
        >
          <UIImage
            src={url}
            alt={alt}
            style={{ width: '100%', color: '#fff', fontSize: '2em', height: '100%', objectFit: 'cover' }}
            iconStyle={{ fontSize: '4em', color: '#eee' }}
          />
        </div>
        <div className={styles.cardLabel}>{`${docName}`} </div>
        {data.docFormatId === 2 && (
          <CaretRightFilled className={styles.playIcon} />
        )}
      </div>
      {showPreview && (
        <Modal
          visible={showPreview}
          centered
          className={styles.appModal}
          // closeIcon={<CloseOutlined />}
          footer={null}
          width="75%"
          onCancel={() => setShowPreview(false)}
        >
          {data.docFormatId === 1 && (
            <div
              style={{
                height: isMobile ? 'auto' : '600px',
                margin: 'auto',
                lineHeight: isMobile ? 'auto' : '600px',
                width: '100%',
                textAlign: 'center',
              }}
            >
              <UIImage
                src={url}
                style={{
                  height: '100%',
                  width: '100%',
                  objectFit: 'contain',
                  textAlign: 'center',
                }}
                iconStyle={{ fontSize: '4em', color: '#eee' }}
              />
            </div>
          )}
          {data.docFormatId === 2 && (
            <iframe
              title="preview-document"
              style={{ height: isMobile ? '300px' : '600px', width: '100%', margin: 'auto' }}
              id="preview-document"
              src={props.data.urlVideo}
              frameBorder="0"
              allow="accelerometer;
            autoplay;
            encrypted-media;
            gyroscope;
            picture-in-picture"
              allowFullScreen="allowfullscreen"
            />
          )}
        </Modal>
      )}
    </div>
  );
};

// eslint-disable-next-line import/prefer-default-export
export const UICardDocumentItem = withStyles(styles)(CardDocumentItem);
