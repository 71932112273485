// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root{--font-family-base:\"Mulish\",sans-serif!important;--max-content-width:1000px;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--brand-color:#61dafb}.rKG8p{background:#f5f5f5;color:#fff}._2gp6g{margin:0 auto;padding:20px 0 0;max-width:90%}._2y175{margin:auto;text-align:center;vertical-align:middle;color:rgba(0,0,0,.85)}.kgE98{color:color(#61dafb lightness(10%));color:color(var(--brand-color) lightness(10%));text-decoration:none;float:left;font-size:1.75em}._3FMkg{font-size:18px;font-weight:700;color:#222}._3o8U1,._3o8U1:hover{border-radius:5px;padding:.5em;height:auto}._3o8U1,._3o8U1:focus,._3o8U1:hover{border-color:#11174e;background-color:#11174e}._3o8U1>span{font-size:18px;font-weight:500;color:#fff}._1i__U{min-height:50px;border-radius:2px;border:none!important;outline:0}._2q1Wr{font-size:1.2em;margin-bottom:.5em;display:block}._1i__U>.input.ant-input{color:rgba(0,0,0,.5)}.ant-modal-footer,.ant-modal-header{display:none}.ant-modal-content{border-radius:8px;overflow:hidden;background:#dfe0e1}._2-1GG{padding:30px 50px;background:#11174e;color:#fff;font-weight:500;text-align:center}.ant-modal-body{padding:0}.ant-modal-close-x{color:#fff}.login-form-center{color:#11174c;text-align:center;font-weight:700}.ant-input-affix-wrapper{min-height:50px;border:1px solid #797979}", ""]);
// Exports
exports.locals = {
	"root": "rKG8p",
	"container": "_2gp6g",
	"titleCenter": "_2y175",
	"brand": "kgE98",
	"titleHeader": "_3FMkg",
	"submitButton": "_3o8U1",
	"inputStyle": "_1i__U",
	"formLabel": "_2q1Wr",
	"modalHeader": "_2-1GG"
};
module.exports = exports;
