// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._12bMu{height:100%;width:100%;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}._17c2p{width:100%;position:relative;display:inline-block;height:240px;background:#ccc;text-align:center;vertical-align:center;line-height:240px;overflow:hidden;border-radius:8px;-webkit-box-shadow:0 4px 8px 0 rgba(0,0,0,.3);box-shadow:0 4px 8px 0 rgba(0,0,0,.3)}._1KAUE{padding:28px 0 0;background:#fff}.T1oMQ{font-family:Poppins,sans-serif;font-weight:500;color:#11174e;margin-bottom:.5em}._10Fsc,.T1oMQ{font-style:normal;font-size:1.2em}._10Fsc{font-family:Mulish,sans-serif;font-weight:400;text-align:justify;margin-bottom:0}", ""]);
// Exports
exports.locals = {
	"card": "_12bMu",
	"cardHeader": "_17c2p",
	"cardBody": "_1KAUE",
	"textDate": "T1oMQ",
	"textContent": "_10Fsc"
};
module.exports = exports;
