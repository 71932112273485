// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2KBbQ{width:100%;position:relative;display:inline-block}._2frdJ{position:absolute;font-size:45px;color:#fff;top:35%;left:40%;z-index:2}._33xOn{font-family:Mulish,sans-serif;font-style:normal;font-weight:400;margin-top:20px;text-align:left}._1mPz0{font-weight:700;font-size:14px;line-height:22px}._3ZboE{font-weight:700;font-size:12px;line-height:12px}._1529M{font-weight:600;font-size:10px;line-height:12px}.Cohi3{border-radius:15px;min-width:250px;background:#fff}._1tUiv ._2xggL{padding:0}._9nWYb{text-align:left}._1vJwm{text-align:center}._24WJ7{text-align:right}._9MIG6,._9MIG6:focus,._9MIG6:hover{margin-top:1.25rem;background:#11174e;border-radius:8px;color:#ececec}._9MIG6>span{font-size:14px;font-size:.875rem;color:#ececec}._1Nh4A{text-align:center;width:50%}._5-U7C{font-family:Mulish,sans-serif;font-style:normal;font-weight:700;font-size:12px;line-height:20px;text-align:center;color:#ef712b}._1eFQZ>.ant-modal-content>.ant-modal-body{padding:0}._3hoxk{text-align:justify;font-weight:700;font-size:12px;line-height:12px}._3n4qj{margin-left:0!important;margin-right:0!important;height:100px;padding:20px;background:#11174e;text-align:center}._1Pu8W{padding:50px 0}._24vtq{border:1px solid #12184f;border-radius:15px;width:100%;padding:3px 0;background:#12184f;color:#fff}", ""]);
// Exports
exports.locals = {
	"gradientCard": "_2KBbQ",
	"playButton": "_2frdJ",
	"textContent": "_33xOn",
	"textHead": "_1mPz0",
	"textMid": "_3ZboE",
	"textBottom": "_1529M",
	"bodyCard": "Cohi3",
	"custom": "_1tUiv",
	"ant-card-body": "_2xggL",
	"leftText": "_9nWYb",
	"centerText": "_1vJwm",
	"rightText": "_24WJ7",
	"buttonStyle": "_9MIG6",
	"buttonContainer": "_1Nh4A",
	"nameUniv": "_5-U7C",
	"modalParticipate": "_1eFQZ",
	"docDescription": "_3hoxk",
	"modalHeader": "_3n4qj",
	"modalContent": "_1Pu8W",
	"modalButton": "_24vtq"
};
module.exports = exports;
