import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { UIImage } from '@components';
import history from '@source/history';
import s from './index.css';
import { Typography } from 'antd';

const CardBasic = props => {
  const {
    routeByKey = {},
    data: {
      imageUrl = '',
      title = '{Title}',
      description = `{Description}`,
      id,
    } = {},
  } = props;

  const goToModule = () => {
    history.push(`${routeByKey[id]}`);
  };

  return (
    <React.Fragment>
      <div
        className={s.card}
        style={{ cursor: 'pointer' }}
        onClick={goToModule}
      >
        <div className={s.cardHeader}>
          <UIImage
            src={imageUrl}
            style={{
              width: '100%',
              color: '#474747',
              fontSize: '2em',
              fontWeight: 'bold',
            }}
            iconStyle={{ fontSize: '2em' }}
            alt="gambar"
          />
        </div>
        <div className={s.cardBody}>
          <div className={s.textTitle}>{title}</div>
          <Typography.Paragraph
            ellipsis={{ rows: 6 }}
            className={s.textContent}
          >
            {description}
          </Typography.Paragraph>
        </div>
      </div>
    </React.Fragment>
  );
};

export const UICardBasic = withStyles(s)(CardBasic);
