// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".tvFG9{text-align:center;cursor:default;overflow:hidden;border-radius:8px;background:#fff;-webkit-box-shadow:0 0 10px rgba(0,0,0,.1);box-shadow:0 0 10px rgba(0,0,0,.1);height:100%;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:justify;justify-content:space-between}._1PT3O{padding:10px 20px}._1PT3O:last-child{padding-bottom:20px}._1PAAw{border-radius:4px;overflow:hidden;height:140px;line-height:140px;background:#eee;font-weight:700;color:#212121}._1PAAw>img{text-align:center;vertical-align:middle;width:100%;height:100%;-o-object-fit:cover;object-fit:cover}.aMJHz{margin:auto;width:50%;-ms-flex-pack:justify;justify-content:space-between;text-align:center}._3-deo{font-size:1.4em}.l1h5A{font-size:1.25em;font-weight:700;width:100%;display:block;color:#11174e;margin-bottom:0}._2gOic{color:#fff;background:#11174e;padding:5px 0}._1tSIs{color:#11174e;display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between}.zx_R2{width:100%;display:block}.a6bco{color:#11174e}", ""]);
// Exports
exports.locals = {
	"appCard": "tvFG9",
	"cardBody": "_1PT3O",
	"projectImage": "_1PAAw",
	"shareIcons": "aMJHz",
	"shareIcon": "_3-deo",
	"title": "l1h5A",
	"titleFlag": "_2gOic",
	"textTable": "_1tSIs",
	"subtitle": "zx_R2",
	"appLink": "a6bco"
};
module.exports = exports;
