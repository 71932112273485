import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Row, Col, Typography } from 'antd';
import style from './index.css';
import { UIShare } from '../Share';

const CardProjectDana = props => {
  const { data } = props;

  return (
    <div className={style.root}>
      <div className={style.appCard}>
        <Row>
          <Col span={24}>
            <div className={style.projectImage}>
              <img src={data.image} alt="IMG" />
            </div>
          </Col>
          <Col
            span={24}
            style={{ paddingBottom: '20px', cursor: 'pointer' }}
            onClick={() => props.onClick(data)}
          >
            <span className={style.title}>{data.name || '{Project Name}'}</span>
              <Typography.Paragraph ellipsis={{rows: 1}}>
                <span className={style.subtitle}>{data.type || '{subtitle}'}</span>
              </Typography.Paragraph>
          </Col>
          <Col span={24} className={style.titleFlag}>
            Detail Proyek
          </Col>
          <Col span={24} style={{ padding: '10px 20px 0px ' }}>
            <div className={style.textTable}>
              <span>Tanggal Mulai</span> <span>{data.startDate || '-'}</span>
            </div>
            <div className={style.textTable}>
              <span>Durasi</span> <span>{data.duration || '-'}</span>
            </div>
            <div className={style.textTable}>
              <span>Nilai Proyek</span> <span>{data.value || '-'}</span>
            </div>
            <div className={style.textTable}>
              <span>Status Proyek</span> <span>{data.status || '-'}</span>
            </div>
          </Col>
          <Col
            span={24}
            className={style.cardBody}
            style={{ textAlign: 'justify' }}
          >
            <div style={{height: 125}}>
              <Typography.Paragraph ellipsis={{rows: 6}}>
                {data.desc || 'No description'}
              </Typography.Paragraph>
            </div>
          </Col>
          <Col span={24} className={style.cardBody} style={{ textAlign: 'center' }}>
            <UIShare label={'Bagikan'} url={window.location.href+`/${data.projectListId}/${data.projectListName}`}/>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export const UICardProjectDana = withStyles(style)(CardProjectDana);
