import { useEffect } from 'react';
import Downloader from 'js-file-downloader';

export const generateRandom = length => {
  const randomValue =
    '012345abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let value = '';
  for (let i = length; i > 0; i--) {
    value += randomValue[Math.floor(Math.random() * randomValue.length)];
  }
  return value;
};

export const handleShare = (social, url) => {
  switch (social) {
    case 'facebook':
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`);
      break;
    case 'whatsapp':
      window.open(`https://api.whatsapp.com/send?text=${url}`);
      break;
    case 'twitter':
      window.open(`https://twitter.com/intent/tweet?url=${url}`);
      break;
    case 'line':
      window.open(`https://social-plugins.line.me/lineit/share?url=${url}`);
      break;
    default:
  }
};

export const downloadFile = link => {
  return new Promise(({ resolve, reject }) => {
    new Downloader({
      url: link,
      nameCallback: name => {
        const newName = name.replace(/%20/g, ' ');
        return newName;
      },
    })
      .then(file => {
        resolve(file);
      })
      .catch(err => {
        reject(err);
      });
  });
};

/* eslint-disable react-hooks/exhaustive-deps */

export const useScript = props => {
  const { src, clientKey } = props;
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = src;
    script.type = 'text/javascript';
    script.dataset.clientKey = clientKey;

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [props]);
};

export function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);
  // separate out the mime component
  const mimeString = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0];
  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
}

export function blobtoFile(blob, filename) {
  return new File([blob], filename);
}

export function truncateText(text, length, suffix = '...') {
  return text.length > length ? text.substring(0, length) + suffix : text;
}
