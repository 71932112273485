import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Card, Row, Col, Form, Input, Tooltip} from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import style from './index.css';

const CaptchaBox = props => {

  return (
    <React.Fragment>
      <Card className={style.captchaBox} bordered={false}>
        <Row>
          <Col span={20} style={{ marginBottom: '10px' }}>
            <span className={style.textRandom}>{props.randomValue}</span>
          </Col>
          <Col span={4} style={{ textAlign: 'right' }}>
            <Tooltip title="Reload">
              <ReloadOutlined
                onClick={() => props.handleRandom()}
                size={18}
                style={{ color: '#ef712b', cursor: 'pointer' }}
              />
            </Tooltip>
          </Col>
          <Col span={24}>
            <Form.Item
              name="captcha"
              style={{ marginBottom: '0' }}
              rules={[
                {
                  required: true,
                  message: props.language == 'eng' ? 'Please input your Captcha!' : 'Captcha harus diisi!' ,
                },
              ]}
            >
              <Input
                size="large"
                className={style.inputStyle}
                placeholder="Captcha"
              />
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </React.Fragment>
  );
};

export const UICaptchaBox = withStyles(style)(CaptchaBox);
