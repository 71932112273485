/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { UIImage } from '@components';
import history from '@source/history';
import { Typography } from 'antd';
import s from './index.css';

function CreateMarkup(html) {
  const htmlString = html.replace(/(<([^>]+)>)/gi, '');
  return <div>{htmlString}</div>;
}

const CardPengumuman = props => {
  const {
    data: {
      thumbnail = '',
      title = '{Title}',
      desc = `{Description}`,
      date,
      announceId = 0,
    } = {},
  } = props;

  const goToModule = () => {
    history.push(`/pengumuman/${announceId}`);
  };

  return (
    <React.Fragment>
      <div
        className={s.card}
        style={{ cursor: 'pointer' }}
        onClick={goToModule}
      >
        <div className={s.cardHeader}>
          <UIImage
            src={thumbnail}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              color: '#474747',
              fontSize: '2em',
              fontWeight: 'bold',
              display: 'block',
            }}
            iconStyle={{ fontSize: '2em' }}
            alt="gambar"
          />
        </div>
        <div className={s.cardBody}>
          <div className={s.textDate}>{date}</div>
          <Typography.Title level={3} className="appTitle" style={{ color: '#11174e', fontWeight: ' 700'}} >
            {title}
          </Typography.Title>
          <Typography.Paragraph
            ellipsis={{ rows: 6 }}
            className={s.textContent}
          >
            {CreateMarkup(desc)}
            {/* {desc} */}
          </Typography.Paragraph>
        </div>
      </div>
    </React.Fragment>
  );
};

export const UICardPengumuman = withStyles(s)(CardPengumuman);
