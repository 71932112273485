export default function translator(lang) {
  if (lang === 'eng') {
    return {
      previousEvent: 'Previous Event',
      latestEvent: 'Latest Event',

    }
  } else {
    return {
      previousEvent: 'Acara Sebelumnya',
      latestEvent: 'Acara Terbaru',
    }
  }
}