import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Row, Col, Button } from 'antd';
import history from '@source/history';
import { UIImage } from '@components';
import Paragraph from 'antd/lib/typography/Paragraph';
import style from './index.css';

const labels = {
  eng: {
    dokumen: 'View Document',
    foto: 'View Photo',
    video: 'View Video',
  },
  ind: {
    dokumen: 'Lihat Dokumen',
    foto: 'Lihat Foto',
    video: 'Lihat Video',
  },
};

const ext = {
  photo: null,
  video: 'VIDEO',
  pdf: 'PDF',
  ppt: 'PPT',
  tautan: 'HTTP',
  'artikel koran': 'HTTP',
};

const CardInformation = props => {
  const label = labels[props.language];
  const { url, title, year, ownerId, format} = props.data;
  const docType = props.type.toLowerCase();
  const alt = ext[format.toLowerCase()];

  const showDetail = type => {
    history.push(`Detail?type=${type}&ownerId=${ownerId}&periodId=${props.periodId}`);
  };

  // const thumbnail = url;
  return (
    <React.Fragment>
      <div
        style={{
          height: '100%',
          borderRadius: '10px',
          overflow: 'hidden',
          background: 'white',
          boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div className={style.gradientCard}>
          <UIImage
            src={docType !== 'dokumen' ? url : ''}
            style={{
              height: '100%',
              color: '#474747',
              fontSize: '2em',
              fontWeight: 'bold',
              width: '100%',
              objectFit: 'cover',
            }}
            iconStyle={{ fontSize: '4em' }}
            alt={alt}
            width="100%"
          />
        </div>
        <div className={style.textContent} style={{ flex: 1 }}>
          <Row>
            <Col
              span={24}
              style={{ textAlign: 'center', color: '#12184f', fontSize: 18 }}
            >
              <Paragraph
                title={title}
                ellipsis={{ rows: 1 }}
                className={style.textHead}
              >
                {title}
              </Paragraph>
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ textAlign: 'center' }}>
              <span className={style.textHead}>{year}</span>
            </Col>
          </Row>
        </div>
        <div style={{ marginTop: 10 }}>
          <Row>
            <Col span={24}>
              <Button
                style={{
                  border: '1px solid yellow',
                  width: '100%',
                  background: 'yellow',
                }}
                onClick={() => showDetail(docType, ownerId)}
              >
                {label[docType]}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export const UICardInformation = withStyles(style)(CardInformation);
