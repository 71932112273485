/* eslint-disable react/prop-types */
import { InputNumber } from 'antd';
import React from 'react';

const largeStyle = {
  lineHeight: '32px',
  height: '40px',
};

export default function MoneyInput({ value, onChange, ...props }) {
  let style = {
    paddingTop: '2px',
    verticalAlign: 'middle',
    display: 'inline-table',
    lineHeight: '24px',
    height: '32px',
  };
  if (props.size === 'large') {
    style = {
      ...style,
      ...largeStyle,
    };
  }

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <div className="ant-input-group-addon" style={style}>
        {'Rp '}
      </div>
      <InputNumber
        {...props}
        value={value}
        onChange={v => {
          let val = v;
          if (v) {
            val = !!v ? `${v}` : '';
            val = val.replace(/[^0-9]/g, '');
          }
          onChange(val);
        }}
        style={{
          verticalAlign: 'middle',
          borderBottomLeftRadius: 0,
          borderTopLeftRadius: 0,
          width: 'auto',
          flex: '1',
        }}
        className="app-input"
        formatter={v => {
          let val = v;
          val = val.replace(/[^0-9]/g, '');
          return `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }}
        parser={v => v.replace(/(,*)/g, '')}
      />
    </div>
  );
}

export const UIMoneyInput = MoneyInput;
