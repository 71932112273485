// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1MN2H{border-radius:15px;background-color:#fff;-webkit-box-shadow:#888 5px 10px 18px;box-shadow:5px 10px 18px #888;height:100%;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:justify;justify-content:space-between}._2aawS>.ant-modal-content{border-radius:20px!important}._3uXGh{font-family:Mulish,sans-serif;font-style:normal;font-weight:400;font-size:18px;line-height:22px;color:#000}._168fW{width:100%;position:relative;display:inline-block;height:200px;background:#ccc;text-align:center;vertical-align:center;line-height:160px;overflow:hidden}.ant-btn .ant-btn-loading-icon{line-height:1;margin-right:1em}.ant-btn .ant-btn-loading-icon .anticon{padding:0}._31cLc,._31cLc:focus,._31cLc:hover{background:#11174e}._31cLc,._31cLc:focus,._31cLc:hover,._3j0Vq,._3j0Vq:focus,._3j0Vq:hover{margin:auto;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;border-radius:8px;color:#fff;padding:.5em 2em;height:auto;border:0}._3j0Vq,._3j0Vq:focus,._3j0Vq:hover{background:#d42627}", ""]);
// Exports
exports.locals = {
	"appCard": "_1MN2H",
	"modalEventExpired": "_2aawS",
	"textEventExpired": "_3uXGh",
	"cardHeader": "_168fW",
	"buttonStyle": "_31cLc",
	"redButtonStyle": "_3j0Vq"
};
module.exports = exports;
