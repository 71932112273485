/* eslint-disable prettier/prettier */
import { get, post, put } from 'axios';
import { endpoints } from '@source/config';

const isEng = lang => {
  return lang !== 'ind' ? 'true' : 'false';
};

const InformasiService = {
  getInformationDescription(lang) {
    const uri = `${endpoints.api}noAuth/be/api/yayasanLoyola/informasi?isEng=${isEng(
      lang,
    )}`;
    return get(uri, {
      ...endpoints.setting,
    })
  },

  getDocument(docType, docPeriodId, lang) {
    const uri = `${
      endpoints.api
    }noAuth/be/api/yayasanLoyola/documentList?isEng=${isEng(
      lang,
    )}&docFormatId=${docType}&docPeriodId=${docPeriodId}`;
    return get(uri, {
      ...endpoints.setting,
    });
  },

  getDocuments(docPeriodId, lang, docType = 4, size) {
    const limit = docType === 4 ? '&size=5' : '';
    const uri = `${endpoints.api}noAuth/be/api/yayasanLoyola/document?isEng=${isEng(
      lang,
    )}&docPeriodId=${docPeriodId}&formatId=${docType}${limit}&size=${size}`;
    return get(uri, {
      ...endpoints.setting,
    });
  },

  getDocumentDetail(ownerId, lang) {
    const uri = `${
      endpoints.api
    }noAuth/be/api/yayasanLoyola/documentDetail?isEng=${isEng(
      lang,
    )}&id=${ownerId}`;
    return get(uri, {
        ...endpoints.setting,
      })
  },

  saveDocument(data, token) {
    const uri = `${endpoints.api}be/api/yayasanLoyola/createDocumentation`;
    return post(uri, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      ...endpoints.setting,
    })
  },

  upladoDocumentUrl(lang, type = 'image', path = 'user%2FimageUser') {
    const uploadType =
      type.toLowerCase() === 'foto' ? 'image' : type.toLowerCase();
    return `${
      endpoints.api
    }noAuth/be/api/yayasanLoyola/upload/${uploadType}?path=${path}&isEng=${isEng(lang)}`;
  },

  getPengumuman(lang, params) {
    const uri = `${endpoints.api}noAuth/be/api/yayasanLoyola/pengumuman?isEng=${isEng(lang)}&size=${params.size}&announceCatId=${params.announceCatId}`;
    return get(uri, {...endpoints.setting });
  },

  getKategoriPengumuman() {
    const uri = `${endpoints.api}noAuth/be/api/admin/master/announcementCategory?keyword=`;
    return get(uri, {...endpoints.setting });
  },

  getDetailPengumuman(lang, announceId) {
    const uri = `${endpoints.api}noAuth/be/api/yayasanLoyola/pengumuman/detail?announceId=${announceId}&isEng=${isEng(lang)}`;
    return get(uri, {...endpoints.setting });
  },

  getYears(token, api) {
    const uri = `${endpoints.api}be/api/lov/${api}`;
    return get(uri, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      ...endpoints.setting,
    })
  }
};

export { InformasiService };
