// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3ovdn{height:100%}.B78uk{overflow:hidden;border-radius:8px;background:#fff;-webkit-box-shadow:0 0 10px rgba(0,0,0,.1);box-shadow:0 0 10px rgba(0,0,0,.1);display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:justify;justify-content:space-between}._1ju4J{padding:10px 20px}._1ju4J:last-child{padding-bottom:20px}._1TM6G{border-radius:4px;overflow:hidden;height:140px;line-height:140px;background:#eee;font-weight:700;color:#212121}._1TM6G>img{text-align:center;vertical-align:middle;width:100%;height:100%;-o-object-fit:cover;object-fit:cover}._2qJlZ{margin:auto;width:50%;-ms-flex-pack:justify;justify-content:space-between;text-align:center}._6Q2MK{font-size:1.4em}._282dD{font-size:1.25em;font-weight:700;width:100%;display:block;color:#11174e;margin-bottom:0!important}._1ydOo{color:#fff;background:#11174e;padding:5px 0}._39uGB{color:#11174e;display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between}.PKMD-{width:100%;display:block;margin-bottom:0!important}._3p7_y,._3p7_y:focus,._3p7_y:hover{border-radius:4px;background:#11174e;color:#fff;border:0 solid #fff;padding:.5em 2em;height:auto;font-size:1.2em}", ""]);
// Exports
exports.locals = {
	"root": "_3ovdn",
	"appCard": "B78uk",
	"cardBody": "_1ju4J",
	"projectImage": "_1TM6G",
	"shareIcons": "_2qJlZ",
	"shareIcon": "_6Q2MK",
	"title": "_282dD",
	"titleFlag": "_1ydOo",
	"textTable": "_39uGB",
	"subtitle": "PKMD-",
	"appButton": "_3p7_y"
};
module.exports = exports;
