export default function loginLabel(lang) {
  const notifikasi = {
    success: {
      message: 'Success',
      description:
        lang == 'eng' ? 'Data saved successfully' : 'Data Berhasil disimpan',
    },
    error: {
      message: 'Error',
      description:
        lang == 'eng'
          ? 'Server Error Occurred'
          : 'Terjadi Kesalahan Pada Server',
      descriptionLogin:
        lang == 'eng'
          ? 'Username or Password wrong!'
          : 'Username atau Password salah!',
    },
  };

  return lang === 'eng'
    ? {
        // label
        punyaAkun: "Don't have an account yet?",
        lupaPassword: 'Forgot Password?',
        login: 'Login',
        username: 'username',
        password: 'password',
        email: 'E-mail',
        back: 'Back',
        submit: 'Submit',
        changePass: 'Change Password',
        logout: 'Logout',

        // required
        usernameRequired: 'Please input your username!',
        passwordRequired: 'Please input your password!',
        emailRequired: 'E-mail is required!',

        notifikasi,
      }
    : {
        // label
        punyaAkun: 'Belum punya akun?',
        lupaPassword: 'Lupa Password?',
        login: 'Login',
        username: 'username',
        password: 'password',
        email: 'E-mail',
        back: 'Kembali',
        submit: 'Submit',
        changePass: 'Ubah Password',
        logout: 'Logout',

        // required
        usernameRequired: 'Please input your username!',
        passwordRequired: 'Please input your password!',
        emailRequired: 'Email harus diisi!',

        notifikasi,
      };
}
