module.exports = {
  development: {
    port: 3000,
    endpoints: {
      clientUrl: 'https://portal.yayasanloyola.org/', // dev
      serverUrl: 'https://portal.yayasanloyola.org/', // dev
      // clientUrl: 'https://portal.yayasanloyola.org/', // PROD
      // serverUrl: 'https://portal.yayasanloyola.org/', // PROD
      // api: 'http://25.15.225.89:9015/', // local BE
      api: 'https://portal.yayasanloyola.org:9025/', // server dev
      // api: 'https://loyola.cloudias79.com:9115/', // server PROD
      // noAuth: `http://25.15.225.89:9012/`, // local BE
      noAuth: 'https://portal.yayasanloyola.org:9025/', // server dev
      // noAuth: 'https://loyola.cloudias79.com:9112/', // server PROD
      domain: 'https://portal.yayasanloyola.org/', // dev
      // domain: 'https://portal-yayasan-master.cloudias79.com/', // PROD
      setting: {
        timeout: 100000,
        maxRedirects: 1,
      },
      midtransScript: 'https://app.midtrans.com/snap/snap.js',
      midtransClientKey: 'Mid-client-uDVew773G69MsqAf',
    },
  },
  staging: {
    port: 3000,
    endpoints: {
      clientUrl: 'https://portal.yayasanloyola.org/', // dev
      serverUrl: 'https://portal.yayasanloyola.org/', // dev
      // clientUrl: 'https://portal.yayasanloyola.org/', // PROD
      // serverUrl: 'https://portal.yayasanloyola.org/', // PROD
      // api: 'https://portal.yayasanloyola.org:9056/', // local BE
      api: 'https://portal.yayasanloyola.org:9025/', // server dev
      // api: 'https://loyola.cloudias79.com:9115/', // server PROD
      noAuth: `https://portal.yayasanloyola.org:9025/`, // local BE
      // noAuth: 'https://portal.yayasanloyola.org:9052/', // server dev
      // noAuth: 'https://loyola.cloudias79.com:9112/', // server PROD
      domain: 'https://portal.yayasanloyola.org/', // dev
      // domain: 'https://portal-yayasan-master.cloudias79.com/', // PROD
      setting: {
        timeout: 100000,
        maxRedirects: 1,
      },
      midtransScript: 'https://app.midtrans.com/snap/snap.js',
      midtransClientKey: 'Mid-client-uDVew773G69MsqAf',
    },
  },
  production: {
    port: 3000,
    endpoints: {
      clientUrl: 'https://portal.yayasanloyola.org/', // dev
      serverUrl: 'https://portal.yayasanloyola.org/', // dev
      // clientUrl: 'https://portal.yayasanloyola.org/', // PROD
      // serverUrl: 'https://portal.yayasanloyola.org/', // PROD
      // api: 'http://25.21.250.1:9015/', // local BE
      api: 'https://portal.yayasanloyola.org:9025/', // server dev
      // api: 'https://loyola.cloudias79.com:9115/', // server PROD
      // noAuth: `http://25.21.250.1:9012/`, // local BE
      noAuth: 'https://portal.yayasanloyola.org:9022/', // server dev
      // noAuth: 'https://loyola.cloudias79.com:9112/', // server PROD
      domain: 'https://portal.yayasanloyola.org/', // dev
      // domain: 'https://portal-yayasan-master.cloudias79.com/', // PROD
      setting: {
        timeout: 100000,
        maxRedirects: 1,
      },
      midtransScript: 'https://app.midtrans.com/snap/snap.js',
      midtransClientKey: 'Mid-client-uDVew773G69MsqAf',
    },
  },
};
