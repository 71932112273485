import React, { useState} from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Card, Row, Col, Button, Modal, Popconfirm } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons'
import style from './index.css';
import history from '@source/history';
import { handleShare } from '@lib'
// import Carousel from 'nuka-carousel'

const CarouselCard = props => {
  const [modalVid, setModalVid] = useState(false);
  const [dataVid, setDataVid] = useState('');

  const withDetail = props.withDetail !== undefined ? props.withDetail : true;
  const dataCard = props.dataCard ? props.dataCard : []

  const handleDirectDetail = id => {
    if (props.from === 'jadwal') {
      history.push(`/week/jadwal/${id}`);
    }else {
      history.push(`/week/galery/${id}`);
    }

  }

  const clickShareIcon = (name, id) => {
    let url = ''
    if (props.from === 'jadwal') {
      url = `${props.urlWeb}/week/jadwal/${id}`
    }else {
      url = `${props.urlWeb}/week/galery/${id}`;
    }
    handleShare(name, url)
  }

  const playVid = (data) => {
    setDataVid(data)
    setModalVid(true)
  }

  const handleClose = () => {
    setModalVid(false)
    setDataVid('')
  }


  const cover = (value) => {
    let kuota = '-'
    if (value.bwUnivOtherQuota && value.bwUnivLoyolaQuota) {
      kuota = value.bwUnivOtherQuota + value.bwUnivLoyolaQuota
    }
    return (
      <Row>
        {withDetail ?
          <Col span={24} style={{ marginTop: '40px' }}>
            <img alt="example" width="90" height="90" src={value.bidiktiWeekUniversityLogoPath} />
          </Col>
        :
          <Col span={24}>
            <div className={style.gradientCard}>
              <CaretRightOutlined className={style.playButton} onClick={() => playVid(value.bidiktiGalleryEmbed)} />
              <img
                alt="example"
                src={value.bidiktiGalleryImage}
                width='100%'
                style={{borderRadius: '20px 20px 0 0'}}
              />
            </div>
          </Col>
        }
        <Col span={24} style={{ marginTop: '15px' }}>
          <span className={style.titleUniversitas} onClick={() => handleDirectDetail(value.bwUniversityId ? value.bwUniversityId : value.bwGalleryId)}>
            {value.bwUnivName.length > 20 ? value.bwUnivName.substr(0,20)+'...': value.bwUnivName}
          </span>
        </Col>
        {withDetail ? (
          <div style={{width: '100%'}}>
            <Col span={24} style={{ marginTop: '3px', textAlign: 'center' }}>
              <span className={style.subTitle}>{value.isOnline}</span>
            </Col>
            <Col span={24} style={{ marginTop: '24px', textAlign: 'left' }}>
              <span className={style.dateEvent}>{value.bwUnivSchedule.replace('00:00:00.0', '')}</span>
            </Col>
            <Col span={24} style={{ marginTop: '8px', textAlign: 'left' }}>
              <span className={style.timeEvent}>{value.bwUnivSession}</span>
            </Col>
            <Col span={24} style={{ marginTop: '8px', textAlign: 'left' }}>
              <span className={style.timeEvent}>{value.bwUnivMedia && value.bwUnivMedia.length > 20 ? value.bwUnivMedia.substr(0,20)+'...' : value.bwUnivMedia}</span>
            </Col>
            <Col span={24} style={{ marginTop: '10px', textAlign: 'left' }}>
              <span className={style.koutaEvent}>Kuota</span>
            </Col>
            <Col span={24} style={{ marginTop: '7px', textAlign: 'left' }}>
              <span className={style.kouta}>{kuota}</span>
            </Col>
          </div>
        ) : (
          <></>
        )}
      </Row>
    );
  };

  const headCard = () => {
    return <span className={style.headCard}>Segera</span>;
  };

  const textConfirm = 'Apakah Anda yakin membatalkan kehadiran pada presentasi ini?'
  const textRegisConfirm = "Apakah anda yakin mendaftar presentasi ini?"
  return (
    <div className={style.root}>
      <Row gutter={[32, 24]}>
        {dataCard.map((value, index) => {
          return(
            props.moreData > index &&
            <Col span={6}>
              {value.isSoon ? (
                <Card
                  extra={headCard()}
                  className={style.cardBody}
                  style={{ width: 230 }}
                  // hoverable
                  cover={cover(value)}
                >
                  { withDetail ?
                    value.isRegistration ? (
                      <Popconfirm placement="bottom" title={textConfirm} onConfirm={() => props.handleCancel(value.bwUniversityId, index)} okText="Yes" cancelText="No">
                        <Button
                          loading={props.loadingButton[index].isLoading}
                          className={style.buttonCancel}
                          // onClick={() => props.handleCancel(value.bwUniversityId, index)}
                        >Cancel</Button>
                      </Popconfirm>
                    ) : (
                      props.userRoleGroup !== undefined ? (
                        props.userRoleGroup === 'Komunitas Loyola' ? (
                          value.isLoyolaAvailable === true ? (
                            <Popconfirm placement="bottom" title={textRegisConfirm} onConfirm={() => props.handleRegisEvent(value.bwUniversityId, index)} okText="Yes" cancelText="No">
                              <Button
                                loading={props.loadingButton[index].isLoading}
                                className={style.buttonDaftar}
                              >Daftar</Button>
                            </Popconfirm>
                          ) : (
                            <Button
                              loading={props.loadingButton[index].isLoading}
                              disabled={true}
                              className={style.buttonDaftar}
                            >Daftar</Button>
                          )
                        ) : (
                          value.isOtherAvailable === true ? (
                            <Popconfirm placement="bottom" title={textRegisConfirm} onConfirm={() => props.handleRegisEvent(value.bwUniversityId, index)} okText="Yes" cancelText="No">
                              <Button
                                loading={props.loadingButton[index].isLoading}
                                className={style.buttonDaftar}
                              >Daftar</Button>
                            </Popconfirm>
                          ) : (
                            <Button
                              loading={props.loadingButton[index].isLoading}
                              disabled={true}
                              className={style.buttonDaftar}
                            >Daftar</Button>
                          )
                        )
                      ) : (
                      // <Popconfirm placement="bottom" title={textRegisConfirm} onConfirm={() => props.handleRegisEvent(value.bwUniversityId, index)} okText="Yes" cancelText="No">
                        <Button
                          loading={props.loadingButton[index].isLoading}
                          disabled={ value.isLoyolaAvailable === undefined || value.isOtherAvailable === undefined || (!value.isLoyolaAvailable && !value.isOtherAvailable) }
                          className={style.buttonDaftar}
                          onClick={() => props.handleRegisEvent(value.bwUniversityId, index)}
                        >Daftar</Button>
                      // </Popconfirm>
                      )
                    )
                    :
                    <></>
                  }
                  {props.shareButton[index].buttonShare ? (
                    <Row justify="center" style={{ marginTop: '6px' }}>
                      <Col span={5}>
                        <span className={style.whatAppsIcon} onClick={() => clickShareIcon('whatsapp', value.bwUniversityId ? value.bwUniversityId : value.bwGalleryId)} />
                      </Col>
                      <Col span={5}>
                        <span className={style.lineIcon} onClick={() => clickShareIcon('line', value.bwUniversityId ? value.bwUniversityId : value.bwGalleryId)}/>
                      </Col>
                      <Col span={5}>
                        <span className={style.facebookIcon} onClick={() => clickShareIcon('facebook', value.bwUniversityId ? value.bwUniversityId : value.bwGalleryId)}/>
                      </Col>
                      <Col span={5}>
                        <span className={style.twitterIcon} onClick={() => clickShareIcon('twitter', value.bwUniversityId ? value.bwUniversityId : value.bwGalleryId)}/>
                      </Col>
                    </Row>
                  ) : (
                    <span
                      className={style.shareSpan}
                      onClick={() => props.onClickShare(index)}
                    >
                      Share
                    </span>
                  )}
                </Card>
              ) : (
                <Card
                  extra={'none'}
                  className={`${style.cardBodyNone} ${!withDetail && style.galeryStyle}`}
                  style={{ width: 230 }}
                  // hoverable
                  cover={cover(value)}
                  // onClick={() => handleDirectDetail(value.bwUniversityId ? value.bwUniversityId : value.bwGalleryId)}
                >
                  {withDetail ?
                    value.isRegistration ? (
                      <Popconfirm placement="bottom" title={textConfirm} onConfirm={() => props.handleCancel(value.bwUniversityId, index)} okText="Yes" cancelText="No">
                        <Button
                          loading={props.loadingButton[index].isLoading}
                          className={style.buttonCancel}
                          // onClick={() => props.handleCancel(value.bwUniversityId, index)}
                        >Cancel</Button>
                      </Popconfirm>
                    ) : (
                      props.userRoleGroup !== undefined ? (
                        props.userRoleGroup === 'Komunitas Loyola' ? (
                          value.isLoyolaAvailable === true ? (
                            <Popconfirm placement="bottom" title={textRegisConfirm} onConfirm={() => props.handleRegisEvent(value.bwUniversityId, index)} okText="Yes" cancelText="No">
                              <Button
                                loading={props.loadingButton[index].isLoading}
                                className={style.buttonDaftar}
                              >Daftar</Button>
                            </Popconfirm>
                          ) : (
                            <Button
                              loading={props.loadingButton[index].isLoading}
                              disabled={true}
                              className={style.buttonDaftar}
                            >Daftar</Button>
                          )
                        ) : (
                          value.isOtherAvailable === true ? (
                            <Popconfirm placement="bottom" title={textRegisConfirm} onConfirm={() => props.handleRegisEvent(value.bwUniversityId, index)} okText="Yes" cancelText="No">
                              <Button
                                loading={props.loadingButton[index].isLoading}
                                className={style.buttonDaftar}
                              >Daftar</Button>
                            </Popconfirm>
                          ) : (
                            <Button
                              loading={props.loadingButton[index].isLoading}
                              disabled={true}
                              className={style.buttonDaftar}
                            >Daftar</Button>
                          )
                        )
                      ) : (
                      // <Popconfirm placement="bottom" title={textRegisConfirm} onConfirm={() => props.handleRegisEvent(value.bwUniversityId, index)} okText="Yes" cancelText="No">
                        <Button
                          loading={props.loadingButton[index].isLoading}
                          disabled={ value.isLoyolaAvailable === undefined || value.isOtherAvailable === undefined || (!value.isLoyolaAvailable && !value.isOtherAvailable) }
                          className={style.buttonDaftar}
                          onClick={() => props.handleRegisEvent(value.bwUniversityId, index)}
                        >Daftar</Button>
                      // </Popconfirm>
                      )
                    )
                    :
                    <></>
                  }
                  {props.shareButton[index].buttonShare ? (
                    <Row justify="center" style={{ marginTop: '6px' }}>
                      <Col span={5}>
                        <span className={style.whatAppsIcon} onClick={() => clickShareIcon('whatsapp', value.bwUniversityId ? value.bwUniversityId : value.bwGalleryId)} />
                      </Col>
                      <Col span={5}>
                        <span className={style.lineIcon} onClick={() => clickShareIcon('line', value.bwUniversityId ? value.bwUniversityId : value.bwGalleryId)}/>
                      </Col>
                      <Col span={5}>
                        <span className={style.facebookIcon} onClick={() => clickShareIcon('facebook', value.bwUniversityId ? value.bwUniversityId : value.bwGalleryId)}/>
                      </Col>
                      <Col span={5}>
                        <span className={style.twitterIcon} onClick={() => clickShareIcon('twitter', value.bwUniversityId ? value.bwUniversityId : value.bwGalleryId)}/>
                      </Col>
                    </Row>
                  ) : (
                    <span
                      className={style.shareSpan}
                      onClick={() => props.onClickShare(index)}
                    >
                      Share
                    </span>
                  )}
                </Card>
              )}
            </Col>
          )
        })}
      </Row>
      <Modal
        visible={modalVid}
        centered
        className={style.cardVideo}
        footer={null}
        width={'75%'}
        style={{ padding: '2em 0px' }}
        onCancel={() => handleClose()}
      >
        <iframe
          style={{width: '-webkit-fill-available', height: '500px'}}
          id='modalVideo'
          src={dataVid}
          frameborder="0"
          allow="accelerometer;
          autoplay;
          encrypted-media;
          gyroscope;
          picture-in-picture"
          allowfullscreen="allowfullscreen"
        >
        </iframe>
      </Modal>
    </div>
  );
};

export const UICarouselCard = withStyles(style)(CarouselCard);
