/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Row, Col, Button, Modal, Typography } from 'antd';
import {
  CaretRightOutlined,
  DoubleRightOutlined,
  DownOutlined,
} from '@ant-design/icons';
import style from './index.css';
import Paragraph from 'antd/lib/typography/Paragraph';

const CardProfile = props => {
  const { data = {} } = props;
  const [modalVid, setModalVid] = useState(false);
  const [dataVid, setDataVid] = useState('');

  const playVid = url => {
    setDataVid(url);
    setModalVid(true);
  };

  const handleClose = () => {
    setModalVid(false);
    setDataVid('');
  };

  return (
    <div className={style.root}>
      <div
        style={{
          borderRadius: '15px',
          background: 'white',
          height: '100%',
          boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.2)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        {props.isViewAll ? (
          <div className={style.imageContainer} />
        ) : (
          <div className={style.imageContainer}>
            <CaretRightOutlined
              className={style.playButton}
              onClick={() => playVid(data.urlVideo)}
            />
            <img
              alt="example"
              src={data.urlVideoImage}
              style={{ borderRadius: '15px 15px 0 0' }}
            />
          </div>
        )}
        {!props.isViewAll && (
          <div className={style.textContent}>
            <Row>
              <Col
                span={24}
                style={{
                  height: '100%',
                  textAlign: 'center',
                  color: '#12184f',
                }}
              >
                <Paragraph
                  className={style.textHead}
                  title={data.name}
                  style={{ marginBottom: '.5em' }}
                  ellipsis={{ rows: 1 }}
                >
                  {data.name}
                </Paragraph>
              </Col>
              <Col
                span={24}
                style={{ textAlign: 'center', marginBottom: '20px' }}
              >
                <Paragraph
                  ellipsis={{ row: 1 }}
                  style={{ marginBottom: '0px', fontWeight: 'bold' }}
                >
                  {props.isKekl ? data.profession : data.position}
                </Paragraph>
                <Paragraph
                  ellipsis={{ row: 1 }}
                  style={{ marginBottom: '0px', fontWeight: 'bold' }}
                >
                  {props.isKekl ? data.graduateYear : data.period}
                </Paragraph>
              </Col>
              <Col span={24}>
                <Typography.Paragraph
                  ellipsis={{ rows: 5 }}
                  style={{ marginBottom: '0px' }}
                >
                  {data.description || ''}
                </Typography.Paragraph>
              </Col>
            </Row>
          </div>
        )}
        <div className={style.buttonContainer}>
          <Button
            className={style.buttonStyle}
            block
            onClick={() => props.onClickButton(data)}
          >
            {props.isViewAll ? (
              <>
                {props.language === 'eng' ? `Show More` : `Lebih Lengkap `}
                <DownOutlined style={{ marginLeft: '.5em' }} />
              </>
            ) : (
              <>
                {props.language === 'eng' ? `More Details ` : `Lihat Lebih `}
                <DoubleRightOutlined style={{ marginLeft: '.5em' }} />
              </>
            )}
          </Button>
        </div>
      </div>
      <Modal
        visible={modalVid}
        centered
        className={style.cardVideo}
        footer={null}
        width="75%"
        onCancel={() => handleClose()}
      >
        <iframe
          title="Preview"
          style={{ width: '100%', height: props.isMobile ? '300px' : '500px' }}
          id="modalVideo"
          src={dataVid}
          frameBorder="0"
          allow="accelerometer;
          autoplay;
          encrypted-media;
          gyroscope;
          picture-in-picture"
          allowFullScreen="allowfullscreen"
        />
      </Modal>
    </div>
  );
};

export const UICardProfile = withStyles(style)(CardProfile);
