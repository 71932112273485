export default function registrasiLabel(lang) {
  let pendidikanLov = [
    {
      key: 1,
      value: 'SD',
    },
    {
      key: 2,
      value: 'SMP',
    },
    {
      key: 3,
      value: 'SMA/SMK',
    },
    {
      key: 4,
      value: 'D3',
    },
    {
      key: 5,
      value: 'D4',
    },
    {
      key: 6,
      value: 'S1',
    },
    {
      key: 7,
      value: 'S2',
    },
    {
      key: 8,
      value: 'S3',
    },
  ];

  let jenisKelaminLov = [
    {
      key: 1,
      value: lang == 'eng' ? 'Male' : 'Laki-laki',
    },
    {
      key: 2,
      value: lang == 'eng' ? 'Female' : 'Perempuan',
    },
  ];

  let notifikasi = {
    success: {
      message: 'Success',
      description:
        lang == 'eng' ? 'Data saved successfully' : 'Data Berhasil disimpan',
    },
    error: {
      message: 'Error',
      description:
        lang == 'eng'
          ? 'Server Error Occurred'
          : 'Terjadi Kesalahan Pada Server',
      confirmPasswordMessage:
        lang == 'eng'
          ? 'Password and Confirm Password are not the same'
          : 'Password dan Confirm Password tidak sama',
    },
  };

  return lang === 'eng'
    ? {
        //label
        kembaliKeLogin: 'Back to Login',
        bergabungBersama: 'Join with Loyola',
        nama: 'Full Name',
        jenisKelamin: 'Gender',
        tglLahir: 'Date of Birth',
        alamat: 'Address',
        pekerjaan: 'Profession',
        namaInstitusiBekerja: 'Work Institution',
        pendidikan: 'Last Degree of Education',
        namaInstitusiPendidikan: 'Last Educational Institution Attended',
        registeredLyl:
          'Do you register as Loyola Students/Teachers/Education Staff/KEKL?',
        noTlpn: 'Phone Number',
        wa: 'WhatsApp',
        email: 'E-mail',
        username: 'Username',
        password: 'Password',
        confirmPassword: 'Confirm Password',
        daftar: 'Register',

        //required
        namaRequired: 'Please input your Name!',
        jenisKelaminRequired: 'Please input your Gender!',
        tglLahirRequired: 'Please input your Date of birth!',
        noTlpnRequired: 'Please input your Phone Number!',
        waRequired: 'Please input your WhatsApp!',
        emailRequired: 'Please input your E-mail!',
        usernameRequired: 'Please input your Username!',
        passwordRequired: 'Please input your Password!',
        confirmPasswordRequired: 'Please confirm your Password!',
        noOnlyRequired: 'Please input nomor only!',
        confirmRole: 'Please select Role Confirmation!',

        //lov hardcode
        pendidikanLov: pendidikanLov,
        jenisKelaminLov: jenisKelaminLov,
        notifikasi: notifikasi,
      }
    : {
        kembaliKeLogin: 'Kembali ke Login',
        bergabungBersama: 'Bergabung Bersama Loyola',
        nama: 'Nama Lengkap',
        jenisKelamin: 'Jenis Kelamin',
        tglLahir: 'Tanggal Lahir',
        alamat: 'Alamat',
        pekerjaan: 'Profesi',
        namaInstitusiBekerja: 'Nama Institusi Tempat Bekerja',
        pendidikan: 'Jenjang Pendidikan Terakhir',
        namaInstitusiPendidikan: 'Nama Institusi Pendidikan Terakhir',
        registeredLyl:
          'Apakah Anda mendaftar sebagai siswa Loyola/guru/tenaga kependidikan/KEKL?',
        noTlpn: 'No. Telepon',
        wa: 'WhatsApp',
        email: 'E-mail',
        username: 'Username',
        password: 'Password',
        confirmPassword: 'Confirm Password',
        daftar: 'Daftar',

        //required
        namaRequired: 'Nama Lengkap harus diisi!',
        jenisKelaminRequired: 'Jenis Kelamin harus diisi!',
        tglLahirRequired: 'Tanggal Lahir harus diisi!',
        // alamatRequired: 'Alamat harus diisi!',
        // pekerjaanRequired: 'Profesi harus diisi!',
        // namaInstitusiBekerjaRequired: 'Nama Institusi Tempat Bekerja harus diisi!',
        // pendidikanRequired: 'Jenjang Pendidikan Terakhir harus diisi!',
        // namaInstitusiPendidikanRequired: 'Nama institusi pendidikan terakhir harus diisi!',
        noTlpnRequired: 'No. Telepon harus diisi!',
        waRequired: 'WhatsApp harus diisi!',
        emailRequired: 'E-mail harus diisi!',
        usernameRequired: 'Username harus diisi!',
        passwordRequired: 'Password harus diisi!',
        confirmPasswordRequired: 'Confirm Password harus diisi!',
        noOnlyRequired: 'Please input nomor only!',
        confirmRole: 'Konfirmasi Role harus diisi!',

        //lov hardcode
        pendidikanLov: pendidikanLov,
        jenisKelaminLov: jenisKelaminLov,
        notifikasi: notifikasi,
      };
}
