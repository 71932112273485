/* eslint-disable import/prefer-default-export */
import { get, post } from 'axios';
import { endpoints } from '@source/config';

const isEng = lang => {
  return lang !== 'ind' ? 'true' : 'false';
};

const DanaService = {
  getDanaPengembangan(lang) {
    const uri = `${
      endpoints.api
    }noAuth/be/api/danaPengembangan/homepage?isEng=${isEng(lang)}`;
    return get(uri, {
      ...endpoints.setting,
    }).then(res => {
      return res;
    });
  },

  getDonasiSukarela(lang, id) {
    const uri = `${
      endpoints.api
    }noAuth/be/api/danaPengembangan/donasiSukarela?isEng=${isEng(lang)}${
      id ? `&userId=${id}` : ''
    }`;
    return get(uri, {
      ...endpoints.setting,
    }).then(res => {
      return res;
    });
  },

  getDonorBeasiswa(lang, size, userId, id) {
    let idParam = id ? '&id='+id : ''
    let userIdParam = userId ? '&userId='+userId : ''
    let sizeParam = size ? '&size='+size : ''
    const uri = `${
      endpoints.api
    }noAuth/be/api/danaPengembangan/donorBeasiswa?isEng=${isEng(lang)}${idParam}${userIdParam}${sizeParam}`;
    return new Promise((resolve, reject) => {
      get(uri, {
        ...endpoints.setting,
      }).then(res => { resolve(res); })
      .catch(err => { reject(err); })
    });
  },

  getPenerimaDonorBeasiswa(id, lang, page, size, sort) {
    const uri = `${
      endpoints.api
    }noAuth/be/api/danaPengembangan/donorBeasiswa/listPenerima?isEng=${isEng(
      lang,
    )}&id=${id}&page=${page}&size=${size}&sort=${sort}`;
    return new Promise((resolve, reject) => {
      get(uri, {
        ...endpoints.setting,
      }).then(res => { resolve(res); })
      .catch(err => { reject(err); })
    });
  },

  getQrCode(id, token) {
    const uri = `${endpoints.api}be/api/danaPengembangan/qrCode?fundId=${id}`;
    return get(uri, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      ...endpoints.setting,
    }).then(res => {
      return res;
    });
  },

  checkoutDonasiSukarela(data, lang, token, link = 'be/api/danaPengembangan/donasiSukarela/registrasi') {
    const uri = `${endpoints.api}${link}?isEng=${isEng(lang)}`;
    return new Promise((resolve, reject) => {
      post(uri, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        ...endpoints.setting,
      })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  saveSignature(file) {
    const uri = `${endpoints.api}noAuth/be/api/upload/tandaTangan`;
    const body = new FormData();
    body.append('file', file);
    return new Promise((resolve, reject) => {
      post(uri, body, {
        ...endpoints.setting,
      })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};

export { DanaService };
