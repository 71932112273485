/* eslint-disable no-unused-expressions */
import swal from 'sweetalert2';

export function confirmDialog(config) {
  return new Promise(async (resolve, reject) => {
    const result = await swal.fire({
      icon: 'info',
      text: 'Apakah Anda Yakin?',
      confirm: true,
      showCancelButton: true,
      confirmButtonText: 'Ya',
      cancelButtonText: 'Batal',
      ...config,
    });
    result.isConfirmed ? resolve(result) : reject(result);
  });
}

export function dialog(config) {
  return new Promise(async (resolve, reject) => {
    const result = await swal.fire({
      icon: 'success',
      showConfirmButton: true,
      ...config,
    });
    resolve(result);
  });
}
