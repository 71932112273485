import React, { useState } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Row, Col, Button, Modal, Typography } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import history from '@source/history';
import style from './index.css';

const CardGaleri = props => {
  const [modal, setModal] = useState(false);

  const showDetail = (type, id) => {
    history.push(`Detail?type=${type}&userId=${id}`);
  };

  return (
    <React.Fragment>
      <div
        style={{
          borderRadius: '15px',
          background: 'white',
          boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
          height: '100%',
        }}
      >
        <div className={style.gradientCard} onClick={() => props.description && props.directToDetail(props.data)}>
          {props.data.videoUrl && (
            <CaretRightOutlined
              className={style.playButton}
              onClick={() => setModal(true)}
            />
          )}
          <img
            alt="Thumbnail"
            src={props.data.eventThumbnailPath
              ? props.data.eventThumbnailPath
              : 'https://cdn0.iconfinder.com/data/icons/free-daily-icon-set/512/TaskStroke-512.png'
            }
            width="100%"
            style={{ borderRadius: '15px 15px 0 0' }}
          />
        </div>
        <div className={style.textContent}>
          <Row>
            <Col
              span={24}
              style={{ textAlign: 'center', color: '#12184f', fontSize: 18 }}
            >
              <span className={style.textHead}>{props.data.title ? props.data.title : props.data.eventName ? props.data.eventName : '-'}</span>
            </Col>
          </Row>
          {props.data.year &&
            <Row>
              <Col span={24} style={{ textAlign: 'center' }}>
                <span className={style.textHead}>{props.data.year}</span>
              </Col>
            </Row>
          }
          {props.description &&
            <Row>
              <Col offset={2} span={20} style={{ paddingBottom: 30, textAlign: 'justify' }}>
                <Typography.Paragraph
                  ellipsis={{ rows: 3 }}
                  style={{ marginBottom: '0px' }}
                >
                  {props.description}
                </Typography.Paragraph>
              </Col>
            </Row>
          }
        </div>
        {props.data.year &&
          <div style={{ marginTop: 30 }}>
            <Row>
              <Col span={24}>
                <Button
                  style={{
                    border: '1px solid yellow',
                    borderRadius: '0 0 15px 15px',
                    width: '100%',
                    background: 'yellow',
                  }}
                  onClick={() => showDetail(props.type, 0)}
                >{`Lihat ${props.type}`}</Button>
              </Col>
            </Row>
          </div>
        }
      </div>
      <Modal
        visible={modal}
        centered
        className={style.cardVideo}
        footer={null}
        width="75%"
        onCancel={() => setModal(false)}
      >
        {props.type === 'Dokumen' && (
          <div
            style={{
              height: props.isMobile ? 'auto' : '500px',
              padding: 80,
              textAlign: 'left',
              fontSize: 'larger',
              overflow: 'auto',
            }}
          >
            <Row>
              <Col
                span={24}
                style={{ textAlign: 'center', marginBottom: '50px' }}
              >
                <Typography>
                  <Typography.Title level={2}>{props.data.title}</Typography.Title>
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Typography className={style.docDescription}>
                  {props.data.desc}
                </Typography>
              </Col>
            </Row>
          </div>
        )}
        {props.type === 'Foto' && <img src={props.data.photo} />}
        {props.type === 'Video' && (
          <iframe
            style={{ width: '100%', height: props.isMobile ? '300px' : '500px' }}
            id="modaleo"
            src={props.data.videoUrl}
            frameBorder="0"
            allow="accelerometer;
          autoplay;
          encrypted-media;
          gyroscope;
          picture-in-picture"
            allowFullScreen="allowfullscreen"
          />
        )}
      </Modal>
    </React.Fragment>
  );
};

export const UICardGaleri = withStyles(style)(CardGaleri);
