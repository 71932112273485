// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._327E-{height:100%}.WhmxC{width:100%;height:180px;position:relative}.WhmxC img{width:100%;height:100%;-o-object-fit:cover;object-fit:cover}._3YLNn{width:100%;position:relative;display:inline-block}._36Wao{position:absolute;font-size:45px;color:#fff;top:40%;left:45%;z-index:2}._1FucU{font-family:Mulish,sans-serif;font-style:normal;font-weight:400;padding:15px 20px 20px;-ms-flex:1 1;flex:1 1;text-align:left}._2yZCm{font-weight:700;font-size:16px;color:#11174e}._3k9o_,.DQYmH{font-size:10px;line-height:12px}._3k9o_{font-weight:600}.yrDBT{border-radius:15px;min-width:250px;background:#fff}._2pmKj ._1ZSlY{padding:0}.O69nI{text-align:left}._1AMZA{text-align:center}._1NwUB{text-align:right}._1q_i0,._1q_i0:focus,._1q_i0:hover{background:#11174e;border-radius:4px;color:#ececec;width:auto;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;border-color:rgba(0,0,0,0)}._1q_i0>span{font-size:14px;font-size:.875rem;color:#ececec}._3Z0Ul{text-align:center;width:100%;padding:0 20px 20px;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center}._16Be2{font-family:Mulish,sans-serif;font-style:normal;font-weight:700;font-size:12px;line-height:20px;text-align:center;color:#ef712b}._1_ML_>.ant-modal-content>.ant-modal-body{padding:0}._1_ML_ .ant-modal-close{margin-top:-56px;margin-right:-56px}", ""]);
// Exports
exports.locals = {
	"root": "_327E-",
	"imageContainer": "WhmxC",
	"gradientCard": "_3YLNn",
	"playButton": "_36Wao",
	"textContent": "_1FucU",
	"textHead": "_2yZCm",
	"textBottom": "_3k9o_",
	"textMid": "DQYmH",
	"bodyCard": "yrDBT",
	"custom": "_2pmKj",
	"ant-card-body": "_1ZSlY",
	"leftText": "O69nI",
	"centerText": "_1AMZA",
	"rightText": "_1NwUB",
	"buttonStyle": "_1q_i0",
	"buttonContainer": "_3Z0Ul",
	"nameUniv": "_16Be2",
	"cardVideo": "_1_ML_"
};
module.exports = exports;
