/* eslint-disable no-use-before-define */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Upload, Button, message } from 'antd';
import {
  UploadOutlined,
  LoadingOutlined,
  CheckCircleFilled,
} from '@ant-design/icons';
import withStyles from 'isomorphic-style-loader/withStyles';
// import { dialog } from '../functions/alert';
import axios from 'axios';
import Text from 'antd/lib/typography/Text';
import styles from './index.css';

export function saveFile(url, file, config) {
  return new Promise((resolve, reject) => {
    const body = new FormData();
    body.append('file', file);
    axios
      .post(url, body, config)
      .then(res => {
        resolve(res);
      })
      .catch(err => reject(err));
  });
}

UploadFile.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  accept: PropTypes.string,
  maxSize: PropTypes.number,
  value: PropTypes.any,
  uploadFn: PropTypes.func,
  disabled: PropTypes.bool,
  fluidBtn: PropTypes.bool,
  classBtn: PropTypes.string,
  btnStyle: PropTypes.string,
  splitUpload: PropTypes.bool,
  showDesc: PropTypes.bool,
  showPreview: PropTypes.bool,
  previewStyle: PropTypes.object,
  notifMessages: PropTypes.object,
  language: PropTypes.string,
};

UploadFile.defaultProps = {
  title: 'Upload File',
  onChange: () => {},
  onError: () => {},
  onRemove: () => {},
  maxSize: 1,
  url: '',
  uploadFn: saveFile,
  splitUpload: false,
  showDesc: false,
  showPreview: false,
  previewStyle: {},
  notifMessages: {
    eng: {
      success: 'File Uploaded Successfully',
      failed: 'Failed To Upload File',
      tooBig: 'File is Too Big',
    },
    ind: {
      success: 'File Berhasil Diunggah',
      failed: 'File Gagal Diunggah',
      tooBig: 'File Terlalu Besar',
    },
  },
  language: 'ind',
};

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function UploadFile({
  value,
  title,
  onChange,
  onRemove,
  accept,
  maxSize,
  url,
  uploadFn,
  disabled,
  fluidBtn,
  classBtn,
  splitUpload,
  showDesc,
  showPreview,
  previewStyle,
  btnStyle,
  notifMessages,
  language,
  ...props
}) {
  const [fileList, setFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [imgUrl, setImgUrl] = useState();
  const notifMessage = notifMessages[language];

  useEffect(() => {
    if (value) {
      setFileList(value.fileList || []);
    }
  }, [value]);

  const handleChange = async info => {
    setIsLoading(true);
    let newFileList = [...info.fileList];
    newFileList = newFileList.slice(-1);
    newFileList = newFileList.map(file => {
      if (file.size / 1024 / 1024 > maxSize) {
        file.name = notifMessage.tooBig;
        file.response = notifMessage.failed;
        file.status = 'error';
        // dialog({icon:'error', title: 'File terlalu besar', text: `Ukuran file harus < ${maxSize} MB`})
        onChange(null);
      }
      return file;
    });
    if (newFileList.length > 0 && newFileList[0].status !== 'error') {
      try {
        const uploadRes = await uploadFn(
          url,
          newFileList[0].originFileObj,
          props.config,
        );
        getBase64(newFileList[0].originFileObj, url => setImgUrl(url));
        message.success({ content: notifMessage.success, top: '100px' });
        delete newFileList[0].originFileObj;
        onChange({ fileName: uploadRes.data.fileName, fileList: newFileList });
      } catch (err) {
        // const {data} = err.response;
        newFileList[0].status = 'error';
        newFileList[0].response = err.response
          ? err.response.data.message
          : err.message;
        message.error({ content: notifMessage.failed, top: '100px' });
        onChange(null);
      }
    }
    setFileList(newFileList);
    setIsLoading(false);
  };

  const handleRemove = info => {
    onChange('');
    setFileList([]);
  };

  const uploadProps = {
    beforeUpload: file => false,
    onChange: event => handleChange(event),
    onRemove: event => handleRemove(event),
    fileList,
    multiple: false,
  };


  return (
    <>
      <Upload
        className={`app-upload${splitUpload && ' split-upload'}`}
        {...uploadProps}
        iconRender=""
        accept={accept}
      >
        <Button
          className={classBtn}
          disabled={disabled || isLoading}
          style={{
            width: fluidBtn ? '100%' : 'auto',
            padding: '5px 20px',
            height: 'auto',
            display: 'flex',
            alignItems: 'center',
            ...btnStyle,
          }}
        >
          {isLoading ? <LoadingOutlined /> : <UploadOutlined />} {title}
          {!!value && (
            <CheckCircleFilled
              style={{ color: '#27ae60', marginLeft: '1em' }}
            />
          )}
        </Button>
      </Upload>
      {!value && showDesc && (
        <Text style={{ color: 'rgb(172, 172, 172)' }}>
          {' '}
          Ukuran file max {maxSize} MB
        </Text>
      )}
      {value && imgUrl && showPreview && (
        <div
          style={{
            padding: '.5em',
            border: '1px solid #dddddd',
            textAlign: 'center',
            borderRadius: '4px',
            height: '200px',
            width: '100%',
            marginTop: '.5em',
            ...previewStyle,
          }}
        >
          <div
            style={{
              width: '100%',
              height: '100%',
              textAlign: 'center',
              overflow: 'hidden',
            }}
          >
            <img src={imgUrl} alt="Preview" style={{ height: '100%' }} />
          </div>
        </div>
      )}
    </>
  );
}

export const UIUploadFile = withStyles(styles)(UploadFile);

/*
  CONTOH PENGGUNAAN

  <Form>
    <Form.Item
      name="suratPemandian"
      rules={[{required: true, message: 'Surat Pemandian harus diinput' }]}
    >
      <UploadFile
        url={CONFIG.BASE_URL_NOAUTH+'/api/upload/foto'}
        title="Upload Surat Pemandian"
        onChange={_ => {}}
      />
    </Form.Item>
  </Form>
*/
