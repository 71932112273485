import { css } from '@emotion/core';

export const chevronRight = css`
  width: 2rem;
  height: 2rem;
  margin: 0 auto;
  display: block;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAyMCAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuNTAwMSAyNi45Nzk0TDEyLjQ5MzQgMTUuOTg2TDEuNTAwMSA0Ljk5MjdDMC45Njk1NDcgNC40NjMzNCAwLjY3MTM4NyAzLjc0NDY2IDAuNjcxMzg3IDIuOTk1MkMwLjY3MTM4NyAyLjI0NTczIDAuOTY5NTQ3IDEuNTI3MDUgMS41MDAxIDAuOTk3Njk1QzIuNjA1MSAtMC4xMDczMDUgNC4zOTAxIC0wLjEwNzMwNSA1LjQ5NTEgMC45OTc2OTVMMTguNTAwMSAxNC4wMDI3QzE5LjYwNTEgMTUuMTA3NyAxOS42MDUxIDE2Ljg5MjcgMTguNTAwMSAxNy45OTc3TDUuNDk1MSAzMS4wMDI3QzQuMzkwMSAzMi4xMDc3IDIuNjA1MSAzMi4xMDc3IDEuNTAwMSAzMS4wMDI3QzAuNDIzNDI5IDI5Ljg5NzcgMC4zOTUwOTYgMjguMDg0NCAxLjUwMDEgMjYuOTc5NFoiIGZpbGw9IiNFQ0VDRUMiLz4KPC9zdmc+Cg==");
  background-repeat: no-repeat;
  background-position: center;
}
`;

export const chevronLeft = css`
  width: 2rem;
  height: 2rem;
  margin: 0 auto;
  display: block;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAyMCAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE4LjQ5OTkgNS4wMjA2NEw3LjUwNjU3IDE2LjAxNEwxOC40OTk5IDI3LjAwNzNDMTkuMDMwNSAyNy41MzY3IDE5LjMyODYgMjguMjU1MyAxOS4zMjg2IDI5LjAwNDhDMTkuMzI4NiAyOS43NTQzIDE5LjAzMDUgMzAuNDcyOSAxOC40OTk5IDMxLjAwMjNDMTcuMzk0OSAzMi4xMDczIDE1LjYwOTkgMzIuMTA3MyAxNC41MDQ5IDMxLjAwMjNMMS40OTk5MSAxNy45OTczQzAuMzk0OTA2IDE2Ljg5MjMgMC4zOTQ5MDcgMTUuMTA3MyAxLjQ5OTkxIDE0LjAwMjNMMTQuNTA0OSAwLjk5NzMwNkMxNS42MDk5IC0wLjEwNzY5MyAxNy4zOTQ5IC0wLjEwNzY5MyAxOC40OTk5IDAuOTk3MzA3QzE5LjU3NjYgMi4xMDIzMSAxOS42MDQ5IDMuOTE1NjQgMTguNDk5OSA1LjAyMDY0WiIgZmlsbD0iI0VDRUNFQyIvPgo8L3N2Zz4K");
  background-repeat: no-repeat;
  background-position: center;
}
`;
