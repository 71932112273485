/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import Carousel from 'nuka-carousel';
import { UICardProfile } from '@components';
import { chevronRight, chevronLeft } from './style';
import style from './index.css';

const Banner = props => {
  const { bannerHeight = '570px' } = props;
  const [listImage, setListImage] = useState();
  const dotStyle = {
    display: 'none',
  };
  const heightBanner = props.isMobile ? '230px' : props.isTablet ? '425px' :  bannerHeight

  useEffect(() => {
    const newListImage = props.listImage;
    if (newListImage) {
      setListImage(
        newListImage.length === 1
          ? [...newListImage, ...newListImage]
          : newListImage,
      );
    }
  }, [props.listImage]);

  return (
    <Carousel
      defaultControlsConfig={{
        pagingDotsStyle: dotStyle,
        nextButtonStyle: chevronRight,
        nextButtonText: ' ',
        nextButtonClassName: style.chevronRight,
        prevButtonStyle: chevronLeft,
        prevButtonClassName: style.chevronLeft,
        prevButtonText: ' ',
      }}
      className={style.bannerCarousel}
      height={heightBanner}
      wrapAround
    >
      {props.image && listImage
        ? listImage.map(data => {
            return (
              <div className={style.gradientImg}>
                <img
                  className={ props.isMobile ? style.imageStyleMobile : style.imageStyle}
                  src={data.imageUrl}
                />
              </div>
            );
          })
        : // <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          props.listData &&
          props.listData.map(item => <UICardProfile data={item} isMobile={props.isMobile} />)}
    </Carousel>
  );
};

export const UIBanner = withStyles(style)(Banner);
